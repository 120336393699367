import { PduktKategorie } from '@/entities/member/lists/pdukt_list'
import router from '@/router'
import RouteNames from '@/util/route_names'
import ProdukteMemcommonLogic from '../memcommon/produkte_memcommon_logic'
import Pdukt from '@/entities/member/pdukt'

export default class ProdukteLogic extends ProdukteMemcommonLogic {
  produkte: PduktKategorie[] = []
  listWorking: boolean = false
  listError: boolean = false

  detailsAnzeigen(produkt: Pdukt) {
    router.push({
      name: RouteNames.MEMBER.PRODUKT_DETAILS,
      params: { id: produkt.id },
    })
  }

  inhaltsstoffeVerwalten() {
    router.push({ name: RouteNames.MEMBER.INHALTSSTOFFE })
  }

  kategorienVerwalten() {
    router.push({ name: RouteNames.MEMBER.BASISKATEGORIEN })
  }

  extrasVerwalten() {
    router.push({ name: RouteNames.MEMBER.PRODUKT_EXTRAS })
  }

  artenVerwalten(): void {
    router.push({ name: RouteNames.MEMBER.PRODUKTARTEN })
  }

  standardpreiseVerwalten(): void {
    router.push({ name: RouteNames.MEMBER.STANDARDPREISE })
  }

  produktBearbeiten(produkt: Pdukt) {
    router.push({
      name: RouteNames.MEMBER.PRODUKT_EDIT,
      params: { id: produkt.id },
    })
  }

  neuesProdukt() {
    router.push({ name: RouteNames.MEMBER.PRODUKT_NEU })
  }
}
