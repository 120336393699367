
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import FilialeEditMemcommonLogic from '@/logic/memcommon/filiale_edit_memcommon_logic'
import ErrorBox from '../custom/ErrorBox.vue'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ErrorBox } })
export default class FilialeEditMemCommon extends mixins(Calculator) {
  @Prop() logic: FilialeEditMemcommonLogic

  adresseUeberpruefen() {
    this.logic.adresseUeberpruefen()
  }

  previewImage() {
    this.logic.previewImage()
  }

  previewBanner() {
    this.logic.previewBanner()
  }

  previewLogoKlein() {
    this.logic.previewLogoKlein()
  }

  filialeSpeichern() {
    this.logic.filialeSpeichern()
  }

  uploadImage() {
    this.logic.uploadImage()
  }

  uploadBanner() {
    this.logic.uploadBanner()
  }

  navBack() {
    this.logic.navBack()
  }
}
