import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import OeffnungszeitOverview, {
  Oeffnung as Oeffn,
  OeffnungData,
} from '@/entities/member/oeffnungszeit_overview'
import OeffnungszeitOverviewRequest from '@/entities/member/request/oeffnungszeit_overview_request'
import OeffnungszInsertRequest, {
  ZeitraumData,
} from '@/entities/member/request/oeffnungsz_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import MemberBaseLogic from '../member_base_logic'

export default abstract class OeffnungszeitAusnahmenMemcommonLogic extends MemberBaseLogic {
  abstract get filialen(): FilialeSimple[]
  //filialen: FilialeSimple[] = []
  _filiale?: string = null
  _datum?: string = null
  geschlossen: boolean = false
  oeffnungen?: Oeffnung[] = []

  dialogOpen: boolean = false

  ausnahmen: Oeffn[] = []
  events: any[] = []

  aktuellesDatum?: Oeffn = null
  pickerDate?: string = null

  get datum() {
    return this._datum
  }

  set datum(value: string) {
    this._datum = value

    this.ausnahmen.forEach((element) => {
      if (element.datum === value) {
        this.aktuellesDatum = element
        element.normal.zeitraeume.forEach((x) => {})
        return
      }
    })
  }

  get filiale() {
    return this._filiale
  }

  set filiale(value: string) {
    this._filiale = value
  }

  getEventColor(date: string) {
    var result = null
    this.ausnahmen.forEach((element) => {
      if (element.datum === date) {
        if (element.ausnahme) {
          if (element.ausnahme.geoeffnet) {
            result = 'blue'
            return
          } else {
            result = 'black'
            return
          }
        } else {
          if (element.normal.geoeffnet) {
            result = 'green lighten-1'
          } else {
            result = 'grey lighten-1'
          }
          return
        }
      }
    })
    return result
  }

  functionEvents(date) {
    this.ausnahmen.forEach((element) => {
      if (element.datum === date) {
        return true
      }
    })
  }

  reloadDates(val) {
    var request = new OeffnungszeitOverviewRequest()
    request.monatJahr = moment(val, 'yyyy-MM').format('MM.yyyy')
    request.filialeId = this._filiale

    Poster.postMember<RemoteResult<OeffnungszeitOverview>>(
      RestPaths.oeffnungszeitOverview,
      request
    ).then((response) => {
      this.ausnahmen = response.data.payload.oeffnungen
      this.events = this.ausnahmen.map((x) => {
        return x.datum
      })
    })
  }

  ausnahmeEinfuegen() {
    var request = new OeffnungszInsertRequest()
    request.id = uuidv4()
    request.filialeId = this.filiale
    request.datum = this.datum
    request.geoeffnet = !this.geschlossen

    this.oeffnungen.forEach((oeffnung) => {
      var zeitraumData = new ZeitraumData()
      zeitraumData.zeitVon = oeffnung.von
      zeitraumData.zeitBis = oeffnung.bis
      request.oeffnungszeiten.push(zeitraumData)
    })

    Poster.postMember<RemoteResult<OeffnungData>>(
      RestPaths.oeffnungszeitOeffnungszeitEinfuegen,
      request
    ).then((response) => {
      this.dialogOpen = false
      this.geschlossen = false
      this.oeffnungen = []

      this.aktuellesDatum.ausnahme = response.data.payload
    })
  }

  ausnahmeLoeschen() {
    var request = new IdRequest()
    request.id = this.aktuellesDatum.ausnahme.id

    Poster.postMember<RemoteResult<Boolean>>(
      RestPaths.oeffnungszeitOeffnungszeitLoeschen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aktuellesDatum.ausnahme = null
      }
    })
  }

  oeffnungHinzufuegen() {
    this.oeffnungen.push(new Oeffnung())
  }

  init() {
    this.oeffnungen.push(new Oeffnung())
  }
}

class Oeffnung {
  von?: string = null
  bis?: string = null
  vonOpen: boolean = false
  bisOpen: boolean = false
}
