
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import FilialeNeuMemcommonLogic from '@/logic/memcommon/filiale_neu_memcommon_logic'
import {
  Liefergebiet,
  Polygon,
  Point,
} from '@/logic/memcommon/filiale_neu_memcommon_logic'

@Component
export default class FilialeNeuMemCommon extends Vue {
  @Prop() logic: FilialeNeuMemcommonLogic

  selectedGebiet: string = null
  polygonZuordnenVisible: boolean = false
  neuesLiefergebietVisible: boolean = false
  neuesLiefergebiet: Liefergebiet = new Liefergebiet()

  zuordnen() {
    this.logic.zuordnen()
    this.polygonZuordnenVisible = false
  }

  showPolygoneZuordnen(gebiet: Liefergebiet) {
    this.logic.aktuellesGebiet = gebiet
    this.polygonZuordnenVisible = true
  }

  neuesLiefergebietSpeichern() {
    this.logic.gebiete.push(this.neuesLiefergebiet)
    this.neuesLiefergebietVisible = false
  }

  previewImage() {
    this.logic.previewImage()
  }

  previewBanner() {
    this.logic.previewBanner()
  }

  previewLogoKlein() {
    this.logic.previewLogoKlein()
  }

  uploadKmlFile() {
    this.logic.uploadKmlFile()
  }

  uploadImage() {
    this.logic.uploadImage()
  }

  uploadBanner() {
    this.logic.uploadBanner()
  }

  filialeEinfuegen() {
    this.logic.filialeEinfuegen()
  }

  adresseUeberpruefen() {
    this.logic.adresseUeberpruefen()
  }
}
