
import {
  AbholungStatus,
  AbholzeitType,
  Verzehrart,
} from '@/entities/common/enums'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestellungAbholungLogic from '@/logic/member/bestellung_abholung_logic'
import BestellungenLogic from '@/logic/member/bestellungen_logic'

@Component({ components: { ArtikelRow } })
export default class BestellungAbholung extends mixins(Calculator) {
  @Prop() bestellung?: Bestellung
  logic?: BestellungAbholungLogic = null

  @Watch('bestellung', { immediate: true, deep: true })
  watchBestellung(newValue: Bestellung) {
    this.logic.init(newValue)
  }

  bestimmeAbholzeitpunkt() {
    if (this.bestellung == null) return null
    if (this.bestellung.abholung.type == AbholzeitType.BALDEST) {
      return 'schnellstmöglich'
    } else if (this.bestellung.abholung.type == AbholzeitType.UHRZEIT) {
      return this.formatiereDatumUhrzeit(
        this.bestellung.abholung.gewaehlterAbholZp
      )
    }
    return null
  }

  formatBool(value: boolean): string {
    if (value == null) return 'k.A.'
    if (value) return 'ja'
    if (!value) return 'nein'
  }

  bestimmeStatus() {
    if (this.bestellung == null) return null
    switch (this.bestellung.abholung.status) {
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.ABGESENDET:
        return 'nicht angenommen'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
    }
  }

  bestimmeVerzehrart() {
    if (!this.bestellung) return null
    switch (this.bestellung.abholung.verzehrart) {
      case Verzehrart.VOR_ORT:
        return 'Verzehr im Restaurant'
      case Verzehrart.MITNAHME:
        return 'Mitnahme'
    }
  }

  get annehmenVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABGESENDET
  }

  get ablehnenVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABGESENDET
  }

  get abholbereitVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ANGENOMMEN
  }

  get abgeholtVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABHOLBEREIT
  }

  get nichtAbgeholtVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABHOLBEREIT
  }

  get stornierenVisible(): boolean {
    return true
    // return !this.bestellung.abgeschlossen && !this.bestellung.storniert
  }

  @Emit('bestellung-abgeschlossen')
  bestellungAbgeschlossen() {}

  bestimmeAngenommenStatus(): string {
    if (!this.bestellung.abholung.angenommenZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.angenommenZp) +
      ' (' +
      this.bestellung.abholung.angenommenBenutzerName +
      ')'
    )
  }

  bestimmeInBearbeitungStatus(): string {
    if (!this.bestellung.abholung.inBearbeitungZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.inBearbeitungZp) +
      ' (' +
      this.bestellung.abholung.inBearbeitungBenutzerName +
      ')'
    )
  }

  bestimmeAbgelehntStatus(): string {
    if (!this.bestellung.abholung.abgelehntZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.abgelehntZp) +
      ' (' +
      this.bestellung.abholung.abgelehntBenutzerName +
      ')'
    )
  }

  bestimmeAbholbereitStatus(): string {
    if (!this.bestellung.abholung.abholbereitZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.abholbereitZp) +
      ' (' +
      this.bestellung.abholung.abholbereitBenutzerName +
      ')'
    )
  }

  bestimmeAbgeholtStatus(): string {
    if (!this.bestellung.abholung.abgeholtZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.abgeholtZp) +
      ' (' +
      this.bestellung.abholung.abgeholtBenutzerName +
      ')'
    )
  }

  bestimmeNichtAbgeholtStatus(): string {
    if (!this.bestellung.abholung.nichtAbgeholtZp) return '---'
    return (
      this.formatiereDatumUhrzeit(this.bestellung.abholung.nichtAbgeholtZp) +
      ' (' +
      this.bestellung.abholung.nichtAbgeholtBenutzerName +
      ')'
    )
  }

  abgeholt() {
    this.logic.updateStatus(AbholungStatus.ABGEHOLT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  nichtAbgeholt() {
    this.logic.updateStatus(AbholungStatus.NICHT_ABGEHOLT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  abholbereit() {
    this.logic.updateStatus(AbholungStatus.ABHOLBEREIT)
  }

  ablehnen() {
    this.logic.updateStatus(AbholungStatus.ABGELEHNT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  annehmen() {
    this.logic.updateStatus(AbholungStatus.ANGENOMMEN)
  }

  stornieren() {
    this.logic.stornieren(() => {
      this.bestellungAbgeschlossen()
    })
  }

  mounted() {
    this.logic = new BestellungAbholungLogic()
    this.logic.init(this.bestellung)
  }

  beforeDestroy() {}

  @Watch('logic.details')
  watchDetails() {
    document.getElementById('rightPane').scrollTo({ top: 0 })
    window.scrollTo({ top: 0 })
  }
}
