import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Bezahlart, BuchungBestellart } from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import BestellungInsertRequest from '@/entities/common/request/bestellung_insert_request'
import TischPruefenRequest from '@/entities/common/request/tisch_pruefen_request'
import Tisch from '@/entities/common/tisch'
import router from '@/router'
import store from '@/store'
import Nbd from '@/util/neue-bestellung-data'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import { response } from 'express'
import { v4 as uuidv4 } from 'uuid'

export default class TerminSofortLogic {
  filiale?: BestellungFiliale = null
  tischNummer?: string = null
  tisch?: Tisch = null

  pruefenWorking: boolean = false
  pruefenError: boolean = false
  pruefenCompleted: boolean = false

  buchenWorking: boolean = false
  buchenError: boolean = false
  buchenCompleted: boolean = false

  init() {
    var nbd = store.state.bestellungPending
    this.filiale = nbd.filiale
    this.tischNummer = nbd.sofort.tischBezeichnung
  }

  verfuegbarkeitPruefen() {
    this.pruefenWorking = true
    this.pruefenError = false

    var request = new TischPruefenRequest()
    request.id = store.state.bestellungPending.filiale.filialeId
    request.tischNr = this.tischNummer

    Poster.postUser<RemoteResult<Tisch>>(RestPaths.tischPruefen, request)
      .then((response) => {
        this.pruefenWorking = false
        if (response.data.success) {
          this.tisch = response.data.payload
          store.commit(StoreOps.UPDATE_NBD_SOF_TISCH, this.tisch)
          this.pruefenCompleted = true
        } else {
          this.pruefenError = true
        }
      })
      .catch(() => {
        this.pruefenWorking = false
        this.pruefenError = true
      })
  }

  tischBuchen() {
    this.buchenWorking = true
    this.buchenError = false
    var nbd = store.state.bestellungPending
    var request = new BestellungInsertRequest()
    request.id = nbd.id
    request.terminart = nbd.terminart
    request.identifier = store.state.user.identifier
    request.filialeId = this.filiale.filialeId
    request.bezahlart = Bezahlart.BARZAHLUNG

    var sofort = nbd.sofort
    request.createSofortbestellung()
    request.sofort.id = uuidv4()
    request.sofort.bestellart = BuchungBestellart.SELBST
    request.sofort.personen = 2
    request.sofort.tischId = sofort.tischId

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeEinfuegen,
      request
    )
      .then((response) => {
        this.buchenWorking = false
        if (response.data.success) {
          this.buchenCompleted = true
          router.push({
            name: RouteNames.NEU.SOFORTBESTELLUNG_BESTELLART,
          })
        } else {
          this.buchenError = true
        }
      })
      .catch(() => {
        this.buchenWorking = false
        this.buchenError = true
      })
  }
}