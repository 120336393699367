
import SofortSelectLogic from '@/logic/bestellung_neu/sofort_select_logic'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import Calculator from '../mixins/Calculator.vue'
import vuexStore from '@/store'

@Component({ components: { FilialeHeader, NichtGueltig, ErrorBox } })
export default class SofortSelect extends mixins(Calculator) {
  logic: SofortSelectLogic = new SofortSelectLogic()

  mounted() {
    if (!vuexStore.state.isNeueBestellung) return
  }

  serviceRufen() {
    this.logic.serviceRufen()
  }

  selbstBestellen() {
    this.logic.selbstBestellen()
  }

  zurueckZurBestellung() {
    this.logic.zurueckZurBestellung()
  }
}
