import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Terminart } from '@/entities/common/enums'
import BestellungList from '@/entities/common/lists/bestellung_list'
import BPBeginnenRequest from '@/entities/common/pending/bp_beginnen_request'
import RemoteResult from '@/entities/common/remote_result'
import BestellungFilialeRequest from '@/entities/common/request/bestellung_filiale_request'
import FilialeDetailRequest from '@/entities/common/request/filiale_detail_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class AbholungStartenLogic {
  filiale?: BestellungFiliale = null
  bestellungen?: Bestellung[] = []

  bestellungStarten() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.filiale.filialeId
    request.terminart = Terminart.ABHOLUNG
    request.direktabholung = true
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      if (response.data.success) {
        var payload = {
          filiale: this.filiale,
          bestellungId: response.data.payload,
        }

        store.commit(StoreOps.BESTELLUNG_DIREKT_STARTEN, payload)
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.VERZEHRART })
      }
    })
  }

  init(id: string) {
    var request = new FilialeDetailRequest()
    request.urlPath = id

    Poster.postUser<RemoteResult<BestellungFiliale>>(
      RestPaths.filialeBestellungSuche,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload

        var bestellungenRequest = new BestellungFilialeRequest()
        bestellungenRequest.identifierId = store.state.user.identifier
        bestellungenRequest.filialeId = this.filiale.filialeId
        Poster.postUser<RemoteResult<BestellungList>>(
          RestPaths.bestellungKundeFilialeAktuell,
          bestellungenRequest
        ).then((response) => {
          if (response.data.success) {
            this.bestellungen = response.data.payload.list
          }
        })
      }
    })
  }
}
