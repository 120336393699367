import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Verzehrart } from '@/entities/common/enums'
import BPAbholungVerzehrartRequest from '@/entities/common/pending/bp_abholung_verzehrart_request'
import RemoteResult from '@/entities/common/remote_result'
import router from '@/router'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class VerzehrartLogic {
  verzehrart?: string = Verzehrart.MITNAHME

  get filiale(): BestellungFiliale {
    return vuexStore.state.bestellungPending.filiale
  }

  verzehrarten = [
    { label: 'Zum Mitnehmen', value: Verzehrart.MITNAHME },
    { label: 'Verzehr im Restaurant', value: Verzehrart.VOR_ORT },
  ]

  weiter() {
    var request = new BPAbholungVerzehrartRequest()
    request.id = vuexStore.state.bestellungPending.id
    request.verzehrart = this.verzehrart

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungpendingAbholungVerzehrart,
      request
    ).then((response) => {
      vuexStore.commit(StoreOps.UPDATE_NBD_ABH_VERZEHRART, this.verzehrart)
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.SHOPPING.PRODUKTE })
    })
  }
}
