import router from '@/router'
import RouteNames from '@/util/route_names'

export default class MemberPortalLogic {
  init() {}

  navEigeneDaten() {
    router.push({ name: RouteNames.MEMBER.EIGENE_DATEN })
  }

  navBestellungen() {
    router.push({ name: RouteNames.MEMBER.BESTELLUNGEN })
  }

  navBestellverwaltung() {
    router.push({ name: RouteNames.MEMBER.BESTELLLISTE })
  }

  navKueche() {
    router.push({ name: RouteNames.MEMBER.KUECHE })
  }

  navService() {
    router.push({ name: RouteNames.MEMBER.SERVICE })
  }

  navReservierungen() {
    router.push({ name: RouteNames.MEMBER.RESERVIERUNGEN })
  }

  navFilialen() {
    router.push({ name: RouteNames.MEMBER.FILIALEN })
  }

  navOeffnungszeiten() {
    router.push({ name: RouteNames.MEMBER.OEFFNUNGSZEITEN })
  }

  navInhaltsstoffe() {
    router.push({ name: RouteNames.MEMBER.INHALTSSTOFFE })
  }

  navProdukte() {
    router.push({ name: RouteNames.MEMBER.PRODUKTE })
  }

  navVerfuegbarkeiten() {
    router.push({ name: RouteNames.MEMBER.VERFUEGBARKEIT })
  }

  navPreislisten() {
    router.push({ name: RouteNames.MEMBER.PREISLISTEN })
  }

  navTische() {
    router.push({ name: RouteNames.MEMBER.TISCHE })
  }

  navMitarbeiter() {
    router.push({ name: RouteNames.MEMBER.MITARBEITER })
  }
}
