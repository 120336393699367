import BestellungFiliale from '@/entities/common/bestellung_filiale'
import KundeLieferung from '@/entities/common/kunde_lieferung'
import BPLieferungKundeRequest from '@/entities/common/pending/bp_lieferung_kunde_request'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class KundendatenLieferungLogic {
  bestellungCompleted: boolean = false
  filiale?: BestellungFiliale = null
  formValid: boolean = true
  kundeNachname?: string = null
  kundeVorname?: string = null
  kundeEmail?: string = null
  kundeTelefon?: string = null
  kundeStrasse?: string = null
  kundePlz?: string = null
  kundeOrt?: string = null
  kundeAdresszusatz?: string = null
  kommentare?: string = null

  nachnameRules: any[] = [(v: any) => !!v || 'Bitte Nachname angeben']

  vornameRules: any[] = [(v: any) => !!v || 'Bitte Vorname angeben']

  emailRules: any[] = [
    (v: any) => !!v || 'Bitte E-Mail angeben',
    (v: string) => /.+@.+\..+/.test(v) || 'Bitte gültige E-Mail angeben',
  ]

  telefonRules: any[] = [(v) => !!v || 'Bitte Telefon angeben']

  strasseRules: any[] = [(v) => !!v || 'Bitte Strasse angeben']

  plzRules: any[] = [
    (v) => !!v || 'Bitte PLZ angeben',
    (v: string) => /\d{5}/.test(v) || 'Bitte gültige PLZ angeben',
  ]

  ortRules: any[] = [(v) => !!v || 'Bitte Ort angeben']

  init() {
    this.filiale = store.state.bestellungPending.filiale
    var request = new IdRequest()
    request.id = store.state.user.identifier
    Poster.postUser<RemoteResult<KundeLieferung>>(
      RestPaths.bestellungKundeKundendatenLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        var kundeLieferung = response.data.payload
        // this.kundeNachname = kundeLieferung.nachname
        // this.kundeVorname = kundeLieferung.vorname
        // this.kundeStrasse = kundeLieferung.strasse
        // this.kundePlz = kundeLieferung.plz
        // this.kundeOrt = kundeLieferung.ort
        // this.kundeAdresszusatz = kundeLieferung.adresszusatz
        // this.kundeTelefon = kundeLieferung.telefon
        // this.kundeEmail = kundeLieferung.email
      }
    })

    var ld = store.state.bestellungPending.lieferung

    if (ld.kundeNachname) {
      this.kundeNachname = ld.kundeNachname
    }
    if (ld.kundeVorname) {
      this.kundeVorname = ld.kundeVorname
    }
    if (ld.kundeStrasse) {
      this.kundeStrasse = ld.kundeStrasse
    }
    if (ld.kundePlz) {
      this.kundePlz = ld.kundePlz
    }
    if (ld.kundeOrt) {
      this.kundeOrt = ld.kundeOrt
    }
    if (ld.kundeAdresszusatz) {
      this.kundeAdresszusatz = ld.kundeAdresszusatz
    }
    if (ld.kundeTelefon) {
      this.kundeTelefon = ld.kundeTelefon
    }
    if (ld.kundeEmail) {
      this.kundeEmail = ld.kundeEmail
    }
  }

  weiter() {
    var request = new BPLieferungKundeRequest()
    request.id = store.state.bestellungPending.id
    request.kundeNachname = this.kundeNachname
    request.kundeVorname = this.kundeVorname
    request.kundeTelefon = this.kundeTelefon
    request.kundeEmail = this.kundeEmail
    request.kundeStrasse = this.kundeStrasse
    request.kundePlz = this.kundePlz
    request.kundeOrt = this.kundeOrt
    request.kundeAdresszusatz = this.kundeAdresszusatz

    Poster.postUser<RemoteResult<any>>(
      RestPaths.bestellungpendingLieferungKunde,
      request
    ).then(() => {
      store.commit(StoreOps.UPDATE_NBD_LIEFERUNG_KUNDE, {
        kundeNachname: this.kundeNachname,
        kundeVorname: this.kundeVorname,
        kundeTelefon: this.kundeTelefon,
        kundeEmail: this.kundeEmail,
        kundeStrasse: this.kundeStrasse,
        kundePlt: this.kundePlz,
        kundeOrt: this.kundeOrt,
        kundeAdresszusatz: this.kundeAdresszusatz,
      })
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.TRINKGELD })
    })
  }
}
