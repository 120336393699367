
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import TageskarteNeuMemcommonLogic from '@/logic/memcommon/tageskarte_neu_memcommon_logic'
import { Prop } from 'vue-property-decorator'
import PreislisteKategorieContainer from './PreislisteKategorieContainer.vue'
import router from '@/router'

@Component({ components: { PreislisteKategorieContainer } })
export default class TageskarteNeuMemCommon extends mixins(Calculator) {
  @Prop() logic: TageskarteNeuMemcommonLogic

  produktEntfernen(produkt) {
    
  }

  produkteHinzufuegenAbbrechen() {
    this.logic.produkteHinzufuegenAbbrechen()
  }

  produkteHinzufuegen() {
    this.logic.produkteHinzufuegen()
  }

  produkteHinzufuegenAnzeigen() {
    this.logic.produkteHinzufuegenAnzeigen()
  }

  preislisteEinfuegen() {
    this.logic.preislisteEinfuegen()
  }

  navBack() {
    router.back()
  }
}
