import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Bezahlart, Terminart } from '@/entities/common/enums'
import BPAbholungKundeRequest from '@/entities/common/pending/bp_abholung_kunde_request'
import RemoteResult from '@/entities/common/remote_result'
import BestellungInsertRequest from '@/entities/common/request/bestellung_insert_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import Validator from '@/util/validator'
import { v4 as uuidv4 } from 'uuid'

export default class KundendatenLogic {
  filiale?: BestellungFiliale = null
  formValid: boolean = true
  kundeNachname?: string = null
  kundeVorname?: string = null
  _kundeEmail?: string = null
  kundeTelefon?: string = null

  get kundeEmail(): string {
    return this._kundeEmail
  }

  set kundeEmail(newValue: string) {
    if (newValue == null) this._kundeEmail = null
    else this._kundeEmail = newValue.toLowerCase()
  }

  nachnameRules: any[] = [(v) => !!v || 'Bitte Nachname angeben']

  vornameRules: any[] = [(v) => !!v || 'Bitte Vorname angeben']

  emailRules: any[] = Validator.emailValidate

  telefonRules: any[] = [(v) => !!v || 'Bitte Telefon angeben']

  init() {
    this.filiale = store.state.bestellungPending.filiale

    var ad = store.state.bestellungPending.abholung
    if (ad.kundeNachname) {
      this.kundeNachname = ad.kundeNachname
    }
    if (ad.kundeVorname) {
      this.kundeVorname = ad.kundeVorname
    }
    if (ad.kundeEmail) {
      this.kundeEmail = ad.kundeEmail
    }
    if (ad.kundeTelefon) {
      this.kundeTelefon = ad.kundeTelefon
    }
  }

  weiter() {
    var terminart = store.state.bestellungPending.terminart

    if (terminart == Terminart.RESERVIERUNG) {
      store.commit(StoreOps.UPDATE_NBD_RESERV_KUNDE, {
        kundeNachname: this.kundeNachname,
        kundeVorname: this.kundeVorname,
        kundeTelefon: this.kundeTelefon,
        kundeEmail: this.kundeEmail,
      })

      var request = new BestellungInsertRequest()
      var nbd = store.state.bestellungPending
      var nbdR = nbd.reservierung

      request.id = nbd.id
      request.terminart = terminart
      request.identifier = store.state.user.identifier
      request.filialeId = nbd.filiale.filialeId
      request.bezahlart = Bezahlart.BARZAHLUNG

      request.createReservierung()
      request.reservierung.id = uuidv4()
      //request.reservierung.tischId = nbdR.tisch.id
      request.reservierung.kundeNachname = nbdR.kundeNachname
      request.reservierung.kundeVorname = nbdR.kundeVorname
      request.reservierung.kundeTelefon = nbdR.kundeTelefon
      request.reservierung.kundeEmail = nbdR.kundeEmail
      request.reservierung.zeitVon = nbdR.zeitVon
      request.reservierung.zeitBis = nbdR.zeitBis
      request.reservierung.personen = nbdR.anzahlPersonen
      request.reservierung.art = 'ONLINE'
      request.reservierung.bestellart = 'SELBST'

      Poster.postUser<RemoteResult<Bestellung>>(
        RestPaths.bestellungKundeEinfuegen,
        request
      ).then((response) => {
        if (response.data.success) {
          router.push({
            name: RouteNames.NEU.RESERVIERUNG_SELECT_VORBESTELLUNG,
          })
        }
      })
    } else if (terminart == Terminart.ABHOLUNG) {
      var krequest = new BPAbholungKundeRequest()
      krequest.id = store.state.bestellungPending.id
      krequest.kundeNachname = this.kundeNachname
      krequest.kundeVorname = this.kundeVorname
      krequest.kundeTelefon = this.kundeTelefon
      krequest.kundeEmail = this.kundeEmail

      Poster.postUser<RemoteResult<any>>(
        RestPaths.bestellungpendingAbholungKunde,
        krequest
      ).then(() => {
        store.commit(StoreOps.UPDATE_NBD_ABH_KUNDE, {
          kundeNachname: this.kundeNachname,
          kundeVorname: this.kundeVorname,
          kundeTelefon: this.kundeTelefon,
          kundeEmail: this.kundeEmail,
        })
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.TRINKGELD })
      })
    }
  }
}
