
import Vue from 'vue'
import Component from 'vue-class-component'
import InhaltsstoffeMemcommonLogic from '@/logic/memcommon/inhaltsstoffe_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({ components: { ToolbarButton } })
export default class InhaltsstoffeMemcommon extends Vue {
  @Prop() logic: InhaltsstoffeMemcommonLogic

  $refs!: {
    editForm: HTMLFormElement
    newForm: HTMLFormElement
  }

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  einfuegen() {
    var valid = this.$refs.newForm.validate()

    if (!valid) return

    this.logic.einfuegen()
  }

  neuenErstellen() {
    this.logic.neuenErstellen()
  }
}
