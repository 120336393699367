
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import BestellungAbholung from './BestellungAbholung.vue'
import BestellungReservierung from './BestellungReservierung.vue'
import BestellungSofort from './BestellungSofort.vue'
import BestellungLogic from '@/logic/member/bestellung_logic'
import BestellungLieferung from './BestellungLieferung.vue'

@Component({
  components: {
    BestellungAbholung,
    BestellungReservierung,
    BestellungSofort,
    BestellungLieferung,
  },
})
export default class Bestellung extends mixins(Calculator) {
  logicSync: BestellungLogic = null

  mounted() {
    this.logicSync = new BestellungLogic()
    this.logicSync.init(this.$route.params.id)
  }

  beforeDestroy() {}
}
