export default class FilialeUpdateRequest {
  id?: string
  aktiv?: boolean = true
  bezeichnung?: string
  strasse?: string
  plz?: string
  ort?: string
  telefon?: string
  email?: string
  bildId?: string
  bildNeu?: boolean = false
  bildNeuId?: string
  bannerId?: string
  bannerNeu?: boolean = false
  logoKleinId?: string
  logoKleinNeuId?: string
  logoKleinNeu: boolean = false
  bannerNeuId?: string
  betriebId?: string
  latitude?: number
  longitude?: number
  timeZoneId?: number
  supportsAbholung?: boolean = false
  supportsReservierung?: boolean = false
  supportsSofortbestellung?: boolean = false
  supportsLieferung?: boolean = false
  supportsPaypal?: boolean = false
  colorPrimary: number = 0
  colorSecondary: number = 0
  colorTertiary: number = 0
  urlPath: string
  darkMode: boolean
  abholungSettings?: AbholungSettings
  reservierungSettings?: ReservierungSettings
  sofortbestellungSettings?: SofortbestellungSettings
  lieferungSettings?: LieferungSettings
  paypalSettings?: PaypalSettings
}

class AbholungSettings {
  supportsVorbestellung?: boolean = false
  supportsMehrereTage?: boolean = false
  zeitVorSchliessung?: number
  vorbestellungTage?: number
}

class ReservierungSettings {
  supportsVorbestellung?: boolean
  zeitVorSchliessung?: number
  reservierungTage?: number
}

class SofortbestellungSettings {
  zeitVorSchliessung?: number
}

class LieferungSettings {
  supportsTermin: boolean = false
  terminTage?: number
}

class PaypalSettings {
  paypalAdresse?: string
}

export {
  AbholungSettings,
  ReservierungSettings,
  SofortbestellungSettings,
  LieferungSettings,
  PaypalSettings,
}
