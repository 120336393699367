import GoogleLocationList from '@/entities/common/lists/google_location_list'
import GoogleLocation from '@/entities/common/google_location'
import GooglePlace from '@/entities/common/google_place'
import RemoteResult from '@/entities/common/remote_result'
import StringRequest from '@/entities/common/request/string_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import vuexStore from '@/store'
import StoreOps from '@/util/store_ops'
import RouteNames from '@/util/route_names'
import router from '@/router'
import LocationRequest from '@/entities/common/request/location_request'
import VueCookie from 'vue-cookie'
import LatLonRequest from '@/entities/common/request/lat_lon_request'

export default class HomepageLogic {
  locations: GoogleLocation[] = []
  _searchText: string = null
  _selectedLocation: GoogleLocation = null

  standort: string = null
  timerHandle: any = null
  timeout: number = 1000

  editStandortMode: boolean = false

  constructor() {
    if (vuexStore.state.standort)
      this.standort = vuexStore.state.standort.bezeichnung
  }

  get searchText(): string {
    return this._searchText
  }

  set searchText(newValue: string) {
    this._searchText = newValue
    this.timeout = 1000

    if (!this.timerHandle)
      this.timerHandle = setInterval(() => {
        this.timeout = this.timeout - 200

        if (this.timeout <= 0) {
          clearInterval(this.timerHandle)
          this.timerHandle = null
          this.timeout = 1000

          if (this._searchText.length >= 3) {
            var request = new LocationRequest()
            request.locationString = this._searchText

            Poster.postMember<RemoteResult<GoogleLocationList>>(
              RestPaths.locationListByTextOSM,
              request
            ).then((response) => {
              if (response.data.success) {
                this.locations = []
                response.data.payload.list.forEach((element) => {
                  var b = ''
                  if (element.strasse) {
                    b += element.strasse + ' '
                  }

                  if (element.strasseNr) {
                    b += element.strasseNr + ', '
                  }

                  if (element.plz) {
                    b += element.plz + ' '
                  }

                  if (element.stadt) {
                    b += element.stadt + ', '
                  }

                  if (element.land) {
                    b += element.land
                  }

                  element.bezeichnung = b
                  this.locations.push(element)
                })
              }
            })
          }
        }
      }, 200)
  }

  get selectedLocation(): GoogleLocation {
    return this._selectedLocation
  }

  set selectedLocation(newValue: GoogleLocation) {
    this._selectedLocation = newValue

    var updateStandort = {
      bezeichnung: this._selectedLocation.bezeichnung,
      land: this._selectedLocation.land,
      plz: this._selectedLocation.plz,
      ort: this._selectedLocation.stadt,
      strasse:
        this._selectedLocation.strasse + ' ' + this._selectedLocation.strasseNr,
      latitude: this._selectedLocation.latitude,
      longitude: this._selectedLocation.longitude,
    }

    this.standort = vuexStore.state.standort.bezeichnung

    vuexStore.commit(StoreOps.UPDATE_STANDORT, updateStandort)
  }

  editStandort() {
    this.editStandortMode = true
  }

  deleteStandort() {
    this.standort = null
  }

  positionErmitteln(navigator) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const lat = pos.coords.latitude
        const lon = pos.coords.longitude

        var request = new LatLonRequest()
        request.latitude = lat
        request.longitude = lon

        Poster.postUser<RemoteResult<GoogleLocation>>(
          RestPaths.locationLocationByCoordinatesOSM,
          request
        ).then((response) => {
          var l = response.data.payload
          var b = ''
          if (l.strasse) {
            b += l.strasse + ' '
          }

          if (l.strasseNr) {
            b += l.strasseNr + ', '
          }

          if (l.plz) {
            b += l.plz + ' '
          }

          if (l.stadt) {
            b += l.stadt + ', '
          }

          if (l.land) {
            b += l.land
          }

          l.bezeichnung = b

          this.selectedLocation = l
        })
      })
    }

    // var request = new FilialeSearchRequest()
    // request.requestType = FilialeSearchRequest.STANDORT_GENAU
    // request.latitude = store.state.standort.latitude
    // request.longitude = store.state.standort.longitude

    // Poster.postUser<RemoteResult<BestellungFilialeList>>(
    //   RestPaths.filialeListeBestellungSuche,
    //   request
    // ).then((response) => {
    //   this.reactivefilialeList = response.data.payload.list
    // })

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((pos) => {

    //     const lat = pos.coords.latitude
    //     const lng = pos.coords.longitude

    //     fetch(
    //       RestPaths.urlBase +
    //         RestPaths.locationNext +
    //         '?lat=' +
    //         lat +
    //         '&lon=' +
    //         lng
    //     )
    //       .then((response) => response.json())
    //       .catch((response) => {}
    //       .then((data) => {
    //         this.currentLocation = data
    //         var request = new FilialeSearchRequest()
    //         request.requestType = FilialeSearchRequest.STANDORT_GENAU
    //         request.latitude = pos.coords.latitude
    //         request.longitude = pos.coords.longitude

    //         Poster.postUser<RemoteResult<BestellungFilialeList>>(
    //           RestPaths.filialeListeBestellungSuche,
    //           request
    //         ).then((response) => {
    //           this.reactivefilialeList = response.data.payload.list
    //         })
    //       })
    //   })
    // }
  }
}
