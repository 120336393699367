
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import vuexStore from '@/store'
import FilialeHeader from '../custom/FilialeHeader.vue'
import BPBeginnenRequest from '@/entities/common/pending/bp_beginnen_request'
import { Terminart } from '@/entities/common/enums'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'
import router from '@/router'
import RouteNames from '@/util/route_names'
import { Watch } from 'vue-property-decorator'
import LatLonFilialeRequest from '@/entities/common/request/lat_lon_filiale_request'
import FilialeLiefergebietResult from '@/entities/common/filiale_liefergebiet_result'
import OeffnungLabel from './sub/OeffnungLabel.vue'

@Component({ components: { FilialeHeader, OeffnungLabel } })
export default class FilialeMain extends mixins(Calculator) {
  filiale: BestellungFiliale = null
  liefergebiet: FilialeLiefergebietResult = null

  @Watch('$store.state.bestellungPending.filiale', {
    deep: true,
    immediate: true,
  })
  watchFiliale(newValue) {
    this.filiale = newValue
  }

  detailsAnzeigen() {}

  mounted() {
    this.filiale = vuexStore.state.bestellungPending.filiale

    var liefergebietRequest = new LatLonFilialeRequest()
    liefergebietRequest.filialeId = this.filiale.filialeId
    liefergebietRequest.latitude = vuexStore.state.standort.latitude
    liefergebietRequest.longitude = vuexStore.state.standort.longitude

    Poster.postUser<RemoteResult<FilialeLiefergebietResult>>(
      RestPaths.filialeLiefergebietStandort,
      liefergebietRequest
    ).then((response) => {
      if (response.data.success) {
        this.liefergebiet = response.data.payload
      }
    })
  }

  lieferungWaehlen() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.filiale.filialeId
    request.terminart = Terminart.LIEFERUNG
    request.identifier = vuexStore.state.user.identifier
    request.liefergebietId = this.liefergebiet.liefergebietId

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var update = {
        id: response.data.payload,
        liefergebiet: this.liefergebiet,
        terminart: Terminart.LIEFERUNG,
      }
      vuexStore.commit(StoreOps.BESTELLUNG_STARTEN, update)
      if (this.filiale.lieferungSettings) {
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.TERMIN_LIEFERUNG })
      } else {
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.STANDORT_LIEFERUNG })
      }
    })
  }

  abholungWaehlen() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.filiale.filialeId
    request.terminart = Terminart.ABHOLUNG
    request.identifier = vuexStore.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var update = {
        id: response.data.payload,
        terminart: Terminart.ABHOLUNG,
      }
      vuexStore.commit(StoreOps.BESTELLUNG_STARTEN, update)
      if (this.filiale.abholungSettings.supportsVorbestellung) {
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.TERMIN_ABHOLUNG })
      } else {
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.VERZEHRART })
      }
    })
  }

  reservierungWaehlen() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.filiale.filialeId
    request.terminart = Terminart.RESERVIERUNG
    request.identifier = vuexStore.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: this.filiale,
        terminart: Terminart.RESERVIERUNG,
      }
      vuexStore.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.TERMIN_RESERVIERUNG })
    })
  }

  sofortbestellungWaehlen() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.filiale.filialeId
    request.terminart = Terminart.SOFORTBESTELLUNG
    request.identifier = vuexStore.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: this.filiale,
        terminart: Terminart.SOFORTBESTELLUNG,
      }

      vuexStore.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.SOFORTBESTELLUNG_TISCH })
    })
  }
}
