
import Component from 'vue-class-component'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import DetailsAbholung from './DetailsAbholung.vue'
import DetailsReservierung from './DetailsReservierung.vue'
import DetailsSofort from './DetailsSofort.vue'
import DetailsLogic from '@/logic/bestellung/details_logic'
import DetailsLieferung from './DetailsLieferung.vue'

@Component({
  components: {
    DetailsAbholung,
    DetailsReservierung,
    DetailsSofort,
    DetailsLieferung,
  },
})
export default class Details extends mixins(Calculator) {
  logic?: DetailsLogic = null

  mounted() {
    this.logic = new DetailsLogic()
    this.logic.init(this.$route.params.bestellungId)
  }
}
