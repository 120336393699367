import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import { Bezahlart, LoadState, Terminart } from '@/entities/common/enums'
import BPAufgebenRequest from '@/entities/common/pending/bp_aufgeben_request'
import RemoteResult from '@/entities/common/remote_result'
import router from '@/router'
import store from '@/store'
import {
  Abholung,
  Reservierung,
  Sofortbestellung,
} from '@/entities/common/bestellung_pending'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class BestaetigenLogic {
  bestaetigenLoadState: string = LoadState.IDLE
  bestellung?: Bestellung = null
  filiale?: BestellungFiliale = null
  warenkorb: BstlArtikel[] = []
  terminart?: string = null
  bezahlart?: string = null

  abholungData: Abholung
  reservierungDate: Reservierung
  sofortData: Sofortbestellung

  init() {
    var nbd = store.state.bestellungPending

    this.bezahlart = nbd.bezahlart

    this.warenkorb = nbd.artikelliste
    this.filiale = nbd.filiale
    this.terminart = nbd.terminart

    if (this.terminart === Terminart.ABHOLUNG) {
      this.abholungData = nbd.abholung
    } else if (this.terminart === 'RESERVIERUNG') {
      this.reservierungDate = nbd.reservierung
    } else if (this.terminart === 'SOFORT') {
      this.sofortData = nbd.sofort
    }
  }

  bestellungBestaetigen() {
    this.bestaetigenLoadState = LoadState.LOADING

    var request = new BPAufgebenRequest()
    request.bestellungId = store.state.bestellungPending.id
    request.bezahlart = this.bezahlart

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungpendingAufgeben,
      request
    )
      .then((response) => {
        if (response.data.success) {
          var dto = response.data.payload

          store.commit(StoreOps.UPDATE_BESTELLUNG, dto)
          store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
          if (dto.bezahlart == Bezahlart.PAYPAL) {
            router.push({
              name: RouteNames.NEU.BEZAHLUNG_WEITERLEITEN,
              params: {
                bestellungId: dto.id,
              },
            })
            return
          }

          router.push({
            name: RouteNames.BESTELLUNG_PAGE.DETAILS,
            params: { bestellungId: dto.id },
          })
        } else {
          this.bestaetigenLoadState = LoadState.ERROR
        }
      })
      .catch(() => {
        this.bestaetigenLoadState = LoadState.ERROR
      })

    // var terminart = store.state.neueBestellungData.terminart

    // if (terminart == Terminart.SOFORTBESTELLUNG) {
    //   var arequest = new BestellungInsertArtikellisteRequest()
    //   arequest.bestellungId = store.state.neueBestellungData.bestellungId
    //   this.warenkorb.forEach((element) => {
    //     arequest.artikelliste.push(ConverterUtil.convertBstlArtikel(element))
    //   })

    //   Poster.postUser<RemoteResult<DateTimeCapsule>>(
    //     RestPaths.bestellungKundeEinfuegenArtikelliste,
    //     arequest
    //   )
    //     .then((response) => {
    //       if (response.data.success) {
    //         store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
    //         router.push({ name: RouteNames.NEU.BESTAETIGT })
    //       } else {
    //       }
    //     })
    //     .catch(() => {
    //     })
    // } else if (terminart == Terminart.ABHOLUNG) {
    //   var irequest = new BestellungInsertRequest()
    //   var nbd = store.state.neueBestellungData as Nbd
    //   irequest.id = nbd.bestellungId
    //   irequest.identifier = store.state.user.identifier
    //   irequest.terminart = terminart
    //   irequest.bezahlart = this.bezahlart
    //   this.warenkorb.forEach((element) => {
    //     irequest.artikelliste.push(ConverterUtil.convertBstlArtikel(element))
    //   })
    //   irequest.filialeId = nbd.filiale.filialeId
    //   irequest.createAbholung()
    //   irequest.abholung.id = uuidv4()
    //   irequest.abholung.kundeNachname = nbd.abholungData.kundeNachname
    //   irequest.abholung.kundeVorname = nbd.abholungData.kundeVorname
    //   irequest.abholung.kundeTelefon = nbd.abholungData.kundeTelefon
    //   irequest.abholung.kundeEmail = nbd.abholungData.kundeEmail
    //   irequest.abholung.type = nbd.abholungData.abholzeitType
    //   if (nbd.abholungData.abholzeitType == AbholzeitType.BALDEST) {
    //     //irequest.abholung.gewAbholZp = nbd.abholungData.datum
    //   } else {
    //     irequest.abholung.gewAbholZp =
    //       nbd.abholungData.datum + 'T' + nbd.abholungData.gewaehlteUhrzeit
    //   }
    //   irequest.abholung.direktabholung = nbd.abholungData.direktabholung
    //   irequest.abholung.tischId = nbd.abholungData.tischId
    //   irequest.abholung.verzehrart = nbd.abholungData.verzehrart

    //   Poster.postUser<RemoteResult<Bestellung>>(
    //     RestPaths.bestellungKundeEinfuegen,
    //     irequest
    //   )
    //     .then((response) => {
    //       if (response.data.success) {
    //         var bestellungId = store.state.neueBestellungData.bestellungId

    //         store.commit(StoreOps.UPDATE_BESTELLUNG, response.data.payload)

    //         if (this.bezahlart == Bezahlart.PAYPAL) {
    //           router.push({
    //             name: RouteNames.NEU.BEZAHLUNG_WEITERLEITEN,
    //             params: { bestellungId: bestellungId },
    //           })
    //           store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
    //           return
    //           // var request = new CheckoutRequest()
    //           // request.id = bestellungId
    //           // request.checkoutMode = 1

    //           // Poster.postUser<RemoteResult<CheckoutOrder>>(
    //           //   RestPaths.paymentPaypalCheckout,
    //           //   request
    //           // ).then((response) => {
    //           //   window.location.href =
    //           //     'https://www.sandbox.paypal.com/checkoutnow?token=' +
    //           //     response.data.payload.orderId
    //           // })
    //           // return
    //         }

    //     router.push({
    //       name: RouteNames.BESTELLUNG.DETAILS,
    //       params: { id: bestellungId },
    //     })
    //     store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
    //   } else {
    //     this.bestaetigenLoadState = LoadState.ERROR
    //   }
    // })
    // .catch((error) => {
    //   this.bestaetigenLoadState = LoadState.ERROR
    // })
    // }
  }
}
