
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ProduktExtrasLogic from '@/logic/member/produkt_extras_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({ components: { ToolbarButton } })
export default class ProduktExtrasMemCommon extends mixins(Calculator) {
  @Prop() logic: ProduktExtrasLogic

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  artenHinzufuegenAbbrechen() {
    this.logic.artenHinzufuegenAbbrechen()
  }

  artenHinzufuegenBestaetigen() {
    this.logic.artenHinzufuegenBestaetigen()
  }

  artenHinzufuegen() {
    this.logic.artenHinzufuegen()
  }

  extraSpeichern() {
    this.logic.extraSpeichern()
  }

  inhaltsstoffeHinzufuegen() {
    this.logic.inhaltsstoffeHinzufuegen()
  }

  inhaltsstoffeEditVerwalten() {
    this.logic.inhaltsstoffeEditVerwalten()
  }

  inhaltsstoffeVerwalten() {
    this.logic.inhaltsstoffeVerwalten()
  }

  neuesExtra() {
    this.logic.neuesExtra()
  }

  produktEinfuegen() {
    this.logic.produktEinfuegen()
  }

  produktBearbeiten(produkt, event) {
    this.logic.produktBearbeiten(produkt, event)
  }

  detailsAnzeigen(produkt) {
    this.logic.detailsAnzeigen(produkt)
  }
}
