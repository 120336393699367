
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Bestellung from '@/entities/common/bestellung'
import Filiale from '@/components/custom/Filiale.vue'
import DetailsAbholungLogic from '@/logic/bestellung/details_abholung_logic'
import { Bezahlart, Verzehrart } from '@/entities/common/enums'
import VerzehrartLogic from '@/logic/bestellung_neu/verzehrart_logic'

@Component({ components: { ArtikelRow, FilialeHeader, Filiale } })
export default class DetailsAbholung extends mixins(Calculator) {
  logic?: DetailsAbholungLogic = null
  stornierenDialogVisible: boolean = false
  screenLock?: any
  isWatched: boolean = false
  video?: any = null
  watchDialogVisible: boolean = false

  loadVideo() {
    this.video = document.createElement('video')
    this.video.setAttribute('loop', '')
    // Add some styles if needed
    this.video.setAttribute('style', 'position: fixed;')
    this.addSourceToVideo(
      this.video,
      'webm',
      this.base64(
        'video/webm',
        'GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA='
      )
    )
    this.addSourceToVideo(
      this.video,
      'mp4',
      this.base64(
        'video/mp4',
        'AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=='
      )
    )
    document.body.appendChild(this.video)
    document.body.addEventListener('touchend', this.playFn)
  }
  // Create the root video element

  createPdf() {
    this.logic.createPdf()
  }

  // A helper to add sources to video
  addSourceToVideo(element, type, dataURI) {
    var source = document.createElement('source')
    source.src = dataURI
    source.type = 'video/' + type
    element.appendChild(source)
  }

  // A helper to concat base64
  base64(mimeType, base64): string {
    return 'data:' + mimeType + ';base64,' + base64
  }

  // Add Fake sourced

  // Append the video to where ever you need

  // Start playing video after any user interaction.
  // NOTE: Running video.play() handler without a user action may be blocked by browser.
  playFn() {
    if (this.isScreenLockSupported) {
      this.getScreenLock()
    } else {
      //this.video.play()
      //document.body.removeEventListener('touchend', this.playFn)
    }
    this.watchDialogVisible = false
  }

  get bestellungStornierbar() {
    return (
      !this.logic.bestellung.abgeschlossen &&
      this.logic.bestellung.abholung.status === 'ABGESENDET'
    )
  }

  formatiereBoolean(value: boolean) {
    return value ? 'ja' : 'nein'
  }

  formatiereBezahlart(value: string) {
    switch (value) {
      case Bezahlart.BARZAHLUNG:
        return 'Zahlung an der Kasse'
      case Bezahlart.PAYPAL:
        return 'PayPal'
    }
  }

  formatiereVerzehrart() {
    switch (this.logic.bestellung.abholung.verzehrart) {
      case Verzehrart.MITNAHME:
        return 'Zum Mitnehmen'
      case Verzehrart.VOR_ORT:
        return 'Verzehr im Restaurant'
    }
  }

  isScreenLockSupported() {
    return 'wakeLock' in navigator
  }

  async getScreenLock() {
    if (this.isScreenLockSupported) {
      let screenLock
      try {
        screenLock = await (navigator as any).wakeLock.request('screen')
      } catch (error) {}
      return screenLock
    } else {
    }
  }

  async doVibration() {
    navigator.vibrate([
      100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100, 30,
      100, 100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100,
      30, 100,
    ])
  }

  async doBell() {
    new Audio(require('@/assets/bing.wav')).play()
  }

  mounted() {
    this.logic = new DetailsAbholungLogic()
    this.logic.init(navigator)
    this.logic.abholbereitListener = () => {
      this.doVibration()
      this.doBell()
      //new Audio(require('@/assets/bing.wav')).play()
    }
    this.getScreenLock().then((lock) => {
      this.screenLock = lock
    })
    this.loadVideo()

    var abgeschlossen = this.logic.bestellung.abgeschlossen
    this.watchDialogVisible = !abgeschlossen
  }

  destroyed() {
    this.screenLock.release()
  }

  reloadBestellung() {
    this.logic.reloadBestellung()
  }

  computeAbholzeit() {
    return this.logic.computeAbholzeit()
  }

  bestellungAbholbereit() {
    return this.logic.bestellungAbholbereit()
  }

  bestimmeBild(bestellung: Bestellung) {
    return this.logic.bestimmeBild(bestellung)
  }

  formatiereStatus() {
    return this.logic.formatiereStatus()
  }

  formatiereTisch() {
    return this.logic.formatiereTisch()
  }

  stornierenBestaetigen() {
    this.logic.stornieren(() => {
      //this.stornierenDialogVisible = false
    })
  }

  stornieren() {
    this.stornierenDialogVisible = true
  }
}
