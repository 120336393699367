
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LinkBox from '@/components/custom/LinkBox.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import ProdukteMemcommonLogic from '@/logic/memcommon/produkte_memcommon_logic'
import Pdukt from '@/entities/member/pdukt'

@Component({ components: { LinkBox, ToolbarButton, ErrorBox } })
export default class ProdukteMemCommon extends Vue {
  @Prop() logic: ProdukteMemcommonLogic

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  loadListe() {
    this.logic.loadListe()
  }

  detailsAnzeigen(produkt: Pdukt) {
    this.logic.detailsAnzeigen(produkt)
  }

  inhaltsstoffeVerwalten() {
    this.logic.inhaltsstoffeVerwalten()
  }

  kategorienVerwalten() {
    this.logic.kategorienVerwalten()
  }

  artenVerwalten() {
    this.logic.artenVerwalten()
  }

  extrasVerwalten() {
    this.logic.extrasVerwalten()
  }

  standardpreiseVerwalten() {
    this.logic.standardpreiseVerwalten()
  }

  produktBearbeiten(produkt: Pdukt) {
    this.logic.produktBearbeiten(produkt)
  }

  neuesProdukt() {
    this.logic.neuesProdukt()
  }
}
