
import ReservierungVorbestellungLogic from '@/logic/bestellung_neu/reservierung_vorbestellung_logic'
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import ReservierungVorbestellung from '@/components/bestellung-neu/ReservierungVorbestellung.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({
  components: {
    ArtikelRow,
    FilialeHeader,
  },
})
export default class ReservierungVorbestellungBestaetigen extends mixins(
  Calculator
) {
  logic?: ReservierungVorbestellungLogic = null

  mounted() {
    this.logic = (this.$parent as ReservierungVorbestellung).logic
  }

  geheZurBestellung() {
    this.logic.geheZurBestellung()
  }

  bestellungBestaetigen() {
    this.logic.bestellungBestaetigen()
  }
}
