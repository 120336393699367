
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import BestellungSimple from '@/entities/member/bestellung_simple'
import router from '@/router'
import RouteNames from '@/util/route_names'
import IdRequest from '@/entities/common/request/id_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import BestellungList from '@/entities/common/lists/bestellung_list'
import RestPaths from '@/util/rest_paths'
import { Terminart } from '@/entities/common/enums'

@Component
export default class AktuelleBestellungen extends mixins(Calculator) {
  bestellungen: BestellungSimple[] = []
  headers: any[] = [
    { text: 'Bestellzeitp.', value: 'bestellZp' },
    { text: 'Terminart', value: 'terminart' },
    { text: 'Abgeschlossen', value: 'abgeschlossen' },
    { text: 'Storniert', value: 'storniert' },
    { text: 'Bezahlt', value: 'bezahlt' },
  ]

  formatiereTerminartIcon(value: string): string {
    if (!value) return null
    switch (value) {
      case Terminart.ABHOLUNG:
        return '$vuetify.icons.abholung'
      case Terminart.LIEFERUNG:
        return 'mdi-moped-outline'
    }
  }

  formatiereTerminart(value: string): string {
    if (!value) return null
    switch (value) {
      case Terminart.ABHOLUNG:
        return 'Abholung'
      case Terminart.LIEFERUNG:
        return 'Lieferung'
      case Terminart.SOFORTBESTELLUNG:
        return 'Tischbestellung'
      case Terminart.RESERVIERUNG:
        return 'Reservierung'
    }
  }

  gebeBooleanClass(value: boolean): string {
    return value ? 'green--text' : 'red--text'
  }

  handleRowClick(value) {
    router.push({
      name: RouteNames.MEMBER.BESTELLUNG,
      params: { id: value.id },
    })
  }

  mounted() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.filiale.id

    Poster.postMember<RemoteResult<BestellungList>>(
      RestPaths.bestellungMitarbeiterAktuelleBestellungen,
      request
    ).then((response) => {
      this.bestellungen = response.data.payload.list
    })
  }
}
