
import MitarbeiterLogic from '@/logic/member/mitarbeiter_logic'
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class Mitarbeiter extends Vue {
  @Prop() logic: MitarbeiterLogic

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  loadListe() {
    this.logic.loadListe()
  }

  neuerMitarbeiter() {
    this.logic.neuerMitarbeiter()
  }

  detailsAnzeigen(id: string) {
    this.logic.detailsAnzeigen(id)
  }
}
