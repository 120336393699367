
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ErrorBox from '../custom/ErrorBox.vue'
import ProduktNeuMemcommonLogic, {
  PduktArtPreis,
} from '@/logic/memcommon/produkt_neu_memcommon_logic'
import PduktArt from '@/entities/member/pdukt_art'

@Component({ components: { ErrorBox } })
export default class ProduktNeuMemCommon extends Vue {
  @Prop() logic: ProduktNeuMemcommonLogic

  //neuerInhaltsstoffVisible: boolean = false
  //neuesExtraVisible: boolean = false

  //neuerInhaltsstoff: Inhaltsstoff = null
  //neuesExtra: Extra = null

  $refs: {
    form: HTMLFormElement
  }

  removeProduktart(pa: PduktArtPreis) {
    this.logic.removeProduktart(pa)
  }

  neueProduktartErstellen() {
    this.logic.neueProduktartErstellen()
  }

  pushProduktart(pa: PduktArtPreis) {
    this.logic.pushProduktart(pa)
  }

  produktartenAbbrechen() {
    this.logic.produktartenAbbrechen()
  }

  produktartenSpeichern() {
    this.logic.produktartenSpeichern()
  }

  neueProduktartAbbrechen() {
    this.logic.neueProduktartAbbrechen()
  }

  neueProduktartSpeichern() {
    this.logic.neueProduktartSpeichern()
  }

  produktartEntfernen(index: number) {
    this.logic.produktartEntfernen(index)
  }

  moveProduktartUp(index: number) {
    this.logic.moveProduktartUp(index)
  }

  moveProduktartDown(index: number) {
    this.logic.moveProduktartDown(index)
  }

  neueVarianteAbbrechen() {
    this.logic.neueVarianteAbbrechen()
  }

  neueVarianteEinfuegen() {
    this.logic.neueVarianteEinfuegen()
  }

  varianteErstellen() {
    this.logic.varianteErstellen()
  }

  artenVerwalten() {
    this.logic.artenVerwalten()
  }

  varianteBearbeiten(index: number) {
    this.logic.varianteBearbeiten(index)
  }

  varianteEntfernen(index: number) {
    this.logic.varianteEntfernen(index)
  }

  moveVarianteUp(index: number) {
    this.logic.moveVarianteUp(index)
  }

  moveVarianteDown(index: number) {
    this.logic.moveVarianteDown(index)
  }

  inhaltsstoffEntfernen(index: number) {
    this.logic.inhaltsstoffEntfernen(index)
  }

  extraEntfernen(index: number) {
    this.logic.extraEntfernen(index)
  }

  swapElements = (array, index1, index2) => {
    array[index1] = array.splice(index2, 1, array[index1])[0]
  }

  moveExtraUp(index: number) {
    this.logic.moveExtraUp(index)
  }

  moveExtraDown(index: number) {
    this.logic.moveExtraDown(index)
  }

  moveAuswahlUp(index: number) {
    this.logic.moveAuswahlUp(index)
  }

  moveAuswahlDown(index: number) {
    this.logic.moveAuswahlDown(index)
  }

  moveInhaltsstoffUp(index: number) {
    this.logic.moveInhaltsstoffUp(index)
  }

  moveInhaltsstoffDown(index: number) {
    this.logic.moveInhaltsstoffDown(index)
  }

  auswahlBearbeiten(index: number) {
    this.logic.auswahlBearbeiten(index)
  }

  auswahlEntfernen(index: number) {
    this.logic.auswahlEntfernen(index)
  }

  subproduktHinzufuegen() {
    this.logic.subproduktHinzufuegen()
  }

  neuesSubproduktEinfuegen() {
    this.logic.neuesSubproduktEinfuegen()
  }

  inhaltsstoffeVerwalten() {
    this.logic.inhaltsstoffeVerwalten()
  }

  auswahlErstellen() {
    this.logic.auswahlErstellen()
  }

  editAuswahlAbbrechen() {
    this.logic.editAuswahlAbbrechen()
  }

  editAuwahlSpeichern() {
    this.logic.editAuswahlSpeichern()
  }

  extrasVerwalten() {
    this.logic.extrasVerwalten()
  }

  inhaltsstoffeSpeichern() {
    this.logic.inhaltsstoffeSpeichern()
  }

  inhaltsstoffeAbbrechen() {
    this.logic.inhaltsstoffeAbbrechen()
  }

  extrasHinzufuegen() {
    this.logic.extrasHinzufuegen()
  }

  extrasAbbrechen() {
    this.logic.extrasAbbrechen()
  }

  auswahlEinfuegen() {
    this.logic.auswahlEinfuegen()
  }

  auswahlAbbrechen() {
    this.logic.auswahlAbbrechen()
  }

  produktEinfuegen() {
    var valid = this.$refs.form.validate()
    this.logic.mainFormValid = valid
    if (valid) {
      this.logic.produktEinfuegen()
    }
  }

  uploadImage() {
    this.logic.uploadImage()
  }

  previewImage() {
    this.logic.previewImage()
  }

  subproduktBearbeiten(index: number) {}

  subproduktLoeschen(index: number) {
    this.logic.subproduktLoeschen(index)
  }

  moveSubproduktUp(index: number) {
    this.logic.moveSubproduktUp(index)
  }

  moveSubproduktDown(index: number) {
    this.logic.moveSubproduktDown(index)
  }

  // neuerInhaltsstoffErstellen() {
  //   this.logic.neuerInhaltsstoffErstellen()
  //   this.neuerInhaltsstoff = new Inhaltsstoff()
  //   this.neuerInhaltsstoffVisible = true
  // }

  neuesExtraErstellen() {
    this.logic.neuesExtraErstellen()
    // this.neuesExtra = new Extra()
    // this.neuesExtra.produkt = new Pdukt()
    // this.neuesExtraVisible = true
  }

  neuesExtraAbbrechen() {
    this.logic.neuesExtraAbbrechen()
  }

  neuesExtraEinfuegen() {
    this.logic.neuesExtraEinfuegen()
    //this.neuesExtraVisible = false
  }

  neuerInhaltsstoffErstellen() {
    this.logic.neuerInhaltsstoffErstellen()
  }

  neuerInhaltsstoffEinfuegen() {
    this.logic.neuerInhaltsstoffEinfuegen()
    //this.neuerInhaltsstoffVisible = false
  }

  neuerInhaltsstoffAbbrechen() {
    this.logic.neuerInhaltsstoffAbbrechen()
  }

  goBack() {
    this.logic.goBack()
  }

  removeExtra(extra) {
    this.logic.removeExtra(extra)
  }

  pushExtra(extra) {
    this.logic.pushExtra(extra)
  }

  removeInhaltsstoff(inhaltsstoff) {
    this.logic.removeInhaltsstoff(inhaltsstoff)
  }

  pushInhaltsstoff(inhaltsstoff) {
    this.logic.pushInhaltsstoff(inhaltsstoff)
  }
}
