
import Calculator from '@/components/mixins/Calculator.vue'
import BstlArtikel from '@/entities/common/bstl_artikel'
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class WarenkorbArtikel extends mixins(Calculator) {
  @PropSync('artikel') artikelSync: BstlArtikel
  @Prop() primaryColor: number
  @Prop() secondaryColor: number

  @Emit('increment')
  increment() {}

  @Emit('decrement')
  decrement() {}

  @Emit('remove')
  remove() {}
}
