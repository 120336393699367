import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import { Bezahlart, LoadState } from '@/entities/common/enums'
import { Lieferung } from '@/entities/common/bestellung_pending'
import store from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'
import RouteNames from '@/util/route_names'
import router from '@/router'
import BPAufgebenRequest from '@/entities/common/pending/bp_aufgeben_request'

export default class BestaetigenLieferungLogic {
  bestaetigenLoadState: string = LoadState.IDLE
  bestellung?: Bestellung = null
  filiale?: BestellungFiliale = null
  warenkorb: BstlArtikel[] = []
  bezahlart?: string = null

  lieferungData: Lieferung = null

  init() {
    var nbd = store.state.bestellungPending

    this.bezahlart = nbd.bezahlart

    this.warenkorb = nbd.artikelliste
    this.filiale = nbd.filiale
    this.lieferungData = nbd.lieferung
  }

  bestellungBestaetigen() {
    this.bestaetigenLoadState = LoadState.LOADING

    var request = new BPAufgebenRequest()
    request.bestellungId = store.state.bestellungPending.id
    request.bezahlart = this.bezahlart

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungpendingAufgeben,
      request
    )
      .then((response) => {
        if (response.data.success) {
          var dto = response.data.payload
          store.commit(StoreOps.UPDATE_BESTELLUNG, dto)
          store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
          if (dto.bezahlart == Bezahlart.PAYPAL) {
            router.push({
              name: RouteNames.NEU.BEZAHLUNG_WEITERLEITEN,
              params: {
                bestellungId: dto.id,
              },
            })
            return
          }

          router.push({
            name: RouteNames.BESTELLUNG_PAGE.DETAILS,
            params: { bestellungId: dto.id },
          })
        } else {
          this.bestaetigenLoadState = LoadState.ERROR
        }
      })
      .catch(() => {
        this.bestaetigenLoadState = LoadState.ERROR
      })

    // var nbd = store.state.neueBestellungData

    // this.bestaetigenLoadState = LoadState.LOADING
    // var request = new BestellungInsertRequest()
    // request.id = nbd.bestellungId
    // request.identifier = store.state.user.identifier
    // request.terminart = Terminart.LIEFERUNG
    // request.bezahlart = Bezahlart.BARZAHLUNG

    // this.warenkorb.forEach((element) => {
    //   request.artikelliste.push(ConverterUtil.convertBstlArtikel(element))
    // })
    // request.filialeId = nbd.filiale.filialeId
    // request.createLieferung()
    // request.lieferung.id = uuidv4()
    // request.lieferung.kundeNachname = nbd.lieferungData.kundeNachname
    // request.lieferung.kundeVorname = nbd.lieferungData.kundeVorname
    // request.lieferung.kundeStrasse = nbd.lieferungData.kundeStrasse
    // request.lieferung.kundePlz = nbd.lieferungData.kundePlz
    // request.lieferung.kundeOrt = nbd.lieferungData.kundeOrt
    // request.lieferung.kundeAdresszusatz = nbd.lieferungData.kundeAdresszusatz
    // request.lieferung.kundeTelefon = nbd.lieferungData.kundeTelefon
    // request.lieferung.kundeEmail = nbd.lieferungData.kundeEmail
    // request.lieferung.zeitType = nbd.lieferungData.abholzeitType
    // request.lieferung.lieferzeitpunkt =
    //   nbd.lieferungData.datum + 'T' + nbd.lieferungData.gewaehlteUhrzeit
    // request.lieferung.liefergebietId =
    //   nbd.filiale.lieferungSettings.liefergebietId

    // Poster.postUser<RemoteResult<Bestellung>>(
    //   RestPaths.bestellungKundeEinfuegen,
    //   request
    // ).then((response) => {
    //   if (response.data.success) {
    //     var bestellungId = store.state.neueBestellungData.bestellungId

    //     store.commit(StoreOps.UPDATE_BESTELLUNG, response.data.payload)

    //     router.push({
    //       name: RouteNames.BESTELLUNG.DETAILS,
    //       params: { id: bestellungId },
    //     })
    //     store.commit(StoreOps.CLEAR_NEUE_BESTELLUNG, null)
    //   }
    // })
  }
}
