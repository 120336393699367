import RemoteResult from '@/entities/common/remote_result'
import PduktList, { PduktKategorie } from '@/entities/member/lists/pdukt_list'
import PduktRequest from '@/entities/member/request/pdukt_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import store from '@/store'
import Pdukt from '@/entities/member/pdukt'
import MemberBaseLogic from '../member_base_logic'

export default abstract class ProdukteMemcommonLogic extends MemberBaseLogic {
  produkte: PduktKategorie[] = []
  listWorking: boolean = false
  listError: boolean = false
  betriebId: string = null

  abstract detailsAnzeigen(produkt: Pdukt): void

  abstract inhaltsstoffeVerwalten(): void

  abstract kategorienVerwalten(): void

  abstract artenVerwalten(): void

  abstract extrasVerwalten(): void

  abstract standardpreiseVerwalten(): void

  abstract produktBearbeiten(produkt: Pdukt): void

  abstract neuesProdukt(): void

  loadListe() {
    this.listWorking = true
    this.listError = false
    var pduktRequest = new PduktRequest()
    pduktRequest.betriebId = this.betriebId
    pduktRequest.extraZusatz = false

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      pduktRequest
    )
      .then((response) => {
        this.listWorking = false
        if (response.data.success) {
          this.produkte = response.data.payload.kategorien
          this.listError = false
        } else {
          this.listError = true
        }
      })
      .catch(() => {
        this.listWorking = false
        this.listError = true
      })
  }

  init() {
    this.loadListe()
  }
}