const LoadState = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
}

const Bezahlart = {
  BARZAHLUNG: 'BARZAHLUNG',
  PAYPAL: 'PAYPAL',
}

const Terminart = {
  ABHOLUNG: 'ABHOLUNG',
  RESERVIERUNG: 'RESERVIERUNG',
  SOFORTBESTELLUNG: 'SOFORTBESTELLUNG',
  LIEFERUNG: 'LIEFERUNG',
}

const AbholzeitType = {
  BALDEST: 'BALDEST',
  UHRZEIT: 'UHRZEIT',
}

const AbholungStatus = {
  ABGESENDET: 'ABGESENDET',
  ANGENOMMEN: 'ANGENOMMEN',
  IN_BEARBEITUNG: 'IN_BEARBEITUNG',
  ABGELEHNT: 'ABGELEHNT',
  ABHOLBEREIT: 'ABHOLBEREIT',
  ABGEHOLT: 'ABGEHOLT',
  NICHT_ABGEHOLT: 'NICHT_ABGEHOLT',
}

const LieferungStatus = {
  ABGESENDET: 'ABGESENDET',
  ANGENOMMEN: 'ANGENOMMEN',
  ABGELEHNT: 'ABGELEHNT',
  IN_ZUBEREITUNG: 'IN_ZUBEREITUNG',
  ZUBEREITET: 'ZUBEREITET',
  IN_LIEFERUNG: 'IN_LIEFERUNG',
  GELIEFERT: 'GELIEFERT',
}

const ReservierungStatus = {
  RESERVIERT: 'RESERVIERT',
  RESERVIERUNG_ANGENOMMEN: 'RESERVIERUNG_ANGENOMMEN',
  EINGEBUCHT: 'EINGEBUCHT',
  ANGENOMMEN: 'ANGENOMMEN',
}

const BuchungBestellart = {
  SERVICE: 'SERVICE',
  SELBST: 'SELBST',
}

const Reservierungart = {
  ONLINE: 'ONLINE',
  MITARBEITER: 'MITARBEITER',
}

const AnforderungStatus = {
  OFFEN: 'OFFEN',
  ANGENOMMEN: 'ANGENOMMEN',
  ABGELEHNT: 'ABGELEHNT',
  ERLEDIGT: 'ERLEDIGT',
}

const KuecheStatus = {
  OFFEN: 'OFFEN',
  IN_BEARBEITUNG: 'IN_BEARBEITUNG',
  ERLEDIGT: 'ERLEDIGT',
}

const NachbestellungStatus = {
  BESTELLT: 'BESTELLT',
  ANGENOMMEN: 'ANGENOMMEN',
  ERLEDIGT: 'ERLEDIGT',
}

const ErrorCode = {
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  AUTH_ERROR: 'AUTH_ERROR',
}

const Verzehrart = {
  MITNAHME: 'MITNAHME',
  VOR_ORT: 'VOR_ORT',
}

const TrinkgeldType = {
  PERCENT_5: 'PERCENT_5',
  PERCENT_10: 'PERCENT_10',
  CUSTOM: 'CUSTOM',
  NO_TIP: 'NO_TIP',
}

export {
  LoadState,
  Bezahlart,
  Terminart,
  AbholzeitType,
  AbholungStatus,
  BuchungBestellart,
  Reservierungart,
  AnforderungStatus,
  ReservierungStatus,
  LieferungStatus,
  KuecheStatus,
  NachbestellungStatus,
  ErrorCode,
  Verzehrart,
  TrinkgeldType
}
