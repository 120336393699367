
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import PduktStandardpreise, {
  PduktStandardpreis,
} from '@/entities/member/pdukt_standardpreise'
import IdRequest from '@/entities/common/request/id_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import PduktStandardpreiseUpdateRequest, {
  Produkt,
  Variante,
} from '@/entities/member/request/pdukt_standardpreise_update_request'

@Component
export default class Standardpreise extends mixins(Calculator) {
  produkte: PduktStandardpreis[] = []
  extras: PduktStandardpreis[] = []

  mounted() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<PduktStandardpreise>>(
      RestPaths.artikelStandardpreise,
      request
    ).then((response) => {
      if (response.data.success) {
        this.produkte = response.data.payload.produkte
        this.extras = response.data.payload.extras
      }
    })
  }

  speichern() {
    var request = new PduktStandardpreiseUpdateRequest()
    this.produkte.forEach((p) => {
      var produkt = new Produkt()
      produkt.id = p.id
      produkt.standardpreis = p.standardpreis
      request.produkte.push(produkt)

      p.varianten.forEach((v) => {
        var variante = new Variante()
        variante.id = v.id
        variante.standardpreis = v.standardpreis
        produkt.varianten.push(variante)
      })
    })

    this.extras.forEach((e) => {
      var extra = new Produkt()
      extra.id = e.id
      extra.standardpreis = e.standardpreis
      request.produkte.push(extra)

      e.varianten.forEach((v) => {
        var variante = new Variante()
        variante.id = v.id
        variante.standardpreis = v.standardpreis
        extra.varianten.push(variante)
      })
    })

    Poster.postMember<RemoteResult<any>>(
      RestPaths.artikelUpdateStandardpreise,
      request
    ).then((response) => {})
  }

  renderRowStyle(index: number) {
    return index % 2 == 1
      ? 'background-color: #efefef'
      : 'background-color: transparent'
  }
}
