
import BstlArtikel from '@/entities/common/bstl_artikel'
import ReservierungVorbestellungLogic from '@/logic/bestellung_neu/reservierung_vorbestellung_logic'
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import ReservierungVorbestellung from './ReservierungVorbestellung.vue'
import ArtikelEdit from '../custom/ArtikelEdit.vue'

@Component({ components: { ArtikelEdit } })
export default class ReservierungVorbestellungArtikel extends Vue {
  logic?: ReservierungVorbestellungLogic = null

  added(value: BstlArtikel) {
    this.logic.addArtikel(value)
  }

  mounted() {
    this.logic = (this.$parent as ReservierungVorbestellung).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }
}
