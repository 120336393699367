import BestellungFiliale from './bestellung_filiale'
import BstlArtikel from './bstl_artikel'

export default class BestellungPending {
  id: string
  terminart: string
  artikelliste: BstlArtikel[] = []
  filiale: BestellungFiliale
  bezahlart: string
  trinkgeldType: string
  trinkgeld: number

  reservierung: Reservierung = new Reservierung()
  abholung: Abholung = new Abholung()
  sofort: Sofortbestellung = new Sofortbestellung()
  lieferung: Lieferung = new Lieferung()
}

class Reservierung {
  zeitVon: string
  zeitBis: string
  kundeVorname: string
  kundeNachname: string
  kundeTelefon: string
  kundeEmail: string
  anzahlPersonen: number
}

class Abholung {
  abholzeitType: string
  gewaehlteUhrzeit: string
  kundeNachname: string
  kundeVorname: string
  kundeTelefon: string
  kundeEmail: string
  direktabholung: boolean = false
  tischId: string
  tischBezeichnung: string
  verzehrart: string
}

class Sofortbestellung {
  tischId: string
  tischBezeichnung: string
  bestellart: string
}

class Lieferung {
  abholzeitType: string
  gewaehlteUhrzeit: string
  kundeNachname: string
  kundeVorname: string
  kundeTelefon: string
  kundeEmail: string
  kundeStrasse: string
  kundePlz: string
  kundeOrt: string
  kundeAdresszusatz: string
  kommentare: string
  liefergebietId: string
  lieferkosten: number
}

export { Reservierung, Abholung, Sofortbestellung, Lieferung }
