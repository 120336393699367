export default class PlisteInsertRequest {
  id?: string
  filialeId?: string
  bezeichnung?: string
  beschreibung?: string
  gueltigVon?: string
  gueltigBis?: string
  tageskarte: boolean = false
  zeitbegrenzung: boolean = false
  zeitVon?: string
  zeitBis?: string
  kategorien?: Kategorie[] = []
  vorgaengerId?: string
  aktivieren: boolean = true
}

class Kategorie {
  id?: string
  index?: number
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitbegrenzungZeitraeume: KategorieZeitraum[] = []
  produkte?: Produkt[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitBis?: string
}

class Produkt {
  index?: number
  produktId?: string
  artId?: string
  kategorieId?: string
  preis?: number
  arten?: Art[] = []
  shortcut?: string
  extras?: Extra[] = []
  auswahlen?: Auswahl[] = []
  subprodukte?: Subprodukt[] = []
}

class Extra {
  index?: number
  pduktExtraId?: string
  preis?: number
  arten: Art[] = []
}

class Auswahl {
  index?: number
  auswahlId?: string
  optionen?: Option[] = []
}

class Option {
  index?: number
  auswahlOptionId?: string
  preis?: number
  arten: Art[] = []
}

class Subprodukt {
  index?: number
  subproduktId?: string
  optionen?: Produkt[] = []
}

class Art {
  id: string
  pduktArtId: string
  preis: number
}

export {
  Kategorie,
  KategorieZeitraum,
  Produkt,
  Extra,
  Art,
  Auswahl,
  Option,
  Subprodukt,
}