import BestellungFiliale from '@/entities/common/bestellung_filiale'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import { BuchungBestellart } from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import BuchungsartInsertRequest from '@/entities/common/request/buchungsart_insert_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import { response } from 'express'

export default class SofortSelectLogic {
  filiale?: BestellungFiliale = null
  serviceAngefordertCompleted: boolean = false
  serviceAngefordertWorking: boolean = false
  serviceAngefordertError: boolean = false

  constructor() {
    this.filiale = store.state.bestellungPending.filiale
  }

  zurueckZurBestellung() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: store.state.bestellungPending.id },
    })
  }

  serviceRufen() {
    this.serviceAngefordertWorking = true
    var nbd = store.state.bestellungPending

    var request = new BuchungsartInsertRequest()
    request.id = nbd.id
    request.bestellart = BuchungBestellart.SERVICE

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeTischBuchungsartWaehlen,
      request
    ).then((response) => {
      this.serviceAngefordertWorking = false
      if (response.data.success) {
        this.serviceAngefordertCompleted = true
      }
    })
  }

  selbstBestellen() {
    var nbd = store.state.bestellungPending
    var request = new BuchungsartInsertRequest()
    request.id = nbd.id
    request.bestellart = BuchungBestellart.SELBST

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeTischBuchungsartWaehlen,
      request
    ).then((response) => {
      if (response.data.success) {
        router.push({ name: RouteNames.NEU.SHOPPING.PRODUKTE })
      }
    })
  }
}
