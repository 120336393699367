
import AuswertungLogic from '@/logic/member/auswertung_logic'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import AuswertungFiliale, {
  AuswertungArtikel,
} from '@/entities/member/auswertung_filiale'

@Component
export default class Auswertung extends mixins(Calculator) {
  logic?: AuswertungLogic = null
  percentageRowWidth: number = 300

  vonMenuOpen?: boolean = false
  bisMenuOpen?: boolean = false

  mounted() {
    this.logic = new AuswertungLogic()
    this.logic.init()
  }

  auswerten() {
    this.logic.auswertenFiliale()
  }

  pdfExportieren() {
    this.logic.pdfExportieren()
  }

  calcStyle(artikel: AuswertungArtikel): string {
    var newWidth = this.percentageRowWidth * artikel.anteil
    // return 'width:'+ newWidth+"px"
    return 'height: 100%; background: #60d76c; width:' + newWidth + 'px'
  }
}
