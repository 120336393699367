var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.logicSync)?_c('v-container',{attrs:{"fluid":""}},[(
      _vm.logicSync.bestellung && _vm.logicSync.bestellung.terminart == 'ABHOLUNG'
    )?_c('BestellungAbholung',{attrs:{"bestellung":_vm.logicSync.bestellung}}):_vm._e(),(
      _vm.logicSync.bestellung && _vm.logicSync.bestellung.terminart == 'RESERVIERUNG'
    )?_c('BestellungReservierung',{attrs:{"bestellung":_vm.logicSync.bestellung}}):_vm._e(),(
      _vm.logicSync.bestellung &&
      _vm.logicSync.bestellung.terminart == 'SOFORTBESTELLUNG'
    )?_c('BestellungSofort',{attrs:{"bestellung":_vm.logicSync.bestellung}}):_vm._e(),(
      _vm.logicSync.bestellung && _vm.logicSync.bestellung.terminart == 'LIEFERUNG'
    )?_c('BestellungLieferung',{attrs:{"bestellung":_vm.logicSync.bestellung}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }