var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"pa-3 mb-3",attrs:{"outlined":"","color":"transparent"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',{staticClass:"text-h6 font-weight-black"},[_vm._v(_vm._s(_vm.artikelSync.anzahl)+" x ")]),_c('span',{staticClass:"text-h6 font-weight-black"},[_vm._v(_vm._s(_vm.artikelSync.produkt.bezeichnung))]),_c('span',{staticClass:"text-h6 font-weight-black blue--text"},[_vm._v(" ("+_vm._s(_vm.formatiereWaehrung(_vm.artikelSync.produkt.preis))+")")])]),_c('v-col',{staticClass:"col-auto"},[_c('v-btn',{attrs:{"icon":"","color":_vm.computeColor(_vm.secondaryColor),"title":"1 mehr"},on:{"click":_vm.increment}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1),_c('v-btn',{attrs:{"icon":"","color":_vm.computeColor(_vm.secondaryColor),"title":"1 weniger"},on:{"click":_vm.decrement}},[_c('v-icon',[_vm._v("mdi-minus-circle-outline")])],1),_c('v-btn',{attrs:{"icon":"","color":_vm.computeColor(_vm.secondaryColor),"title":"löschen"},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)],1),(_vm.artikelSync.produkt.art)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"d-block"},[_vm._v("Variante: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.artikelSync.produkt.art.bezeichnung))])])]):_vm._e(),(
        _vm.artikelSync.produkt.extras && _vm.artikelSync.produkt.extras.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"outlined":"","color":"transparent"}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Extras")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((_vm.artikelSync.produkt.extras),function(extra){return _c('div',{key:'extra' + extra.id},[_c('span',[_vm._v(_vm._s(extra.bezeichnung)+" ")]),_c('span',{staticClass:"blue--text"},[_vm._v("(+"+_vm._s(_vm.formatiereWaehrung(extra.preis))+")")])])})],2)],1):_vm._e(),(
        _vm.artikelSync.produkt.auswahlen &&
        _vm.artikelSync.produkt.auswahlen.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Auswahlen")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((_vm.artikelSync.produkt.auswahlen),function(auswahl){return _c('div',{key:'auswahl-' + auswahl.id,staticClass:"mb-2"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(auswahl.bezeichnung))]),_c('span',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(auswahl.option.bezeichnung)+" "),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(auswahl.option.preis))+")")])])])})],2)],1):_vm._e(),(
        _vm.artikelSync.produkt.subprodukte &&
        _vm.artikelSync.produkt.subprodukte.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic"},[_vm._v("Gewählte Produkte:")]),_vm._l((_vm.artikelSync.produkt.subprodukte),function(subprodukt){return _c('v-sheet',{key:'subprodukt-' + subprodukt.id,staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"font-weight-bold d-block mb-2"},[_vm._v(_vm._s(subprodukt.bezeichnung)+" (+"+_vm._s(_vm.formatiereWaehrung(subprodukt.produkt.preis))+")")]),(
              subprodukt.produkt.extras &&
              subprodukt.produkt.extras.length > 0
            )?_c('v-sheet',{staticClass:"ma-2 pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Extras")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((subprodukt.produkt.extras),function(extra){return _c('v-sheet',{key:'subextra-' + extra.id,staticClass:"d-block"},[_c('span',[_vm._v(_vm._s(extra.bezeichnung))]),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(extra.preis))+")")])])})],2):_vm._e(),(
              subprodukt.produkt.auswahlen &&
              subprodukt.produkt.auswahlen.length > 0
            )?_c('v-sheet',{attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Auswahlen")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((subprodukt.produkt.auswahlen),function(auswahl){return _c('v-sheet',{key:'subauswahl-' + auswahl.id,staticClass:"mb-2"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(auswahl.bezeichnung))]),_c('span',{staticClass:"d-block font-weight-bold"},[_c('span',[_vm._v(_vm._s(auswahl.option.bezeichnung))]),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(auswahl.option.preis))+")")])])])})],2):_vm._e()],1)})],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"d-block text-right text-h6 font-weight-black blue--text"},[_vm._v(" "+_vm._s(_vm.formatiereWaehrung(_vm.berechnePreis(_vm.artikelSync)))+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }