import BestellungFiliale from '@/entities/common/bestellung_filiale'
import store from '@/store'
import router from '@/router'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import Oeffnungszeiten, { Oeffnung } from '@/entities/common/oeffnungszeiten'
import RestPaths from '@/util/rest_paths'
import VerfuegbarkeitRequest from '@/entities/common/request/verfuegbarkeit_request'
import moment from 'moment'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import Verfuegbarkeit, {
  VerfuegbarkeitTag,
  VerfuegbarkeitUhrzeit,
} from '@/entities/common/verfuegbarkeit'
import Formatter from '../formatter'

export default class TerminReservierungLogic {
  filiale?: BestellungFiliale = null
  minDate?: string = null
  maxDate?: string = null
  geoeffneteTage: Oeffnung[] = []
  allowedDates: VerfuegbarkeitTag[] = []
  private _selectedDate: string = null
  derTag?: VerfuegbarkeitTag = null
  derSlot: VerfuegbarkeitUhrzeit = null
  selectedOeffnung?: Oeffnung = null
  _selectedPersonen?: number = 2
  private _pickerDate: string = null
  availableDates: VerfuegbarkeitTag[] = []
  pickerDisabled = false

  get slotFormatiert(): string {
    if (this.derSlot == null) {
      return null
    }
    return (
      Formatter.formatiereUhrzeit(this.derSlot.uhrzeitVon) +
      ' - ' +
      Formatter.formatiereUhrzeit(this.derSlot.uhrzeitBis)
    )
  }

  get selectedPersonen() {
    return this._selectedPersonen
  }

  set selectedPersonen(value: number) {
    this._selectedPersonen = value
  }

  get selectedDate(): string {
    return this._selectedDate
  }

  set selectedDate(value: string) {
    this._selectedDate = value

    var oeffnung = null

    this.allowedDates.forEach((element) => {
      if (element.datum === value) {
        this.derTag = element
      }
    })

    this.geoeffneteTage.forEach((toCompare) => {
      if (toCompare.datum === value) {
        oeffnung = toCompare
      }
    })
    this.selectedOeffnung = oeffnung
  }

  get pickerDate(): string {
    return this._pickerDate
  }

  set pickerDate(value: string) {
    this._pickerDate = value
    var request = new VerfuegbarkeitRequest()
    request.filialeId = this.filiale.filialeId
    request.monatJahr = moment(value, 'yyyy-MM').format('MM.yyyy')
    request.personen = this.selectedPersonen

    Poster.postUser<RemoteResult<Verfuegbarkeit>>(
      RestPaths.tischCheckFreeSlots,
      request
    ).then((response) => {
      this.availableDates = response.data.payload.tage
    })
  }

  init() {
    this.filiale = store.state.bestellungPending.filiale
    var filialeId = store.state.bestellungPending.filiale.filialeId
    var request = new IdRequest()
    request.id = filialeId

    Poster.postUser<RemoteResult<Oeffnungszeiten>>(
      RestPaths.oeffnungszeitOeffnungszeiten,
      request
    ).then((response) => {
      this.minDate = response.data.payload.minDate
      this.maxDate = response.data.payload.maxDate
      this.geoeffneteTage = response.data.payload.daten
    })
  }

  reloadDates(val) {
    var request = new VerfuegbarkeitRequest()
    request.filialeId = this.filiale.filialeId
    request.monatJahr = moment(val, 'yyyy-MM').format('MM.yyyy')
    request.personen = this.selectedPersonen

    this.pickerDisabled = true

    Poster.postUser<RemoteResult<Verfuegbarkeit>>(
      RestPaths.tischCheckFreeSlots,
      request
    ).then((response) => {
      this.availableDates = response.data.payload.tage
      var verfuegbareTage = []

      this.availableDates.forEach((element) => {
        if (element.slots.length > 0) {
          verfuegbareTage.push(element)
        }
      })

      this.allowedDates = verfuegbareTage
      this.pickerDisabled = false
    })
  }

  terminBestaetigen() {
    store.commit(StoreOps.UPDATE_NBD_RESERVIERUNG_TERMIN, {
      datum: this.derTag.datum,
      personen: this.selectedPersonen,
      tisch: this.derSlot.tisch,
      uhrzeitVon: this.derSlot.uhrzeitVon,
      uhrzeitBis: this.derSlot.uhrzeitBis,
    })

    router.push({ name: RouteNames.NEU.KUNDENDATEN })
  }

  slotWaehlen(slot) {
    this.derSlot = slot
  }
}