
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import TerminReservierungLogic from '@/logic/bestellung_neu/termin_reservierung_logic'
import { VerfuegbarkeitUhrzeit } from '@/entities/common/verfuegbarkeit'
import Formatter from '@/logic/formatter'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class TerminReservierung extends mixins(Calculator) {
  logic: TerminReservierungLogic = null

  dateMenuVisible: boolean = false
  timeDialogVisible: boolean = false
  personen: number[] = Array.from({ length: 10 }, (_, i) => i + 1)

  get bestaetigenEnabled() {
    return this.logic.derSlot != null
  }

  formatiereSlot(slot: VerfuegbarkeitUhrzeit) {
    return (
      Formatter.formatiereUhrzeit(slot.uhrzeitVon) +
      ' - ' +
      Formatter.formatiereUhrzeit(slot.uhrzeitBis)
    )
  }

  saveSelectedDate(selectedDate) {
    ;(this.$refs as any).dateMenu.save(selectedDate)
  }

  reloadDates(val) {
    this.logic.reloadDates(val)
  }

  allowedDates(val) {
    let result = false
    this.logic.allowedDates.forEach((toCompare) => {
      if (toCompare.datum === val) {
        result = true
      }
    })
    return result
  }

  terminBestaetigen() {
    this.logic.terminBestaetigen()
  }

  mounted() {
    this.logic = new TerminReservierungLogic()
    this.logic.init()
  }
}
