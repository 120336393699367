import Bestellung from '@/entities/common/bestellung'
import { AbholzeitType, LieferungStatus } from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import SocketMessageType from '@/util/socket_message_types'
import Formatter from '../formatter'
import StoreOps from '@/util/store_ops'

export default class DetailsLieferungLogic {
  bestellung: Bestellung = null
  navigator: Navigator = null

  init(navigator: Navigator) {
    this.bestellung = store.state.bestellung.bestellung
    this.navigator = navigator

    store.watch(
      (state) => state.bestellung,
      (newValue) => {
        this.bestellung = newValue.bestellung
      }
    )

    store.watch(
      (state) => state.incomingMessage,
      (newValue) => {
        if (newValue) {
          if (newValue.type == SocketMessageType.LIEFERUNG_STATUS_UPDATE) {
            var payload = JSON.parse(newValue.payload)
            if (payload.id == this.bestellung.id) {
              var status = payload.status
              this.bestellung.lieferung.lieferungStatus = status

              this.reloadBestellung()
            }
          }
        }
      }
    )
  }

  berechneGesamtpreis(): number {
    var artikellistepreis = Formatter.berechnePreisListe(
      this.bestellung.artikelliste
    )
    var lieferkosten = this.bestellung.lieferung.lieferkosten
    if (!lieferkosten) lieferkosten = 0
    return artikellistepreis + lieferkosten
  }

  reloadBestellung() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        store.commit(StoreOps.UPDATE_BESTELLUNG, response.data.payload)
      }
    })
  }

  computeGewuenschteLieferzeit(): string {
    if (this.bestellung.lieferung.zeitType == AbholzeitType.BALDEST) {
      return 'schnellst möglich'
    } else if (this.bestellung.lieferung.zeitType == AbholzeitType.UHRZEIT) {
      return Formatter.formatiereDatumUhrzeit(
        this.bestellung.lieferung.lieferzeitpunkt
      )
    }
  }

  bestimmeBild(bestellung: Bestellung) {
    if (bestellung == null) return null
    var bildId = bestellung.filiale.bildId
    if (bildId == null) return null
    return RestPaths.urlBase + 'image/image2/' + bildId
  }

  formatiereStatus() {
    if (this.bestellung == null) return null
    switch (this.bestellung.lieferung.lieferungStatus) {
      case LieferungStatus.ABGESENDET:
        return 'Bestellung noch nicht angenommen'
      case LieferungStatus.ANGENOMMEN:
        return 'Bestellung angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'Bestellung abgelehnt'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'Bestellung in Zubereitung'
      case LieferungStatus.ZUBEREITET:
        return 'Bestellung zubereitet'
      case LieferungStatus.IN_LIEFERUNG:
        return 'Bestellung in Lieferung'
      case LieferungStatus.GELIEFERT:
        return 'Bestellung ausgeliefert'
    }
  }
}
