
import RestPaths from '@/util/rest_paths'
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelAktuell, {
  ProduktExtra,
  ProduktAuswahl,
  ProduktSubprodukt,
  ProduktAuswahlOption,
} from '@/entities/common/artikel_aktuell'
import { PreislisteProdukt } from '@/entities/common/pliste_produkt_zeit'
import BstlArtikel from '@/entities/common/bstl_artikel'
import ExtrasContainer from '@/components/custom/ExtrasContainer.vue'

@Component({ components: { ExtrasContainer } })
export default class ArtikelEdit extends mixins(Calculator) {
  @PropSync('artikel') artikelSync: PreislisteProdukt

  @Prop() primaryColor: number
  @Prop() secondaryColor: number
  @Prop() darkMode: boolean

  showDialog: boolean = true
  artikelAktuell?: ArtikelAktuell = null
  varianteDialogOpen: boolean = false
  aMenuOpenArray: boolean[] = []
  showExtraDetails: boolean = false
  showInfosDialog: boolean = false
  currentExtra: ProduktExtra = null

  formValidated: boolean = null
  formError: boolean = false

  optionen() {}

  berechneHauptpreis(): number {
    if (this.artikelAktuell.produkt.base.hasArten) {
      return this.artikelAktuell.gewaehleVariante.preis
    } else {
      return this.artikelAktuell.produkt.base.preis
    }
  }

  isSelected(auswahl: ProduktAuswahl) {
    return auswahl.gewaehlteOption == null
  }

  zeigeExtra(extra: ProduktExtra) {
    this.currentExtra = extra
    this.showExtraDetails = true
  }

  zeigeInfos() {
    this.showInfosDialog = true
  }

  berechneSubproduktOption(auswahl: ProduktAuswahl): string {
    if (auswahl.gewaehlteOption != null) {
      return (
        auswahl.gewaehlteOption.base.bezeichnung +
        ' (' +
        this.formatiereWaehrung(auswahl.gewaehlteOption.base.preis) +
        ')'
      )
    }
  }

  berechneOption(auswahl: ProduktAuswahl): string {
    if (auswahl == null) return null
    if (this.artikelAktuell.gewaehleVariante == null) return null
    if (auswahl.gewaehlteOption != null) {
      var varianteId = this.artikelAktuell.gewaehleVariante.id
      var result: number = 0
      auswahl.gewaehlteOption.base.arten.forEach((v) => {
        if (v.id === varianteId) {
          result = v.preis
          return
        }
      })
      return (
        auswahl.gewaehlteOption.base.bezeichnung +
        ' (' +
        this.formatiereWaehrung(
          this.berechneOptionPreis(auswahl.gewaehlteOption)
        ) +
        ')'
      )
    }
  }

  berechneOptionPreis(option: ProduktAuswahlOption): number {
    if (option.base.hasArten) {
      if (this.artikelAktuell.gewaehleVariante == null) return null
      var varianteId = this.artikelAktuell.gewaehleVariante.pduktArtId
      var result: number = 0
      option.base.arten.forEach((v) => {
        if (v.pduktArtId === varianteId) {
          result = v.preis
          return
        }
      })
      return result
    }
    return option.base.preis
  }

  berechneSubOption(subprodukt: ProduktSubprodukt): string {
    if (subprodukt == null) return null
    if (subprodukt.gewaehlteOption != null) {
      var base = subprodukt.gewaehlteOption.produkt.base
      return base.bezeichnung + ' (' + this.formatiereWaehrung(base.preis) + ')'
    }
  }

  berechneVariante(): string {
    if (!this.artikelAktuell.gewaehleVariante) return null
    return (
      this.artikelAktuell.gewaehleVariante.bezeichnung +
      ' (' +
      this.formatiereWaehrung(this.artikelAktuell.gewaehleVariante.preis) +
      ')'
    )
  }

  computeBild() {
    if (this.artikelAktuell.produkt.base.bildId != null) {
      return RestPaths.imageBasePath + this.artikelSync.bildId + '_produkt.jpg'
    }
  }

  inDenWarenkorb() {
    this.formError = false
    this.artikelAktuell.produkt.auswahlen.forEach((auswahl) => {
      if (auswahl.gewaehlteOption == null) {
        this.formError = true
        return
      }
    })
    this.artikelAktuell.produkt.subprodukte.forEach((subprodukt) => {
      if (subprodukt.gewaehlteOption == null) {
        this.formError = true
        return
      }
    })
    this.formValidated = true
    if (!this.formError) {
      this.addedToWarenkorb(this.artikelAktuell.toArtikel())
    }
  }

  @Emit('added-to-warenkorb')
  addedToWarenkorb(artikel: BstlArtikel) {
    return artikel
  }

  mounted() {
    this.artikelAktuell = ArtikelAktuell.fromProdukt(this.artikelSync)
    if (this.artikelSync == null) {
      this.aMenuOpenArray = []
    } else {
      this.artikelAktuell = ArtikelAktuell.fromProdukt(this.artikelSync)
      var auswahlen = this.artikelSync.auswahlen.length
      for (var i = 0; i++; i < auswahlen) {
        this.aMenuOpenArray[i] = false
      }
    }
  }

  getArtikelProvider(parent) {
    if (parent == null) {
      return null
    }
    if (parent.pushArtikel) {
      return parent
    }
    return this.getArtikelProvider(parent.$parent)
  }

  auswahlAnzeigen(auswahl) {}

  subproduktAnzeigen(subprodukt) {}

  increment() {
    this.artikelAktuell.anzahl++
  }

  decrement() {
    if (this.artikelAktuell.anzahl > 1) this.artikelAktuell.anzahl--
  }

  get gesamtpreis() {
    return this.artikelAktuell.calcPreis()
  }
}
