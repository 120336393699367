
import vuexStore from '@/store'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import Formatter from '@/logic/formatter'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import FilialeHeader from '../custom/FilialeHeader.vue'
import BPTrinkgeldRequest from '@/entities/common/pending/bp_trinkgeld_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component({ components: { FilialeHeader } })
export default class Trinkgeld extends mixins(Calculator) {
  logic: TrinkgeldLogic = null

  mounted() {
    this.logic = new TrinkgeldLogic()
    this.logic.init()
  }

  weiter() {
    this.logic.weiter()
  }
}

class TrinkgeldLogic {
  prozent5: number
  prozent10: number
  andererBetrag: number

  type: string = 'PERCENT_10'
  filiale: BestellungFiliale

  init() {
    var warenkorb = vuexStore.state.bestellungPending.artikelliste
    var preis = Formatter.berechnePreisListe(warenkorb)

    this.prozent5 = preis * 0.05
    this.prozent10 = preis * 0.1

    this.filiale = vuexStore.state.bestellungPending.filiale
  }

  weiter() {
    var request = new BPTrinkgeldRequest()
    request.bestellungId = vuexStore.state.bestellungPending.id
    request.type = this.type
    request.trinkgeld = this.andererBetrag

    Poster.postUser<RemoteResult<any>>(
      RestPaths.bestellungpendingTrinkgeldFestlegen,
      request
    ).then((response) => {
      if (response.data.success) {
        var payload = {
          type: this.type,
          value: this.andererBetrag,
        }

        vuexStore.commit(StoreOps.TRINKGELD_FESTLEGEN, payload)
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.BESTAETIGEN })
      }
    })
  }
}
