
import router from '@/router'
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'
import HomepageLogic from '@/logic/homepage_logic'

@Component
export default class Homepage extends Vue {
  logic?: HomepageLogic = new HomepageLogic()

  mounted() {
    this.logic = new HomepageLogic()
  }

  deleteStandort() {
    this.logic.deleteStandort()
  }

  editStandort() {
    this.logic.editStandort()
  }

  standortErmitteln() {
    this.logic.positionErmitteln(navigator)
  }

  ersteOptionWaehlen() {
    router.push({ name: RouteNames.BETRIEB_ANFRAGEN, params: { option: '1' } })
  }

  abgAnzeigen() {
    router.push({ name: RouteNames.AGB })
  }

  impressumAnzeigen() {
    router.push({ name: RouteNames.IMPRESSUM })
  }

  datenschutzAnzeigen() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }

  bestellungStarten() {
    router.push({ name: RouteNames.NEU.FILIALE })
  }

  meineBestellungen() {
    router.push({ name: RouteNames.BESTELLUNGEN })
  }
}
