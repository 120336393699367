import Inhaltsstoff from '@/entities/common/inhaltsstoff'
import Basiskategorie from '@/entities/member/basiskategorie'
import Pdukt from '@/entities/member/pdukt'
import PduktInsertRequest from '@/entities/member/request/pdukt_insert_request'
import { v4 as uuidv4 } from 'uuid'
import {
  Extra as RequestExtra,
  Auswahl as RequestAuswahl,
  Option as RequestOption,
  Subprodukt as RequestSubprodukt,
  Art as RequestArt,
} from '@/entities/member/request/pdukt_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RemoteResult from '@/entities/common/remote_result'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import PduktRequest from '@/entities/member/request/pdukt_request'
import PduktList from '@/entities/member/lists/pdukt_list'
import IdRequest from '@/entities/common/request/id_request'
import BasiskategorieList from '@/entities/member/lists/basiskategorie_list'
import InhaltsstoffList from '@/entities/member/lists/inhaltsstoff_list'
import router from '@/router'
import InhaltsstoffInsertRequest from '@/entities/member/request/inhaltsstoff_insert_request'
import vuexStore from '@/store'
import MemberBaseLogic from '../member_base_logic'
import Swapper from '@/util/swapper'
import PduktArtList from '@/entities/member/lists/pdukt_art_list'
import PduktArt from '@/entities/member/pdukt_art'
import PduktArtInsertRequest from '@/entities/member/request/pdukt_art_insert_request'
import TischDetails from '@/components/member/TischDetails.vue'

export default class ProduktNeuMemcommonLogic extends MemberBaseLogic {
  insertComplete: boolean = false
  insertError: boolean = false
  insertWorking: boolean = false
  insertFormDisabled: boolean = false

  bezeichnung?: string = ''
  beschreibung?: string = ''
  inhaltsstoffe?: Inhaltsstoff[] = []
  artikelNr?: string = ''
  vegetarisch: boolean = false
  vegan: boolean = false
  hot1: boolean = false
  hot2: boolean = false
  hot3: boolean = false
  standardpreis: number = 0.0
  extras?: Pdukt[] = []
  selectedInhaltsstoffe?: Inhaltsstoff[] = []
  vorhandeneInhaltsstoffe?: Inhaltsstoff[] = []
  verfInhaltsstoffe?: Inhaltsstoff[] = []
  selectedExtras?: Pdukt[] = []
  vorhandeneExtras?: Pdukt[] = []
  image?: any = null
  imageUrl?: string = null
  tempImageId?: string = null
  basiskategorien?: Basiskategorie[] = []
  basiskategorieId?: string = null
  extrasVisible: boolean = false
  auswahlVisible: boolean = false
  editAuswahlVisible: boolean = false
  editVarianteVisible: boolean = false
  inhaltsstoffeVisible: boolean = false
  subprodukteVisible: boolean = false
  auswahlen?: Auswahl[] = []
  varianten: Variante[] = []
  subprodukte?: Subprodukt[] = []
  verfuegbareSubprodukte?: PduktMitVarianten[] = []
  verfuegbareExtras?: Pdukt[] = []
  menu: boolean = false
  betriebId: string = null
  neuesExtra: ExtraNeu = null
  neuerInhaltsstoff: InhaltsstoffNeu = null
  standardvariante: Variante
  standardart: PduktArt = null
  produktartNeu: PduktArtNeu = null

  neuesExtraDialogVisible: boolean = false
  neuerInhaltsstoffDialogVisible: boolean = false
  neueVarianteDialogVisible: boolean = false
  standardvarianteDialogVisible: boolean = false
  standardartDialogVisible: boolean = false
  artenVerwaltenDialogVisible: boolean = false
  neueProduktartDialogVisible: boolean = false

  neueAuswahl?: Auswahl = new Auswahl()
  editAuswahl?: Auswahl = null
  neuesSubprodukt?: Subprodukt = new Subprodukt()
  editVariante: Variante = null
  neueVariante: Variante = new Variante()

  editWorking: boolean = false
  editError: boolean = false

  produktarten: PduktArt[] = []
  verfuegbareProduktarten: PduktArtPreis[] = []
  vorhandeneProduktarten: PduktArtPreis[] = []

  bezeichnungRules = [
    (v: string) => {
      if (!v) return 'Bitte Bezeichnung angeben'
      else return true
    },
  ]

  mainFormValid: boolean = true

  artenVerwalten() {
    this.artenVerwaltenDialogVisible = true
  }

  varianteErstellen() {
    this.neueVariante = new Variante()
    this.neueVarianteDialogVisible = true
  }

  neueVarianteAbbrechen() {
    this.neueVarianteDialogVisible = false
    this.neueVariante = new Variante()
  }

  neueVarianteEinfuegen() {
    this.varianten.push(this.neueVariante)
    this.neueVarianteDialogVisible = false
    this.neueVariante = new Variante()
  }

  varianteBearbeiten(index: number) {
    this.editVariante = this.varianten[index]
    this.editVarianteVisible = true
  }

  varianteEntfernen(index: number) {
    this.varianten.splice(index, 1)
  }

  moveVarianteUp(index: number) {
    Swapper.instance.pushUp(this.varianten, index)
  }

  moveVarianteDown(index: number) {
    Swapper.instance.pushDown(this.varianten, index)
  }

  subproduktLoeschen(index: number) {
    this.subprodukte.splice(index, 1)
  }

  inhaltsstoffEntfernen(index: number) {
    this.vorhandeneInhaltsstoffe.splice(index, 1)
  }

  extraEntfernen(index: number) {
    this.vorhandeneExtras.splice(index, 1)
  }

  auswahlEntfernen(index: number) {
    this.auswahlen.splice(index, 1)
  }

  auswahlBearbeiten(index: number) {
    this.editAuswahl = this.auswahlen[index]
    this.editAuswahlVisible = true
  }

  subproduktHinzufuegen() {
    this.neuesSubprodukt = new Subprodukt()
    this.subprodukteVisible = true
  }

  neuesSubproduktEinfuegen() {
    this.subprodukte.push(this.neuesSubprodukt)
    this.neuesSubprodukt = new Subprodukt()
    this.subprodukteVisible = false
  }

  inhaltsstoffeVerwalten() {
    this.inhaltsstoffeVisible = true
  }

  inhaltsstoffeAbbrechen() {
    this.inhaltsstoffeVisible = false
  }

  auswahlErstellen() {
    this.auswahlVisible = true
  }

  editAuswahlAbbrechen() {
    this.editAuswahlVisible = false
    this.editAuswahl = null
  }

  editAuswahlSpeichern() {
    this.editAuswahlVisible = false
    this.editAuswahl = null
  }

  extrasVerwalten() {
    this.extrasVisible = true
  }

  inhaltsstoffeSpeichern() {
    this.inhaltsstoffeVisible = false
    //this.vorhandeneInhaltsstoffe = this.selectedInhaltsstoffe
  }

  neuerInhaltsstoffErstellen() {
    this.neuerInhaltsstoff = new InhaltsstoffNeu()
    this.neuerInhaltsstoffDialogVisible = true
  }

  neuesExtraErstellen() {
    this.neuesExtra = new ExtraNeu()
    this.neuesExtraDialogVisible = true
  }

  neuesExtraEinfuegen() {
    this.editWorking = true
    this.editError = false

    var request = new PduktInsertRequest()
    request.betriebId = vuexStore.state.user.betriebId
    request.extraZusatz = true
    request.standardpreis = this.neuesExtra.standardpreis
    request.bezeichnung = this.neuesExtra.bezeichnung
    request.beschreibung = this.neuesExtra.beschreibung
    request.artikelNr = this.neuesExtra.artikelNr
    //request.vegan = e.produkt.vegan
    //request.vegetarisch = e.produkt.vegetarisch

    Poster.postMember<RemoteResult<Pdukt>>(
      RestPaths.artikelPduktEinfuegen,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.extras.push(response.data.payload)
          this.verfuegbareExtras.push(response.data.payload)
          this.neuesExtraDialogVisible = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  neuesExtraAbbrechen() {
    this.neuesExtra = null
    this.neuesExtraDialogVisible = false
  }

  neuerInhaltsstoffEinfuegen() {
    this.editWorking = true
    this.editError = false

    var request = new InhaltsstoffInsertRequest()
    request.bezeichnung = this.neuerInhaltsstoff.bezeichnung
    request.betriebId = vuexStore.state.user.betriebId
    //request.id = uuidv4()

    Poster.postMember<RemoteResult<Inhaltsstoff>>(
      RestPaths.inhaltsstoffeInsert,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.inhaltsstoffe.push(response.data.payload)
          this.verfInhaltsstoffe.push(response.data.payload)
          this.neuerInhaltsstoffDialogVisible = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editError = true
      })
  }

  neuerInhaltsstoffAbbrechen() {
    this.neuerInhaltsstoff = new InhaltsstoffNeu()
    this.neuerInhaltsstoffDialogVisible = false
  }

  neueProduktartAbbrechen() {
    this.neueProduktartDialogVisible = false
    this.produktartNeu = null
  }

  neueProduktartSpeichern() {
    var request = new PduktArtInsertRequest()
    request.bezeichnung = this.produktartNeu.bezeichnung
    request.betriebId = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<PduktArt>>(
      RestPaths.produktInsertArt,
      request
    ).then((response) => {
      var pp = new PduktArtPreis()
      pp.pduktArt = response.data.payload
      this.verfuegbareProduktarten.push(pp)
      this.neueProduktartDialogVisible = false
      this.produktartNeu = null
    })
  }

  extrasHinzufuegen() {
    this.extrasVisible = false
    //this.vorhandeneExtras = this.selectedExtras
  }

  extrasAbbrechen() {
    this.extrasVisible = false
  }

  auswahlEinfuegen() {
    this.auswahlen.push(this.neueAuswahl)
    this.neueAuswahl = new Auswahl()
    this.auswahlVisible = false
  }

  auswahlAbbrechen() {
    this.auswahlVisible = false
  }

  produktEinfuegen() {
    this.insertFormDisabled = true
    this.insertError = false
    this.insertWorking = true

    var request = new PduktInsertRequest()
    request.id = uuidv4()
    request.betriebId = this.betriebId
    request.basiskategorieId = this.basiskategorieId
    request.bezeichnung = this.bezeichnung
    request.beschreibung = this.beschreibung
    request.artikelNr = this.artikelNr
    request.bildId = this.tempImageId
    request.vegetarisch = this.vegetarisch
    request.vegan = this.vegan
    request.hot1 = this.hot1
    request.hot2 = this.hot2
    request.hot3 = this.hot3
    request.extraZusatz = false
    request.standardpreis = this.standardpreis
    request.menu = this.menu

    this.vorhandeneExtras.forEach((extra, index) => {
      var requestExtra = new RequestExtra()
      requestExtra.id = uuidv4()
      requestExtra.index = index
      requestExtra.produktId = extra.id
      request.extras.push(requestExtra)
    })

    this.vorhandeneInhaltsstoffe.forEach((inhaltsstoff) => {
      request.inhaltsstoffe.push(inhaltsstoff.id)
    })

    this.auswahlen.forEach((auswahl, index) => {
      var requestAuswahl = new RequestAuswahl()
      requestAuswahl.id = uuidv4()
      requestAuswahl.index = index
      requestAuswahl.bezeichnung = auswahl.bezeichnung
      requestAuswahl.beschreibung = auswahl.beschreibung
      requestAuswahl.errorText = auswahl.errorText
      auswahl.extras.forEach((extra) => {
        const requestOption = new RequestOption()
        requestOption.id = uuidv4()
        requestOption.produktId = extra.id
        requestAuswahl.optionen.push(requestOption)
      })
      requestAuswahl.defaultOptionId = auswahl.defaultOptionId

      request.auswahlen.push(requestAuswahl)
    })

    this.subprodukte.forEach((subProdukt, index) => {
      var requestSubprodukt = new RequestSubprodukt()
      requestSubprodukt.id = uuidv4()
      requestSubprodukt.index = index
      requestSubprodukt.bezeichnung = subProdukt.bezeichnung
      requestSubprodukt.beschreibung = subProdukt.beschreibung
      requestSubprodukt.errorText = subProdukt.errorText
      subProdukt.produkte.forEach((option, index) => {
        const requestOption = new RequestOption()
        requestOption.id = uuidv4()
        requestOption.index = index
        requestOption.produktId = option.produkt.id
        option.selectedVarianten.forEach((v) => {
          var requestOption = new RequestOption()
          requestOption.index = index
          requestOption.produktId = option.produkt.id
          requestOption.varianteId = v.id
          requestSubprodukt.optionen.push(requestOption)
        })
      })
      request.subprodukte.push(requestSubprodukt)
    })

    if (this.vorhandeneProduktarten.length > 0) {
      this.vorhandeneProduktarten.forEach((pa, index) => {
        var requestArt = new RequestArt()
        requestArt.id = pa.pduktArt.id
        requestArt.index = index
        requestArt.standardpreis = pa.preis
        request.arten.push(requestArt)
      })

      request.standardart = this.standardart.id
    }

    Poster.postMember<RemoteResult<DateTimeCapsule>>(
      RestPaths.artikelPduktEinfuegen,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertComplete = true
        } else {
          this.insertComplete = false
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertComplete = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }

  uploadImage() {
    var data = new FormData()
    data.append('file', this.image)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => (this.tempImageId = data.payload))
  }

  previewImage() {
    this.imageUrl = URL.createObjectURL(this.image)
    this.uploadImage()
  }

  goBack() {
    router.back()
  }

  init() {
    var request = new PduktRequest()
    request.betriebId = this.betriebId
    request.extraZusatz = true

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      this.extras = response.data.payload.list
      this.verfuegbareExtras = this.extras.map((x) => x)
    })

    request = new PduktRequest()
    request.betriebId = this.betriebId
    request.extraZusatz = false

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      response.data.payload.list.forEach((p) => {
        if (!p.menu) {
          var pmitV = new PduktMitVarianten()
          pmitV.produkt = p
          this.verfuegbareSubprodukte.push(pmitV)
        }
      })
    })

    var basiskategorieRequest = new IdRequest()
    basiskategorieRequest.id = this.betriebId

    Poster.postMember<RemoteResult<BasiskategorieList>>(
      RestPaths.artikelBasiskategorieList,
      basiskategorieRequest
    ).then((response) => {
      this.basiskategorien = response.data.payload.list
    })

    var inhaltsstoffeRequest = new IdRequest()
    inhaltsstoffeRequest.id = this.betriebId

    Poster.postMember<RemoteResult<InhaltsstoffList>>(
      RestPaths.inhaltsstoffeListe,
      inhaltsstoffeRequest
    ).then((response) => {
      this.inhaltsstoffe = response.data.payload.list
      this.verfInhaltsstoffe = this.inhaltsstoffe.map((x) => x)
    })

    var artenRequest = new IdRequest()
    artenRequest.id = this.betriebId
    Poster.postMember<RemoteResult<PduktArtList>>(
      RestPaths.produktArtList,
      artenRequest
    ).then((response) => {
      this.produktarten = response.data.payload.list
      this.produktarten.forEach((p) => {
        var pp = new PduktArtPreis()
        pp.pduktArt = p
        this.verfuegbareProduktarten.push(pp)
      })
    })
  }

  swapElements = (array, index1, index2) => {
    array[index1] = array.splice(index2, 1, array[index1])[0]
  }

  moveExtraUp(index: number) {
    Swapper.instance.pushUp(this.vorhandeneExtras, index)
  }

  moveExtraDown(index: number) {
    Swapper.instance.pushDown(this.vorhandeneExtras, index)
  }

  moveAuswahlUp(index: number) {
    Swapper.instance.pushUp(this.auswahlen, index)
  }

  moveAuswahlDown(index: number) {
    Swapper.instance.pushDown(this.auswahlen, index)
  }

  moveInhaltsstoffUp(index: number) {
    Swapper.instance.pushUp(this.vorhandeneInhaltsstoffe, index)
  }

  moveInhaltsstoffDown(index: number) {
    Swapper.instance.pushDown(this.vorhandeneInhaltsstoffe, index)
  }

  moveSubproduktUp(index: number) {
    Swapper.instance.pushUp(this.subprodukte, index)
  }

  moveSubproduktDown(index: number) {
    Swapper.instance.pushDown(this.subprodukte, index)
  }

  pushExtra(extra) {
    this.vorhandeneExtras.push(extra)
    this.verfuegbareExtras.splice(this.verfuegbareExtras.indexOf(extra), 1)
  }

  removeExtra(extra) {
    this.vorhandeneExtras.splice(this.vorhandeneExtras.indexOf(extra), 1)
    this.verfuegbareExtras.push(extra)
  }

  pushInhaltsstoff(inhaltsstoff) {
    this.vorhandeneInhaltsstoffe.push(inhaltsstoff)
    this.verfInhaltsstoffe.splice(
      this.verfInhaltsstoffe.indexOf(inhaltsstoff),
      1
    )
  }

  removeInhaltsstoff(inhaltsstoff) {
    this.vorhandeneInhaltsstoffe.splice(
      this.vorhandeneInhaltsstoffe.indexOf(inhaltsstoff),
      1
    )
    this.verfInhaltsstoffe.push(inhaltsstoff)
  }

  removeProduktart(pa: PduktArtPreis) {
    this.vorhandeneProduktarten.splice(
      this.vorhandeneProduktarten.indexOf(pa),
      1
    )
    this.verfuegbareProduktarten.push(pa)
  }

  neueProduktartErstellen() {
    this.produktartNeu = new PduktArtNeu()
    this.neueProduktartDialogVisible = true
  }

  pushProduktart(pa: PduktArtPreis) {
    this.vorhandeneProduktarten.push(pa)
    this.verfuegbareProduktarten.splice(
      this.verfuegbareProduktarten.indexOf(pa),
      1
    )
  }

  produktartenAbbrechen() {
    this.artenVerwaltenDialogVisible = false
  }

  produktartenSpeichern() {
    this.artenVerwaltenDialogVisible = false
  }

  produktartEntfernen(index: number) {
    this.removeProduktart(this.vorhandeneProduktarten[index])
  }

  moveProduktartUp(index: number) {
    Swapper.instance.pushUp(this.vorhandeneProduktarten, index)
  }

  moveProduktartDown(index: number) {
    Swapper.instance.pushDown(this.vorhandeneProduktarten, index)
  }
}

class Auswahl {
  bezeichnung?: string
  beschreibung: string
  errorText: string
  extras: Pdukt[] = []
  defaultOptionId: string = null
}

class Subprodukt {
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  produkte: PduktMitVarianten[] = []
}

class ExtraNeu {
  bezeichnung: string
  beschreibung: string
  artikelNr: string
  standardpreis: number = 0.0
}

class PduktArtNeu {
  bezeichnung: string
}

class InhaltsstoffNeu {
  bezeichnung: string
  beschreibung: string
}

class Variante {
  bezeichnung: string
  id: string
}

class PduktArtPreis {
  pduktArt: PduktArt
  preis: number
}

class PduktMitVarianten {
  produkt: Pdukt
  selectedVarianten: Variante[] = []
}

export { PduktArtPreis }
