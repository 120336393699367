
import Vue from 'vue'
import Component from 'vue-class-component'
import names from './router'
import CookieLaw from 'vue-cookie-law'
import VueCookie from 'vue-cookie'
import Poster from './util/poster'
import RestPaths from './util/rest_paths'
import RemoteResult from './entities/common/remote_result'

@Component({ components: { CookieLaw } })
export default class App extends Vue {
  drawer = false
  memberLoggedIn = false

  websocket = null
  links = names

  mounted() {
    // if(!cookie) {
    //   Poster.postUser<RemoteResult<string>>(RestPaths.requestCookie, null)
    //     .then((response)=> {
    //       response.headers['set-cookie'].find(x => {
    //         if(x=='session')
    //       })
    //     })
    // }
    //VueCookie.set('test', 'Hello world!', 1);
  }
}
