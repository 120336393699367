
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Calculator from '../mixins/Calculator.vue'
import Expander from '../custom/Expander.vue'
import PreislisteProduktContainer from './PreislisteProduktContainer.vue'
import PreislisteKategorieContainer from './PreislisteKategorieContainer.vue'
import PlisteSimple from '@/entities/member/pliste_simple'
import AltePreisListe from '@/entities/member/alte_preis_liste'
import NewPreislisteFilialeNeuMemcommonLogic from '@/logic/memcommon/new_preisliste_filiale_neu_memcommon_logic'
import KategorieEdit from '../custom/KategorieEdit.vue'
import { Kategorie, PreislisteProdukt, Produkt } from '../custom/preisliste'

@Component({
  components: {
    ToolbarButton,
    Expander,
    PreislisteProduktContainer,
    PreislisteKategorieContainer,
    KategorieEdit,
  },
})
export default class NewPreislisteFilialeNeuMemCommon extends mixins(
  Calculator
) {
  $refs: {
    baseForm: HTMLFormElement
    datumVonDialog: HTMLFormElement
    datumBisDialog: HTMLFormElement
  }

  @Prop() logic: NewPreislisteFilialeNeuMemcommonLogic

  moveKategorieUp(index: number) {
    this.logic.moveKategorieUp(index)
  }

  moveKategorieDown(index: number) {
    this.logic.moveKategorieDown(index)
  }

  ausListeErzeugen(alteListe: PlisteSimple) {
    this.logic.ausListeErzeugen(alteListe)
  }

  findProdukt(id: string) {
    return this.logic.findProdukt(id)
  }

  findProduktExtra(id: string) {
    return this.logic.findProduktExtra(id)
  }

  assignAlteListe(p: AltePreisListe) {
    this.logic.assignAlteListe(p)
  }

  altePreislisteAuswaehlen() {
    this.logic.altePreislisteAuswaehlen()
  }

  //Preisliste Kategorie löschen
  kategorieEntfernen(kategorie: Kategorie) {
    this.logic.kategorieEntfernen(kategorie)
  }

  kategorieBearbeiten(kategorie: Kategorie) {
    this.logic.kategorieBearbeiten(kategorie)
  }

  kategorieBearbeitenAbbrechen() {
    this.logic.kategorieBearbeitenAbbrechen()
  }

  kategorieBearbeitenSpeichern() {
    this.logic.kategorieBearbeitenSpeichern()
  }

  //Kategorie auswählen, zu der Produkt hinzugefügt werden
  //sollen, und den dialog anzeigen
  kategorieProdukteWaehlen(kategorie: Kategorie) {
    this.logic.kategorieProdukteWaehlen(kategorie)
  }

  // createProdukt(produkt: Produkt): PreislisteProdukt {
  //   return this.logic.createProdukt(produkt)
  // }

  //gewählte Produkte zu PreislisteProdukte umwandeln
  //und der aktuellen Kategorie hinzufügen
  produkteHinzufuegen() {
    this.logic.produkteHinzufuegen()
  }

  produktEntfernen(args: any) {
    this.logic.produktEntfernen(args)
  }

  produkteHinzufuegenAbbrechen() {
    this.logic.produkteHinzufuegenAbbrechen()
  }

  // findExtra(extra: Produkt): PreislisteExtra {
  //   return this.logic.findExtra(extra)
  // }

  preislisteEinfuegen() {
    var valid = this.$refs.baseForm.validate()
    if (!valid) return

    this.logic.preislisteEinfuegen()
  }

  navBack() {
    this.logic.navBack()
  }

  neueKategorieErstellen() {
    this.logic.neueKategorieErstellen()
  }

  neueKategorieAbbrechen() {
    this.logic.neueKategorieAbbrechen()
  }

  neueKategorieEinfuegen() {
    this.logic.neueKategorieEinfuegen()
  }
}
