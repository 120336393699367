
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import NichtGueltig from '../custom/NichtGueltig.vue'
import KundendatenLieferungLogic from '@/logic/bestellung_neu/kundendaten_lieferung_logic'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { NichtGueltig, FilialeHeader } })
export default class KundendatenLieferung extends mixins(Calculator) {
  $refs: {
    form: HTMLFormElement
  }

  logic?: KundendatenLieferungLogic = new KundendatenLieferungLogic()

  weiter() {
    var valid = this.$refs.form.validate()
    if (!valid) return
    this.logic.weiter()
  }

  mounted() {
    this.logic = new KundendatenLieferungLogic()
    this.logic.init()
  }
}
