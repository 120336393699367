
import AbholungStartenLogic from '@/logic/bestellung_neu/abholung_starten_logic'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Bestellung from '@/entities/common/bestellung'
import router from '@/router'
import RouteNames from '@/util/route_names'
import Calculator from '../mixins/Calculator.vue'
import { Watch } from 'vue-property-decorator'

@Component({ components: { FilialeHeader } })
export default class AbholungStarten extends mixins(Calculator) {
  logic?: AbholungStartenLogic = null

  @Watch('$store.state.neueBestellungData.filiale', {
    deep: true,
    immediate: true,
  })
  watchFiliale(newValue) {
    this.logic.filiale = newValue
  }

  bestellungStarten() {
    this.logic.bestellungStarten()
  }

  mounted() {
    this.logic = new AbholungStartenLogic()
    this.logic.init(this.$route.params.urlpath)
  }

  bestellungAnzeigen(bestellung: Bestellung) {
    router.push({
      name: RouteNames.BESTELLUNG_PAGE.DETAILS,
      params: { bestellungId: bestellung.id },
    })
  }
}
