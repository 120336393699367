
import BstlArtikel from '@/entities/common/bstl_artikel'
import ShoppingLieferungLogic from '@/logic/bestellung_neu/shopping_lieferung_logic'
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import ShoppingLieferung from './ShoppingLieferung.vue'
import ArtikelEdit from '../custom/ArtikelEdit.vue'

@Component({ components: { ArtikelEdit } })
export default class ShoppingLieferungArtikel extends Vue {
  logic?: ShoppingLieferungLogic = null

  added(value: BstlArtikel) {
    this.logic.addArtikel(value)
  }

  mounted() {
    this.logic = (this.$parent as ShoppingLieferung).logic
    this.logic.createdListener = () => {
      this.logic.reloadArtikel(this.$route.params.id)
    }
  }
}
