
import Component, { mixins } from 'vue-class-component'
import Calculator from './mixins/Calculator.vue'
import CookieLaw from 'vue-cookie-law'
import Bestellung from '@/entities/common/bestellung'
import RestPaths from '@/util/rest_paths'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import router from '@/router'
import RouteNames from '@/util/route_names'
import vuexStore from '@/store'
import StoreOps from '@/util/store_ops'

@Component({ components: { CookieLaw } })
export default class BestellungPage extends mixins(Calculator) {
  drawer: boolean = false

  bestellung: Bestellung = null
  websocket: WebSocket = null

  computeBild() {
    if (this.bestellung.filiale.bildId == null) return '/assets/no_image.png'
    return (
      RestPaths.imageBasePath +
      this.bestellung.filiale.logoKleinId +
      '_logo_klein.jpg'
    )
  }

  goToFiliale() {
    router.push({
      name: RouteNames.FILIALE_PAGE,
      params: { urlpath: this.bestellung.filiale.urlPath },
    })
  }

  mounted() {
    this.websocket = new WebSocket(RestPaths.urlBaseWs + 'user')
    this.websocket.onopen = this.websocketOpened
    this.websocket.onmessage = this.websocketMessage
    this.websocket.onclose = this.websocketClosed
    this.websocket.onerror = this.websocketError

    var request = new IdRequest()
    request.id = this.$route.params.bestellungId

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeDetails,
      request
    ).then((response) => {
      this.bestellung = response.data.payload
    })
  }

  websocketOpened() {
    this.websocket.send(vuexStore.state.user.identifier)
    console.log('websocket opened')
  }

  websocketMessage(message) {
    var parsedObject = JSON.parse(message.data)
    vuexStore.commit(StoreOps.POST_INCOMING_MESSAGE, parsedObject)
  }

  websocketClosed() {
    this.reopenService()
  }

  websocketError() {
    this.reopenService()
  }

  reopenService() {
    setTimeout(() => {
      this.websocket = new WebSocket(RestPaths.urlBaseWs + 'user')
      this.websocket.onopen = this.websocketOpened
      this.websocket.onmessage = this.websocketMessage
      this.websocket.onclose = this.websocketClosed
      this.websocket.onerror = this.websocketError
    }, 5000)
  }

  navBestellungen() {
    router.push({ name: RouteNames.BESTELLUNGEN })
  }

  navAgb() {
    router.push({ name: RouteNames.AGB })
  }

  navImpressum() {
    router.push({ name: RouteNames.IMPRESSUM })
  }

  navDatenschutz() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }
}
