import Inhaltsstoff from '@/entities/common/inhaltsstoff'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import InhaltsstoffList from '@/entities/member/lists/inhaltsstoff_list'
import PduktList from '@/entities/member/lists/pdukt_list'
import Pdukt, {
  Extra as ExtraDto,
  Auswahl,
  Option,
  Subprodukt,
} from '@/entities/member/pdukt'
import PduktEditRequest, {
  Extra as ReqExtra,
  Auswahl as ReqAuswahl,
  Option as ReqOption,
  Subprodukt as ReqSubprodukt,
} from '@/entities/member/request/pdukt_edit_request'
import PduktRequest from '@/entities/member/request/pdukt_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'
import MemberBaseLogic from '../member_base_logic'
import PduktInsertRequest from '@/entities/member/request/pdukt_insert_request'
import vuexStore from '@/store'
import InhaltsstoffInsertRequest from '@/entities/member/request/inhaltsstoff_insert_request'
import Produktkategorie from '@/entities/common/produktkategorie'
import ProduktkategorieList from '@/entities/member/lists/produktkategorie_list'
import BasiskategorieList from '@/entities/member/lists/basiskategorie_list'
import Basiskategorie from '@/entities/member/basiskategorie'
import Swapper from '@/util/swapper'
import PduktArtInsertRequest from '@/entities/member/request/pdukt_art_insert_request'
import PduktArt from '@/entities/member/pdukt_art'
import { response } from 'express'
import PduktArtList from '@/entities/member/lists/pdukt_art_list'

export default class ProduktEditMemcommonLogic extends MemberBaseLogic {
  produkt?: Pdukt = null
  extrasZusaetze: Pdukt[] = []
  produkte: Pdukt[] = []
  extrasEdit: boolean = false
  artenEdit: boolean = false
  verfuegbareExtras: Pdukt[] = []
  auswahlNeu?: AuswahlNeu = new AuswahlNeu()
  subproduktNeu: SubproduktNeu = new SubproduktNeu()
  inhaltsstoffe?: Inhaltsstoff[] = []
  verfInhaltsstoffe?: Inhaltsstoff[] = []
  betriebId: string = null
  neuesExtra: ExtraNeu = null
  neueArt: ArtNeu = null
  neuerInhaltsstoff: InhaltsstoffNeu = null
  kategorien: Basiskategorie[] = []
  kategorie: Basiskategorie = null

  produktarten: PduktArt[] = []
  verfuegbareProduktarten: PduktArtPreis[] = []
  vorhandeneProduktarten: PduktArtPreis[] = []

  image?: any = null
  imageUrl?: string = null
  tempImageId?: string = null
  imageAlt?: string = null
  imageAltEntfernen?: boolean = false

  editWorking: boolean = false
  editFormDisable: boolean = false
  editCompleted: boolean = false
  editError: boolean = false

  extrasDialogVisible: boolean = false
  artenDialogVisible: boolean = false
  auswahlDialogVisible: boolean = false
  inhaltsstoffDialogVisible: boolean = false
  subprodukteDialogVisible: boolean = false
  neuesExtraDialogVisible: boolean = false
  neueArtDialogVisible: boolean = false
  neuerInhaltsstoffDialogVisible: boolean = false
  kategorieDialogVisible: boolean = false

  speichern() {
    var request = new PduktEditRequest()
    request.id = this.produkt.id
    request.bezeichnung = this.produkt.bezeichnung
    request.beschreibung = this.produkt.beschreibung
    request.artikelNr = this.produkt.artikelNr
    request.bildId = this.produkt.bildId
    request.vegetarisch = this.produkt.vegetarisch
    request.vegan = this.produkt.vegan
    request.hot1 = this.produkt.hot1
    request.hot2 = this.produkt.hot2
    request.hot3 = this.produkt.hot3
    request.basiskategorieId = this.kategorie ? this.kategorie.id : null

    if (this.imageAltEntfernen) {
      request.bildNeuId = null
      request.bildNeu = true
    } else {
      request.bildNeuId = this.tempImageId
      request.bildNeu = this.tempImageId != null
    }

    this.produkt.extras.forEach((extra) => {
      var reqExtra = new ReqExtra()
      reqExtra.produktId = extra.produkt.id
      request.extras.push(reqExtra)
    })

    this.produkt.auswahlen.forEach((auswahl) => {
      var reqAuswahl = new ReqAuswahl()
      reqAuswahl.id = auswahl.id
      reqAuswahl.bezeichnung = auswahl.bezeichnung
      reqAuswahl.beschreibung = auswahl.beschreibung
      reqAuswahl.errorText = auswahl.errorText
      auswahl.optionen.forEach((option) => {
        var reqOption = new ReqOption()
        reqOption.id = option.id
        reqOption.produktId = option.produkt.id
        reqAuswahl.optionen.push(reqOption)
      })
      request.auswahlen.push(reqAuswahl)
    })

    this.produkt.subprodukte.forEach((subprodukt) => {
      var reqSubprodukt = new ReqSubprodukt()
      reqSubprodukt.id = subprodukt.id
      reqSubprodukt.bezeichnung = subprodukt.bezeichnung
      reqSubprodukt.beschreibung = subprodukt.beschreibung
      reqSubprodukt.errorText = subprodukt.errorText
      subprodukt.optionen.forEach((option) => {
        var reqOption = new ReqOption()
        reqOption.id = option.id
        reqOption.produktId = option.produkt.id
        reqSubprodukt.optionen.push(reqOption)
      })
      request.subprodukte.push(reqSubprodukt)
    })

    this.produkt.inhaltsstoffe.forEach((inhaltsstoff) => {
      request.inhaltsstoffe.push(inhaltsstoff.id)
    })

    this.editError = false
    this.editWorking = true
    this.editFormDisable = true

    Poster.postMember<RemoteResult<string>>(
      RestPaths.artikelPduktUpdate,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisable = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
        this.editFormDisable = false
      })
  }

  previewImage() {
    if (this.image == null) {
      this.imageUrl = null
      return
    }
    this.imageUrl = URL.createObjectURL(this.image)
    this.uploadImage()
  }

  uploadImage() {
    var data = new FormData()
    data.append('file', this.image)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.tempImageId = data.payload
      })
  }

  removeInhaltsstoff(inh: Inhaltsstoff) {
    this.verfInhaltsstoffe.push(inh)

    var index = this.produkt.inhaltsstoffe.findIndex((x: Inhaltsstoff) => {
      return x.id === inh.id
    })
    this.produkt.inhaltsstoffe.splice(index, 1)
  }

  pushInhaltsstoff(inh: Inhaltsstoff) {
    this.produkt.inhaltsstoffe.push(inh)

    var index = this.verfInhaltsstoffe.findIndex((x: Inhaltsstoff) => {
      return x.id === inh.id
    })
    this.verfInhaltsstoffe.splice(index, 1)
  }

  inhaltsstoffeVerwalten() {
    this.inhaltsstoffDialogVisible = true
  }

  neueAuswahlErstellen() {
    this.auswahlNeu = new AuswahlNeu()
    this.auswahlDialogVisible = true
  }

  neueAuswahlEinfuegen() {
    var a = new Auswahl()
    a.bezeichnung = this.auswahlNeu.bezeichnung
    a.errorText = this.auswahlNeu.errorText

    this.auswahlNeu.optionen.forEach((element) => {
      var o = new Option()
      o.produkt = element
      a.optionen.push(o)
    })

    this.produkt.auswahlen.push(a)
    this.auswahlDialogVisible = false
  }

  neueAuswahlAbbrechen() {
    this.auswahlDialogVisible = false
    this.auswahlNeu = new AuswahlNeu()
  }

  neuesSubproduktErstellen() {
    this.subproduktNeu = new SubproduktNeu()
    this.subprodukteDialogVisible = true
  }

  neuesSubproduktEinfuegen() {
    var s = new Subprodukt()
    s.id = uuidv4()
    s.bezeichnung = this.subproduktNeu.bezeichnung
    s.beschreibung = this.subproduktNeu.beschreibung
    s.errorText = this.subproduktNeu.errorText

    this.subproduktNeu.optionen.forEach((element) => {
      var o = new Option()
      o.id = uuidv4()
      o.produkt = element
      s.optionen.push(o)
    })

    this.produkt.subprodukte.push(s)
    this.subprodukteDialogVisible = false
  }

  neuesSubproduktAbbrechen() {
    this.subprodukteDialogVisible = false
    this.subproduktNeu = new SubproduktNeu()
  }

  subproduktBearbeiten(index: number) {}

  subproduktEntfernen(index: number) {
    this.produkt.subprodukte.splice(index, 1)
  }

  moveSubproduktUp(index: number) {
    Swapper.instance.pushUp(this.produkt.subprodukte, index)
  }

  moveSubproduktDown(index: number) {
    Swapper.instance.pushDown(this.produkt.subprodukte, index)
  }

  extrasSpeichern() {
    this.extrasEdit = false
    this.extrasDialogVisible = false
  }

  extrasAbbrechen() {
    this.extrasEdit = false
    this.extrasDialogVisible = false
  }

  artEntfernen(index: number) {
    this.produkt.arten.splice(index, 1)
  }

  moveArtUp(index: number) {
    Swapper.instance.pushUp(this.produkt.arten, index)
  }

  moveArtDown(index: number) {
    Swapper.instance.pushDown(this.produkt.arten, index)
  }

  extraEntfernen(index: number) {
    this.produkt.extras.splice(index, 1)
  }

  moveExtraUp(index: number) {
    Swapper.instance.pushUp(this.produkt.extras, index)
  }

  moveExtraDown(index: number) {
    Swapper.instance.pushDown(this.produkt.extras, index)
  }

  neuerInhaltsstoffErstellen() {
    this.neuerInhaltsstoff = new InhaltsstoffNeu()
    this.neuerInhaltsstoffDialogVisible = true
  }

  neuerInhaltsstoffAbbrechen() {
    this.neuerInhaltsstoffDialogVisible = false
    this.neuerInhaltsstoff = null
  }

  neuerInhaltsstoffEinfuegen() {
    var request = new InhaltsstoffInsertRequest()
    request.bezeichnung = this.neuerInhaltsstoff.bezeichnung
    request.betriebId = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<Inhaltsstoff>>(
      RestPaths.inhaltsstoffeInsert,
      request
    ).then((response) => {
      if (response.data.success) {
        var neuerInh = response.data.payload
        this.inhaltsstoffe.push(neuerInh)
        this.verfInhaltsstoffe.push(neuerInh)

        this.neuerInhaltsstoffDialogVisible = false
        this.neuerInhaltsstoff = null
      }
    })
  }

  inhaltsstoffeSpeichern() {
    this.inhaltsstoffDialogVisible = false
  }

  inhaltsstoffeAbbrechen() {
    this.inhaltsstoffDialogVisible = false
  }

  inhaltsstoffEntfernen(index: number) {
    this.produkt.inhaltsstoffe.splice(index, 1)
  }

  extraHinzufuegen(extra) {}

  extraBearbeiten() {
    this.extrasEdit = true
    this.extrasDialogVisible = true
  }

  artenBearbeiten() {
    this.artenEdit = true
    this.artenDialogVisible = true
  }

  neuesExtraErstellen() {
    this.neuesExtra = new ExtraNeu()
    this.neuesExtraDialogVisible = true
  }

  neueArtErstellen() {
    this.neueArt = new ArtNeu()
    this.neueArtDialogVisible = true
  }

  neuesExtraEinfuegen() {
    var request = new PduktInsertRequest()
    request.bezeichnung = this.neuesExtra.bezeichnung
    request.beschreibung = this.neuesExtra.beschreibung
    request.artikelNr = this.neuesExtra.artikelNr
    request.extraZusatz = true
    request.betriebId = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<Pdukt>>(
      RestPaths.artikelPduktEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.extrasZusaetze.push(response.data.payload)
        this.neuesExtraDialogVisible = false
      }
    })
  }

  neueArtEinfuegen() {
    var request = new PduktArtInsertRequest()
    request.betriebId = vuexStore.state.user.betriebId
    request.bezeichnung = this.neueArt.bezeichnung

    Poster.postMember<RemoteResult<PduktArt>>(
      RestPaths.produktInsertArt,
      request
    ).then((response) => {
      if(response.data.success) {
        this.neueArtDialogVisible = false
      }
    })
  }

  moveInhaltsstoffUp(index: number) {
    Swapper.instance.pushUp(this.produkt.inhaltsstoffe, index)
  }

  moveInhaltsstoffDown(index: number) {
    Swapper.instance.pushDown(this.produkt.inhaltsstoffe, index)
  }

  neueArtAbbrechen() {
    this.neueArtDialogVisible = false
    this.neueArt = null
  }

  neuesExtraAbbrechen() {
    this.neuesExtraDialogVisible = false
    this.neuesExtra = new ExtraNeu()
  }

  pushExtra(extra) {
    var extraDto = new ExtraDto()
    extraDto.id = uuidv4()
    extraDto.produkt = extra

    this.produkt.extras.push(extraDto)
    this.verfuegbareExtras.splice(this.verfuegbareExtras.indexOf(extra), 1)
  }

  removeExtra(extra) {
    this.produkt.extras.splice(this.produkt.extras.indexOf(extra), 1)
    this.verfuegbareExtras.push(extra.produkt)
  }

  auswahlBearbeiten(index: number) {}

  auswahlEntfernen(index: number) {
    this.produkt.auswahlen.splice(index, 1)
  }

  moveAuswahlUp(index: number) {
    Swapper.instance.pushUp(this.produkt.auswahlen, index)
  }

  moveAuswahlDown(index: number) {
    Swapper.instance.pushDown(this.produkt.auswahlen, index)
  }

  neueProduktartErstellen() {

  }

  pushProduktart(pa: PduktArtPreis) {

  }

  produktartenSpeichern() {

  }

  produktartenAbbrechen() {

  }

  removeProduktart(pa: PduktArtPreis) {
    
  }

  navBack() {
    router.back()
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Pdukt>>(
      RestPaths.artikelPduktDetails,
      request
    ).then((response) => {
      this.produkt = response.data.payload

      if (this.produkt.bildId) {
        this.imageAlt =
          RestPaths.imageBasePath + this.produkt.bildId + '_produkt.jpg'
      }

      if (this.produkt.kategorieId) {
        this.kategorie = this.kategorien.find(
          (element) => element.id == this.produkt.kategorieId
        )
      }
    })

    var pduktRequest = new PduktRequest()
    pduktRequest.betriebId = this.betriebId
    pduktRequest.extraZusatz = true

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      pduktRequest
    ).then((response) => {
      this.extrasZusaetze = response.data.payload.list

      this.verfuegbareExtras = this.extrasZusaetze.map((x) => x)
      this.produkt.extras.forEach((element) => {
        var index = this.verfuegbareExtras.findIndex(
          (x) => x.id === element.produkt.id
        )
        if (index > -1) {
          this.verfuegbareExtras.splice(index, 1)
        }
      })
    })

    pduktRequest = new PduktRequest()
    pduktRequest.betriebId = this.betriebId
    pduktRequest.extraZusatz = false

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      pduktRequest
    ).then((response) => {
      this.produkte = response.data.payload.list
    })

    var iRequest = new IdRequest()
    iRequest.id = this.betriebId

    Poster.postMember<RemoteResult<InhaltsstoffList>>(
      RestPaths.inhaltsstoffeListe,
      iRequest
    ).then((response) => {
      this.inhaltsstoffe = response.data.payload.list
      this.verfInhaltsstoffe = this.inhaltsstoffe.map((x) => x)

      this.produkt.inhaltsstoffe.forEach((element) => {
        var index = this.verfInhaltsstoffe.findIndex((x) => x.id === element.id)
        if (index > -1) {
          this.verfInhaltsstoffe.splice(index, 1)
        }
      })
    })

    var kategorienRequest = new IdRequest()
    kategorienRequest.id = this.betriebId

    Poster.postMember<RemoteResult<BasiskategorieList>>(
      RestPaths.artikelBasiskategorieList,
      kategorienRequest
    ).then((response) => {
      this.kategorien = response.data.payload.list
    })

    var artenRequest = new IdRequest()
    artenRequest.id = this.betriebId

    Poster.postMember<RemoteResult<PduktArtList>>(RestPaths.produktArtList, artenRequest)
    .then((response) => {
      this.produktarten = response.data.payload.list
      this.produktarten.forEach((p)=> {
        var pp = new PduktArtPreis()
        pp.pduktArt = p
        this.verfuegbareProduktarten.push(pp)
      })
    })
  }
}

class PduktArtPreis {
  pduktArt: PduktArt
  preis: number
}

class ArtNeu {
  bezeichnung: string
}

class ExtraNeu {
  bezeichnung: string
  beschreibung: string
  artikelNr: string
}

class AuswahlNeu {
  bezeichnung: string
  errorText: string
  optionen: any[] = []
}

class SubproduktNeu {
  bezeichnung: string
  beschreibung: string
  errorText: string
  optionen: any[] = []
}

class InhaltsstoffNeu {
  bezeichnung: string
}
