import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BestellungPending from '@/entities/common/bestellung_pending'
import RemoteResult from '@/entities/common/remote_result'
import FilialeDetailRequest from '@/entities/common/request/filiale_detail_request'
import IdRequest from '@/entities/common/request/id_request'
import StringRequest from '@/entities/common/request/string_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'

export default class FilialePageLogic {
  filiale: BestellungFiliale

  init(urlPath: string) {
    var request = new FilialeDetailRequest()
    request.urlPath = urlPath
    if (vuexStore.state.standort) {
      request.locationRequest = true
      request.latitude = vuexStore.state.standort.latitude
      request.longitude = vuexStore.state.standort.longitude
    }

    Poster.postUser<RemoteResult<BestellungFiliale>>(
      RestPaths.filialeDetailsNachPfad,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
        vuexStore.commit(StoreOps.FILIALE_SELECT, this.filiale)
      }
    })

    var idRequest = new IdRequest()
    idRequest.id = vuexStore.state.bestellungPending.id

    Poster.postUser<RemoteResult<BestellungPending>>(
      RestPaths.bestellungpendingDetails,
      idRequest
    ).then((response) => {
      vuexStore.commit(StoreOps.UPDATE_NEUBESTELLUNG_DATA, response.data.payload)
    })
  }
}
