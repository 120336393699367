import BstlArtikel, { Art } from './bstl_artikel'
import { v4 as uuidv4 } from 'uuid'
import {
  Produkt as BstlArtikelProdukt,
  Auswahl as BstlArtikelAuswahl,
  Extra as BstlArtikelExtra,
  Subprodukt as BstlArtikelSubprodukt,
} from './bstl_artikel'

import {
  PreislisteProdukt,
  PreislisteProduktArt,
  PreislisteProduktAuswahl,
  PreislisteProduktExtra,
  PreislisteProduktSubprodukt,
  PreislisteProduktSubproduktOption,
  PreislisteProduktVariante,
} from './pliste_produkt_zeit'

export default class ArtikelAktuell {
  produkt?: Produkt
  anzahl?: number = 1
  kommentare?: string

  gewaehleVariante: PreislisteProduktArt = null

  static fromProdukt(preislisteProdukt: PreislisteProdukt): ArtikelAktuell {
    var result = new ArtikelAktuell()
    result.produkt = Produkt.fromBase(preislisteProdukt)
    var sId = preislisteProdukt.standardartId
    if (sId) {
      var gW: PreislisteProduktArt = null
      preislisteProdukt.arten.forEach((v) => {
        if (v.pduktArtId == sId) {
          gW = v
          return
        }
      })
      result.gewaehleVariante = gW
    }
    return result
  }

  toArtikel(): BstlArtikel {
    var result = new BstlArtikel()
    result.id = uuidv4()
    result.anzahl = this.anzahl
    result.kommentare = this.kommentare
    result.produkt = this.fromAaProdukt(this.produkt!)
    return result
  }

  private fromAaExtra(source: ProduktExtra): BstlArtikelExtra {
    var result = new BstlArtikelExtra()
    if (source.base.hasArten) {
      source.base.arten.forEach((v) => {
        if (v.pduktArtId == this.gewaehleVariante.pduktArtId) {
          var art = new Art()
          art.id = v.id
          result.art = art
          result.preis = v.preis
          return
        }
      })
    } else {
      result.preis = source.base.preis
    }

    result.id = uuidv4()
    result.preislisteExtraId = source.base.id
    result.bezeichnung = source.base.bezeichnung
    result.beschreibung = source.base.beschreibung
    result.artikelNr = source.base.artikelNr
    result.bildId = source.base.bildId
    result.vegetarisch = source.base.vegetarisch
    result.vegan = source.base.vegan
    // result.preis = source.base.preis
    return result
  }

  private fromAaProdukt(source: Produkt): BstlArtikelProdukt {
    var result = new BstlArtikelProdukt()
    var pp = source.base

    result.artikelNr = pp?.artikelNr
    result.beschreibung = pp?.beschreibung
    result.bezeichnung = pp?.bezeichnung
    result.bildId = pp?.bildId
    result.inhaltsstoffe = pp?.inhaltsstoffe
    result.preislisteProduktId = pp?.id

    if (source.base.hasArten) {
      var art = new Art()
      art.id = this.gewaehleVariante.id
      art.bezeichnung = this.gewaehleVariante.bezeichnung
      result.art = art
      result.preis = this.gewaehleVariante.preis
    } else {
      result.preis = source.base.preis
    }

    source.selectedExtras?.forEach((extra) => {
      var baExtra = this.fromAaExtra(extra)
      result.extras?.push(baExtra)
    })

    source.auswahlen?.forEach((auswahl) => {
      if (auswahl.gewaehlteOption != null) {
        var option = auswahl.gewaehlteOption
        var baAuswahl = new BstlArtikelAuswahl()
        baAuswahl.id = uuidv4()
        baAuswahl.preislisteAuswahlId = auswahl.base?.id
        baAuswahl.optionId = option.base.id
        baAuswahl.option = this.fromAaExtra(option)
        baAuswahl.bezeichnung = auswahl.base?.bezeichnung
        baAuswahl.beschreibung = auswahl.base?.beschreibung
        result.auswahlen?.push(baAuswahl)
      }
    })

    source.subprodukte?.forEach((subprodukt) => {
      if (subprodukt.gewaehlteOption != null) {
        var option = subprodukt.gewaehlteOption
        var baSubprodukt = new BstlArtikelSubprodukt()
        baSubprodukt.id = uuidv4()
        baSubprodukt.preislisteSubproduktId = subprodukt.base?.id
        baSubprodukt.optionId = option.id
        baSubprodukt.produkt = this.fromAaProdukt(option.produkt)
        baSubprodukt.bezeichnung = subprodukt.base?.bezeichnung
        baSubprodukt.beschreibung = subprodukt.base?.beschreibung
        result.subprodukte?.push(baSubprodukt)
      }
    })
    return result
  }

  calcPreis(): number {
    var preis = this.produkt?.base?.preis!

    if (this.produkt.base.hasArten) {
      if (!this.gewaehleVariante) return 0
      var varianteId = this.gewaehleVariante.pduktArtId
      preis = this.gewaehleVariante.preis

      this.produkt.selectedExtras.forEach((e) => {
        e.base.arten.forEach((v) => {
          if (v.pduktArtId == varianteId) {
            preis += v.preis
            return
          }
        })
      })

      this.produkt.auswahlen.forEach((auswahl) => {
        if (auswahl.gewaehlteOption) {
          auswahl.gewaehlteOption.base.arten.forEach((v) => {
            if (v.pduktArtId == varianteId) {
              preis += v.preis
              return
            }
          })
        }
      })

      this.produkt.subprodukte.forEach((subprodukt) => {
        if (subprodukt.gewaehlteOption == null) return
        var subpreis = subprodukt.gewaehlteOption.produkt.base.preis

        subprodukt.gewaehlteOption?.produkt.selectedExtras?.forEach((extra) => {
          subpreis += extra.base?.preis!
        })

        subprodukt.gewaehlteOption?.produkt.auswahlen?.forEach((auswahl) => {
          if (auswahl.gewaehlteOption) {
            subpreis += auswahl.gewaehlteOption.base?.preis!
          }
        })

        preis += subpreis
      })
    } else {
      this.produkt?.selectedExtras?.forEach((extra) => {
        preis += extra.base.preis
      })

      this.produkt?.auswahlen?.forEach((auswahl) => {
        if (auswahl.gewaehlteOption) {
          preis += auswahl.gewaehlteOption.base?.preis!
        }
      })

      this.produkt?.subprodukte?.forEach((subprodukt) => {
        if (subprodukt.gewaehlteOption == null) return
        var subpreis = subprodukt.gewaehlteOption?.produkt.base.preis

        subprodukt.gewaehlteOption?.produkt.selectedExtras?.forEach((extra) => {
          subpreis += extra.base?.preis!
        })

        subprodukt.gewaehlteOption?.produkt.auswahlen?.forEach((auswahl) => {
          if (auswahl.gewaehlteOption) {
            subpreis += auswahl.gewaehlteOption.base?.preis!
          }
        })

        preis += subpreis
      })
    }

    preis = preis * this.anzahl!
    return preis
  }
}

class Produkt {
  base?: PreislisteProdukt
  extras?: ProduktExtra[] = []
  selectedExtras?: ProduktExtra[] = []
  auswahlen?: ProduktAuswahl[] = []
  subprodukte?: ProduktSubprodukt[] = []

  static fromBase(base: PreislisteProdukt): Produkt {
    var result = new Produkt()
    result.base = base
    base.extras?.forEach((element) => {
      var produktExtra = ProduktExtra.fromBase(element)
      result.extras?.push(produktExtra)
    })

    base.auswahlen?.forEach((element) => {
      var produktAuswahl = ProduktAuswahl.fromBase(element)
      result.auswahlen?.push(produktAuswahl)
    })

    base.subprodukte?.forEach((element) => {
      var produktSubprodukt = ProduktSubprodukt.fromBase(element)
      result.subprodukte?.push(produktSubprodukt)
    })

    return result
  }
}

class ProduktExtra {
  base?: PreislisteProduktExtra

  static fromBase(base: PreislisteProduktExtra): ProduktExtra {
    var result = new ProduktExtra()
    result.base = base

    return result
  }
}

class ProduktSubprodukt {
  base?: PreislisteProduktSubprodukt
  optionen?: ProduktSubproduktOption[] = []
  gewaehlteOption?: ProduktSubproduktOption
  dialogOpen = false

  static fromBase(base: PreislisteProduktSubprodukt): ProduktSubprodukt {
    var result = new ProduktSubprodukt()
    result.base = base
    base.optionen.forEach((element) => {
      var subproduktOption = new ProduktSubproduktOption()
      subproduktOption.id = element.id
      var pOption = Produkt.fromBase(element.preislisteProdukt)
      subproduktOption.produkt = pOption
      result.optionen?.push(subproduktOption)
    })
    return result
  }
}

class ProduktSubproduktOption {
  id?: string
  produkt?: Produkt
}

class ProduktAuswahl {
  base?: PreislisteProduktAuswahl
  optionen?: ProduktAuswahlOption[] = []
  gewaehlteOption?: ProduktAuswahlOption
  dialogOpen = false

  static fromBase(base: PreislisteProduktAuswahl): ProduktAuswahl {
    const result = new ProduktAuswahl()
    result.base = base

    let defOptionId = base.defaultOptionId

    base.optionen.forEach((element) => {
      const pOption = ProduktAuswahlOption.fromBase(element)
      result.optionen?.push(pOption)
      if (pOption.base.extraId == defOptionId) {
        result.gewaehlteOption = pOption
      }
    })
    return result
  }
}

class ProduktAuswahlOption {
  base?: PreislisteProduktExtra
  produkt?: ProduktExtra

  static fromBase(base: PreislisteProduktExtra): ProduktAuswahlOption {
    const result = new ProduktAuswahlOption()
    result.base = base
    result.produkt = ProduktExtra.fromBase(base)
    return result
  }
}

export {
  Produkt,
  ProduktExtra,
  ProduktAuswahl,
  ProduktAuswahlOption,
  ProduktSubprodukt,
}
