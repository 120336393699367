
import FilialeSimple from '@/entities/member/filiale_simple'
import Vue from 'vue'
import Component from 'vue-class-component'
import ToolbarButton from '../custom/ToolbarButton.vue'
import FilialenMemcommonLogic from '@/logic/memcommon/filialen_memcommon_logic'
import { Prop, PropSync } from 'vue-property-decorator'
import ErrorBox from '../custom/ErrorBox.vue'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class Filialen extends Vue {
  @Prop() logic: FilialenMemcommonLogic

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  neueFiliale() {
    this.logic.neueFiliale()
  }

  detailsAnzeigen(filiale: FilialeSimple) {
    this.logic.detailsAnzeigen(filiale)
  }

  shortcutsAnzeigen() {
    this.logic.shortcutsAnzeigen()
  }
}
