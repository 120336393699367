
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import BestellungSimple from '@/entities/member/bestellung_simple'
import BestellungenRequest from '@/entities/member/request/bestellungen_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import BestellungSimpleList from '@/entities/member/lists/bestellung_simple_list'
import FilialeSimple from '@/entities/member/filiale_simple'
import router from '@/router'
import RouteNames from '@/util/route_names'
import { Terminart } from '@/entities/common/enums'
import IdLocalTimeRequest from '@/entities/common/request/id_local_time_request'

@Component
export default class BestellverwaltungListe extends mixins(Calculator) {
  filterDialogVisible: boolean = false
  datumVonDialogVisible: boolean = false
  datumBisDialogVisible: boolean = false

  bestellungen: BestellungSimple[] = []
  filter: Filter = new Filter()
  filialen: FilialeSimple[] = []

  headers: any[] = [
    { text: 'Bestellzeitp.', value: 'bestellZp' },
    { text: 'Terminart', value: 'terminart' },
    { text: 'Abgeschlossen', value: 'abgeschlossen' },
    { text: 'Storniert', value: 'storniert' },
    { text: 'Bezahlt', value: 'bezahlt' },
  ]

  gebeBooleanClass(value: boolean): string {
    return value ? 'green--text' : 'red--text'
  }

  formatiereTerminart(value: string): string {
    if (!value) return null
    switch (value) {
      case Terminart.ABHOLUNG:
        return 'Abholung'
      case Terminart.LIEFERUNG:
        return 'Lieferung'
      case Terminart.SOFORTBESTELLUNG:
        return 'Tischbestellung'
      case Terminart.RESERVIERUNG:
        return 'Reservierung'
    }
  }

  formatiereTerminartIcon(value: string): string {
    if (!value) return null
    switch (value) {
      case Terminart.ABHOLUNG:
        return '$vuetify.icons.abholung'
      case Terminart.LIEFERUNG:
        return 'mdi-moped-outline'
    }
  }

  handleRowClick(value) {
    router.push({
      name: RouteNames.MEMBER.BESTELLUNG,
      params: { id: value.id },
    })
  }

  aktualisieren() {
    var request = new BestellungenRequest()
    request.betriebId = vuexStore.state.user.betriebId
    request.filialeBeliebig = this.filter.filialeBeliebig
    request.filialeId = this.filter.filiale
    request.abgeschlossene = this.filter.abgeschlossene
    request.stornierte = this.filter.stornierte
    request.bezahlte = this.filter.bezahlte
    request.datumVon = this.filter.datumVon
    request.datumBis = this.filter.datumBis

    Poster.postMember<RemoteResult<BestellungSimpleList>>(
      RestPaths.bestellungMitarbeiterListeComplex,
      request
    ).then((response) => {
      this.bestellungen = response.data.payload.list
      this.filterDialogVisible = false
    })
  }

  checkstates = [
    { name: 'beliebig', value: 0 },
    { name: 'ja', value: 1 },
    { name: 'nein', value: 2 },
  ]
}

class Filter {
  filialeBeliebig: boolean = true
  filiale: string = null
  datumBeliebig: boolean = true
  datumVon: string = null
  datumBis: string = null
  abgeschlossene: number = 0
  stornierte: number = 0
  bezahlte: number = 0
  bestellarten: number[] = []
}
