import Pdukt from '@/entities/member/pdukt'
import router from '@/router'
import RouteNames from '@/util/route_names'
import ProdukteMemcommonLogic from '../memcommon/produkte_memcommon_logic'

export default class ProdukteLogic extends ProdukteMemcommonLogic {
  produktBearbeiten(produkt: Pdukt) {
    router.push({
      name: RouteNames.SYSTEM.PRODUKT_EDIT,
      params: { id: produkt.id },
    })
  }

  extrasVerwalten() {
    router.push({ name: RouteNames.SYSTEM.PRODUKT_EXTRAS })
  }

  inhaltsstoffeVerwalten() {
    router.push({ name: RouteNames.SYSTEM.INHALTSSTOFFE })
  }

  neuesProdukt() {
    router.push({ name: RouteNames.SYSTEM.PRODUKT_NEU })
  }

  artenVerwalten() {
    router.push({ name: RouteNames.SYSTEM.PRODUKTARTEN })
  }

  standardpreiseVerwalten(): void {
    throw new Error('Method not implemented.')
  }

  detailsAnzeigen(produkt: Pdukt) {
    router.push({
      name: RouteNames.SYSTEM.PRODUKT_DETAILS,
      params: { id: produkt.id },
    })
  }

  kategorienVerwalten() {
    router.push({ name: RouteNames.SYSTEM.BASISKATEGORIEN })
  }
}
