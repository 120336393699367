import Bestellung from '@/entities/common/bestellung'
import store from '@/store'
import IdRequest from '@/entities/common/request/id_request'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'
import Poster from '@/util/poster'
import SocketMessageType from '@/util/socket_message_types'
import { AbholungStatus, AbholzeitType } from '@/entities/common/enums'
import Formatter from '../formatter'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import axios from 'axios'
import { saveAs } from 'file-saver'

export default class DetailsAbholungLogic {
  bestellung: Bestellung = null
  navigator = null
  abholbereitListener: () => void

  init(navigator: Navigator) {
    this.bestellung = store.state.bestellung.bestellung
    this.navigator = navigator

    store.watch(
      (state) => state.bestellung,
      (newValue) => {
        this.bestellung = newValue.bestellung
      }
    )

    store.watch(
      (state) => state.incomingMessage,
      (newValue) => {
        if (newValue) {
          if (newValue.type == SocketMessageType.ABHOLUNG_STATUS_UPDATE) {
            var payload = JSON.parse(newValue.payload)
            if (payload.id == this.bestellung.id) {
              var status = payload.status
              this.bestellung.abholung.status = status

              if (status == AbholungStatus.ABHOLBEREIT) {
                if (this.abholbereitListener) {
                  this.abholbereitListener()
                }
              }

              this.reloadBestellung()
            }
          }
        }
      }
    )
  }

  createPdf() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    axios
      .post(RestPaths.urlBase + RestPaths.bestellungKundeBestellungRechnung, request, {
        responseType: 'blob',
      })
      .then((response) => {
        saveAs(response.data, 'PDF.pdf')
      })
  }

  reloadBestellung() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        store.commit(StoreOps.UPDATE_BESTELLUNG, response.data.payload)
      }
    })
  }

  computeAbholzeit() {
    if (this.bestellung.abholung.type == AbholzeitType.BALDEST) {
      return 'schnellst möglich'
    } else if (this.bestellung.abholung.type == AbholzeitType.UHRZEIT) {
      return Formatter.formatiereDatumUhrzeit(
        this.bestellung.abholung.gewaehlterAbholZp
      )
    }
  }

  bestellungAbholbereit() {
    if (this.bestellung.abholung.status == AbholungStatus.ABHOLBEREIT)
      return true
    return false
  }

  bestimmeBild(bestellung: Bestellung) {
    if (bestellung == null) return null
    var bildId = bestellung.filiale.bildId
    if (bildId == null) return null
    return RestPaths.urlBase + 'images/image2/' + bildId
  }

  formatiereStatus() {
    if (this.bestellung == null) return null
    switch (this.bestellung.abholung.status) {
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
      case AbholungStatus.IN_BEARBEITUNG:
        return 'In Bearbeitung'
    }
  }

  formatiereTisch() {
    if (this.bestellung == null) return null
    if (this.bestellung.abholung == null) return null
    if (this.bestellung.abholung.tischId == null) return '---'
    return 'Tisch ' + this.bestellung.abholung.tischBezeichnung
  }

  stornieren(finishedHandler: () => void) {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeStornieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung.storniert = true
        this.bestellung.abgeschlossen = true
        if (finishedHandler) {
          finishedHandler()
        }
      }
    })
  }
}
