import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import { AbholzeitType, Terminart } from '@/entities/common/enums'
import PlisteProduktZeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import RemoteResult from '@/entities/common/remote_result'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import router from '@/router'
import store from '@/store'
import Nbd from '@/util/neue-bestellung-data'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import moment from 'moment'
import ProdukteLogic from '@/logic/produkte_logic'
import StoreOps from '@/util/store_ops'
import BPArtikelHinzufuegenRequest from '@/entities/common/pending/bp_artikel_hinzufuegen_request'
import ConverterUtil from '@/util/converter_util'

export default class ShoppingLogic implements ProdukteLogic {
  get hasMindestbestellwert() {
    return false
  }

  get mindestbestellwert() {
    return 0
  }

  get kostenlosAb() {
    return 0
  }

  preisliste: PlisteProduktZeit = null
  warenkorb: BstlArtikel[] = []
  filiale: BestellungFiliale = null
  created = false
  artikel: PreislisteProdukt = null

  artikelWaehlen(produkt: PreislisteProdukt): void {
    this.artikel = produkt
    router.push({
      name: RouteNames.FILIALE_PAGE_AREA.SHOPPING.ARTIKEL,
      params: { id: this.artikel.id },
    })
  }

  get warenkorbGefuellt(): boolean {
    return this.warenkorb.length > 0
  }

  bestellungEinfuegen(): void {
    this.weiter()
  }

  weiter() {
    store.commit(StoreOps.UPDATE_NBD_ARTIKELLISTE, this.warenkorb)
    var terminart = store.state.bestellungPending.terminart
    if (terminart == Terminart.LIEFERUNG) {
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.WARENKORB_LIEFERUNG })
    } else {
      router.push({ name: RouteNames.FILIALE_PAGE_AREA.WARENKORB })
    }
    return
  }

  createdListener: () => void

  reloadArtikel(id: string) {
    this.preisliste.kategorien.forEach((kategorie) => {
      kategorie.produkte.forEach((p) => {
        if (p.id == id) {
          this.artikel = p
          return
        }
      })
    })

    // this.preislisten.forEach((pliste: PlisteProduktZeit) => {
    //   pliste.kategorien.forEach((kategorie) => {
    //     kategorie.produkte.forEach((p) => {
    //       if (p.id == id) {
    //         this.artikel = p
    //         return
    //       }
    //     })
    //   })
    // })
  }

  init() {
    var nbd = store.state.bestellungPending
    this.filiale = nbd.filiale
    this.warenkorb = nbd.artikelliste
    var terminart = nbd.terminart
    var datum = null

    if (terminart == Terminart.ABHOLUNG) {
      if (nbd.abholung.abholzeitType == AbholzeitType.BALDEST) {
        datum = moment().format('yyyy-MM-DDTHH:mm:ss')
      } else {
        // var d = nbd.abholung.datum
        var u = nbd.abholung.gewaehlteUhrzeit
        // datum = d + 'T' + u
        datum = u
      }
    } else if (terminart == Terminart.SOFORTBESTELLUNG) {
      datum = moment().format('yyyy-MM-DDTHH:mm:ss')
    } else if (terminart == Terminart.RESERVIERUNG) {
      datum = nbd.reservierung.zeitVon
    } else if (terminart == Terminart.LIEFERUNG) {
      if (nbd.lieferung.abholzeitType == AbholzeitType.BALDEST) {
        datum = moment().format('yyyy-MM-DDTHH:mm:ss')
      } else {
        datum =
          // nbd.lieferungData.datum + 'T' + nbd.lieferungData.gewaehlteUhrzeit
          nbd.lieferung.gewaehlteUhrzeit
      }
    }

    var request = new PreislisteZeitraumRequest()
    request.filialeId = nbd.filiale.filialeId
    request.zeitpunkt = datum

    Poster.postUser<RemoteResult<PlisteProduktZeit>>(
      RestPaths.artikelPdukteNachZeitSingle,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preisliste = response.data.payload
        this.created = true
        if (this.createdListener) {
          this.createdListener()
        }
      }
    })
  }

  addArtikel(artikel: BstlArtikel) {
    var request = new BPArtikelHinzufuegenRequest()
    request.id = store.state.bestellungPending.id
    request.artikelData = ConverterUtil.convertBstlArtikel(artikel)

    Poster.postUser<RemoteResult<BstlArtikel>>(
      RestPaths.bestellungpendingArtikelHinzufuegen,
      request
    ).then((response) => {
      var newArtikel = response.data.payload
      store.commit(StoreOps.ARTIKEL_HINZUFUEGEN, newArtikel)
      //this.warenkorb.push(artikel)
      router.go(-1)
    })
  }
}