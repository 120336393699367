import BstlArtikel from '@/entities/common/bstl_artikel'
import { Produkt } from '@/entities/common/bstl_artikel'
import {
  Produkt as ProduktData,
  Extra as ExtraData,
  Auswahl as AuswahlData,
  Subprodukt as SubproduktData,
} from '@/entities/common/request/artikel_data'
import ArtikelData from '@/entities/common/request/artikel_data'
import { v4 as uuidv4 } from 'uuid'

export default class ConverterUtil {
  public static convertBstlArtikel(artikel: BstlArtikel): ArtikelData {
    var artikelData = new ArtikelData()
    //artikelData.id = uuidv4()
    artikelData.anzahl = artikel.anzahl!
    artikelData.kommentare = artikel.kommentare
    artikelData.produkt = this.convertProdukt(artikel.produkt!)
    return artikelData
  }

  private static convertProdukt(source: Produkt): ProduktData {
    var produkt = new ProduktData()
    produkt.preislisteProduktId = source.preislisteProduktId
    if (source.art) {
      produkt.artId = source.art.id
    }
    produkt.id = uuidv4()

    source.extras?.forEach((sourceExtra) => {
      var extra = new ExtraData()
      extra.id = sourceExtra.id

      if(sourceExtra.art) {
        extra.artId = sourceExtra.art.id
      }
      extra.preislisteProduktExtraId = sourceExtra.preislisteExtraId
      produkt.extras.push(extra)
    })

    source.auswahlen?.forEach((sourceAuswahl) => {
      var auswahl = new AuswahlData()
      auswahl.id = sourceAuswahl.id
      auswahl.auswahlId = sourceAuswahl.preislisteAuswahlId
      auswahl.optionId = sourceAuswahl.optionId
      auswahl.produkt = this.convertProdukt(sourceAuswahl.option)
      produkt.auswahlen.push(auswahl)
    })

    source.subprodukte?.forEach((sourceSubprodukt) => {
      var subprodukt = new SubproduktData()
      subprodukt.id = sourceSubprodukt.id
      subprodukt.subproduktId = sourceSubprodukt.preislisteSubproduktId
      subprodukt.optionId = sourceSubprodukt.optionId
      subprodukt.produkt = this.convertProdukt(sourceSubprodukt.produkt)
      produkt.subprodukte.push(subprodukt)
    })

    return produkt
  }
}
