export default class PduktStandardpreiseUpdateRequest {
  produkte: Produkt[] = []
}

class Produkt {
  id: string
  standardpreis: number
  varianten: Variante[] = []
}

class Variante {
  id: string
  standardpreis: number
}

export { Produkt, Variante }
