import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { AbholzeitType, Terminart } from '@/entities/common/enums'
import Oeffnungszeiten, { Oeffnung } from '@/entities/common/oeffnungszeiten'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import StoreOps from '@/util/store_ops'
import RouteNames from '@/util/route_names'
import RemoteResult from '@/entities/common/remote_result'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import OeffnungRequest from '@/entities/common/request/oeffnung_request'
import BPLieferungTerminWaehlenRequest from '@/entities/common/pending/bp_lieferung_termin_waehlen_request'

export default class TerminLieferungLogic {
  selectedLieferType: string = AbholzeitType.BALDEST
  private _selectedDate?: string = null
  selectedTime?: string = null
  selectedOeffnung: Oeffnung = null
  geoeffneteTage: Oeffnung[] = null
  minDate?: string = null
  maxDate?: string = null
  uhrzeiten = []

  filiale: BestellungFiliale

  get selectedDate(): string {
    return this._selectedDate
  }

  get abholtypes() {
    if (!this.filiale) return null
    if (!this.filiale.zurZeitGeoeffnet) {
      return [{ label: 'Termin wählen', value: AbholzeitType.UHRZEIT }]
    }
    return [
      { label: 'Schnellstmöglich', value: AbholzeitType.BALDEST },
      { label: 'Termin wählen', value: AbholzeitType.UHRZEIT },
    ]
  }

  get weiterDisabled(): boolean {
    if (this.selectedLieferType == AbholzeitType.BALDEST) return false
    if (this.selectedDate == null || this.selectedTime == null) return true
    return false
  }

  set selectedDate(value: string) {
    this._selectedDate = value

    let oeffnung = null

    this.geoeffneteTage.forEach((toCompare) => {
      if (toCompare.datum == value) {
        oeffnung = toCompare
        return
      }
    })
    this.selectedOeffnung = oeffnung

    var uz = []
    this.selectedOeffnung.oeffnungszeiten.forEach((oz) => {
      var vonTemp = moment(oz.zeitVon, 'HH:mm:ss').clone()
      var bis = moment(oz.zeitBis, 'HH:mm:ss')

      while (vonTemp.isBefore(bis)) {
        uz.push(vonTemp.clone())
        vonTemp.add(15, 'minutes')
      }
    })
    this.uhrzeiten = uz.map((el) => {
      return el.format('HH:mm')
    })
  }

  terminAuswaehlen() {
    var request = new BPLieferungTerminWaehlenRequest()
    request.id = store.state.bestellungPending.id
    request.type = this.selectedLieferType
    if (this.selectedLieferType == AbholzeitType.UHRZEIT) {
      request.zeitpunkt = this._selectedDate + 'T' + this.selectedTime
    }

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingLieferungTermin,
      request
    ).then((response) => {
      if (response.data.success) {
        var payload = {
          type: this.selectedLieferType,
          uhrzeit: response.data.payload,
        }

        store.commit(StoreOps.UPDATE_NEU_LIEFERUNG_TERMIN, payload)
        router.push({
          name: RouteNames.FILIALE_PAGE_AREA.SHOPPING_LIEFERUNG.PRODUKTE,
        })
      }
    })
  }

  init() {
    this.filiale = store.state.bestellungPending.filiale
    if (!this.filiale.zurZeitGeoeffnet) {
      this.selectedLieferType = AbholzeitType.UHRZEIT
    }

    var filialeId = this.filiale.filialeId
    var request = new OeffnungRequest()
    request.filialeId = filialeId
    request.terminart = Terminart.LIEFERUNG

    Poster.postUser<RemoteResult<Oeffnungszeiten>>(
      RestPaths.oeffnungszeitOeffnungszeiten,
      request
    ).then((response) => {
      this.minDate = response.data.payload.minDate
      this.maxDate = response.data.payload.maxDate
      this.geoeffneteTage = response.data.payload.daten
    })
  }

  datumWaehlen() {}
}
