
import Bestellung from '@/entities/common/bestellung'
import AbholungStartenTischLogic from '@/logic/bestellung_neu/abholung_starten_tisch_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { FilialeHeader } })
export default class AbholungStartenTisch extends mixins(Calculator) {
  logic?: AbholungStartenTischLogic = null

  bestellungStarten() {
    this.logic.bestellungStarten()
  }

  mounted() {
    this.logic = new AbholungStartenTischLogic()
    this.logic.init(this.$route.params.id)
  }

  bestellungAnzeigen(bestellung: Bestellung) {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: bestellung.id },
    })
  }
}
