
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import VerzehrartLogic from '@/logic/bestellung_neu/verzehrart_logic'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { FilialeHeader } })
export default class Verzehrart extends mixins(Calculator) {
  logic: VerzehrartLogic = new VerzehrartLogic()

  weiter() {
    this.logic.weiter()
  }
}
