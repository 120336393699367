
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import GoogleLocation from '@/entities/common/google_location'
import LocationRequest from '@/entities/common/request/location_request'
import Poster from '@/util/poster'
import GoogleLocationList from '@/entities/common/lists/google_location_list'
import RestPaths from '@/util/rest_paths'
import RemoteResult from '@/entities/common/remote_result'
import VueCookie from 'vue-cookie'
import vuexStore from '@/store'
import StoreOps from '@/util/store_ops'
import LatLonFilialeRequest from '@/entities/common/request/lat_lon_filiale_request'
import FilialeLiefergebietResult from '@/entities/common/filiale_liefergebiet_result'
import LatLonRequest from '@/entities/common/request/lat_lon_request'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component
export default class StandortLieferung extends mixins(Calculator) {
  logic: StandortLieferungLogic = new StandortLieferungLogic()

  mounted() {
    this.logic.init()
  }

  standortErmitteln() {
    this.logic.positionErmitteln(navigator)
  }

  weiter() {
    this.logic.weiter()
  }
}

class StandortLieferungLogic {
  locations: GoogleLocation[] = []
  _searchText: string = null
  _selectedLocation: GoogleLocation = null
  standort: string = null
  liefergebiet: FilialeLiefergebietResult = null
  filiale: BestellungFiliale = null

  timerHandle: any = null
  timeout: number = 1000

  init() {
    this.filiale = vuexStore.state.bestellungPending.filiale
  }

  weiter() {
    var updateLiefergebiet = {
      id: this.liefergebiet.liefergebietId,
      lieferkosten: this.liefergebiet.lieferkosten,
      mindestbestellwert: this.liefergebiet.mindestbestellwert,
      kostenlosAb: this.liefergebiet.kostenlosAb,
      lieferzeit: this.liefergebiet.lieferzeit,
    }

    vuexStore.commit(StoreOps.UPDATE_LIEFERGEBIET, updateLiefergebiet)
    router.push({ name: RouteNames.FILIALE_PAGE_AREA.TERMIN_LIEFERUNG })
  }

  get searchText(): string {
    return this._searchText
  }

  set searchText(newValue: string) {
    this._searchText = newValue
    this.timeout = 1000

    if (!this.timerHandle) {
      this.timerHandle = setInterval(() => {
        this.timeout = this.timeout - 200

        if (this.timeout <= 0) {
          clearInterval(this.timerHandle)
          this.timerHandle = null
          this.timeout = 1000

          if (this._searchText.length >= 3) {
            var request = new LocationRequest()
            request.locationString = this._searchText

            Poster.postMember<RemoteResult<GoogleLocationList>>(
              RestPaths.locationListByTextOSM,
              request
            ).then((response) => {
              if (response.data.success) {
                this.locations = []
                response.data.payload.list.forEach((element) => {
                  this.editLocation(element)
                  this.locations.push(element)
                })
              }
            })
          }
        }
      }, 200)
    }
  }

  get selectedLocation(): GoogleLocation {
    return this._selectedLocation
  }

  set selectedLocation(newValue: GoogleLocation) {
    this._selectedLocation = newValue

    var updateStandort = {
      bezeichnung: this._selectedLocation.bezeichnung,
      land: this._selectedLocation.land,
      plz: this._selectedLocation.plz,
      ort: this._selectedLocation.stadt,
      strasse:
        this._selectedLocation.strasse + ' ' + this.selectedLocation.strasseNr,
      latitude: this._selectedLocation.latitude,
      longitude: this._selectedLocation.longitude,
    }

    VueCookie.delete('location_cookie')

    this.standort = this._selectedLocation.bezeichnung

    vuexStore.commit(StoreOps.UPDATE_STANDORT, updateStandort)

    var request = new LatLonFilialeRequest()
    request.filialeId = vuexStore.state.bestellungPending.filiale.filialeId
    request.latitude = this._selectedLocation.latitude
    request.longitude = this._selectedLocation.longitude

    Poster.postUser<RemoteResult<FilialeLiefergebietResult>>(
      RestPaths.filialeLiefergebietStandort,
      request
    ).then((response) => {
      if (response.data.success) {
        this.liefergebiet = response.data.payload
      }
    })
  }

  editLocation(l: GoogleLocation) {
    var b = ''
    if (l.strasse) b += l.strasse + ' '
    if (l.strasseNr) b += l.strasseNr + ', '
    if (l.plz) b += l.plz + ' '
    if (l.stadt) b += l.stadt + ', '
    if (l.land) b += l.land
    l.bezeichnung = b
  }

  positionErmitteln(navigator: Navigator) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const lat = pos.coords.latitude
        const lon = pos.coords.longitude

        var request = new LatLonRequest()
        request.latitude = lat
        request.longitude = lon

        Poster.postUser<RemoteResult<GoogleLocation>>(
          RestPaths.locationLocationByCoordinatesOSM,
          request
        ).then((response) => {
          var l = response.data.payload
          this.editLocation(l)
          this.selectedLocation = l
        })
      })
    }
  }
}
