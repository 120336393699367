import BestellungOeffnungszeit from "./bestellung_oeffnungszeit"

export default class BestellungFiliale {
  filialeId: string
  filialeBezeichnung: string
  betriebId: string
  betriebBezeichnung: string
  strasse: string
  plz: string
  ort: string
  telefon: string
  email: string
  bildId: string
  bannerId: string
  logoKleinId: string
  oeffnungszeitArray?: any[] = []
  oeffnungszeitListe?: BestellungOeffnungszeit[] = []
  latitude: number
  longitude: number
  distance?: number
  supportsAbholung: boolean
  supportsReservierung: boolean
  supportsSofortbestellung: boolean
  supportsPaypal: boolean
  supportsLieferung: boolean
  zurZeitGeoeffnet: boolean
  colorPrimary: number
  colorSecondary: number
  colorTertiary: number
  darkMode: boolean = true
  urlPath: string
  abholungSettings: AbholungSettings
  reservierungSettings: ReservierungSettings
  sofortbestellungSettings: SofortbestellungSettings
  lieferungSettings: LieferungSettings
}

class AbholungSettings {
  supportsVorbestellung: boolean
  supportsMehrereTage: boolean
  zeitVorSchliessung: number
  vorbestellungTage: number
}

class ReservierungSettings {
  supportsVorbestellung: boolean
  zeitVorSchliessung: number
  reservierungTage: number
}

class SofortbestellungSettings {
  zeitVorSchliessung: number
}

class LieferungSettings {
  supportsTermin: boolean
  terminTage: number
  liefergebietId: string
  lieferkosten: number
  lieferzeitVon: number
  lieferzeitBis: number
  mindestbestellwert: number
  kostenlosAb: number
}

export {AbholungSettings, ReservierungSettings, SofortbestellungSettings, LieferungSettings}