
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import ReservierungSelectVorbestellungLogic from '@/logic/bestellung_neu/reservierung_select_vorbestellung_logic'
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import Calculator from '../mixins/Calculator.vue'
import LoginLogic from '@/logic/member/login_logic'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class ReservierungSelectVorbestellung extends mixins(
  Calculator
) {
  logic?: ReservierungSelectVorbestellungLogic = null

  mounted() {
    if (!this.$store.state.isNeueBestellung) return
    this.logic = new ReservierungSelectVorbestellungLogic()
    this.logic.init()
  }

  produkteVorbestellen() {
    this.logic.produkteVorbestellen()
  }

  reservierungAbschliessen() {
    this.logic.reservierungAbschliessen()
  }
}
