
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import PduktArtList from '@/entities/member/lists/pdukt_art_list'
import PduktArt from '@/entities/member/pdukt_art'
import PduktArtInsertRequest from '@/entities/member/request/pdukt_art_insert_request'
import PduktArtUpdateRequest from '@/entities/member/request/pdukt_art_update_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ProduktArtenMemCommon extends Vue {
  neueArt: PduktArt = null
  editArt: PduktArt = null

  neueArtDialogVisible: boolean = false
  editArtDialogVisible: boolean = false

  arten: PduktArt[] = []

  computeBackgroundStyle(index: number) {
    return index % 2 == 1 ? 'background-color: #efefef' : null
  }

  bearbeiten(pa: PduktArt) {
    this.editArt = pa
    this.editArtDialogVisible = true
  }

  neueArtErstellen() {
    this.neueArt = new PduktArt()
    this.neueArtDialogVisible = true
  }

  neueArtAbbrechen() {
    this.neueArtDialogVisible = false
    this.neueArt = null
  }

  editArtSpeichern() {
    var request = new PduktArtUpdateRequest()
    request.id = this.editArt.id
    request.bezeichnung = this.editArt.bezeichnung

    Poster.postMember<RemoteResult<any>>(
      RestPaths.produktUpdateArt,
      request
    ).then((response) => {
      this.editArtDialogVisible = false
      this.editArt = null
    })
  }

  neueArtEinfuegen() {
    var request = new PduktArtInsertRequest()
    request.betriebId = vuexStore.state.user.betriebId
    request.bezeichnung = this.neueArt.bezeichnung

    Poster.postMember<RemoteResult<PduktArt>>(
      RestPaths.produktInsertArt,
      request
    ).then((response) => {
      this.arten.push(response.data.payload)
      this.neueArtDialogVisible = false
      this.neueArt = null
    })
  }

  editArtAbbrechen() {
    this.editArtDialogVisible = false
    this.editArt = null
  }

  mounted() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<PduktArtList>>(
      RestPaths.produktArtList,
      request
    ).then((response) => {
      this.arten = response.data.payload.list
    })
  }
}
