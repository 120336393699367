export default class StoreOps {
  public static FILIALE_SELECT = 'filialeSelect'
  public static FILIALE_SELECT_MEMBER = 'filialeSelectMember'
  public static BESTELLUNG_DIREKT_STARTEN = 'bestellungDirektStarten'
  public static BESTELLUNG_DIREKT_STARTEN_TISCH = 'bestellungDirektStartenTisch'
  public static UPDATE_BESTELLUNG = 'updateBestellung'
  public static BESTELLUNG_STARTEN = 'bestellungStarten'
  public static UPDATE_NEU_ABHOLUNG_TERMIN = 'updateNeuAbholungTermin'
  public static UPDATE_NEU_LIEFERUNG_TERMIN = 'updateNeuLieferungTermin'
  public static POST_INCOMING_MESSAGE = 'postIncomingMessage'
  public static UPDATE_IDENTIFIER = 'updateIdentifier'
  public static LOGIN_MEMBER = 'loginMember'
  public static LOGIN_KUNDE = 'loginKunde'
  public static LOGOUT = 'logout'
  public static CLEAR_NEUE_BESTELLUNG = 'clearNeueBestellung'
  public static REMOVE_ARTIKEL_BESTELLUNG_NEU = 'removeArtikelBestellungNeu'
  public static BEGINNE_BESTELLUNG = 'beginneBestellung'
  public static BEGINNE_VORBESTELLUNG = 'beginneVorbestellung'
  public static BEGINNE_NACHBESTELLUNG = 'beginneNachbestellung'
  public static UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG =
    'updateBestellungArtikelBestellung'
  public static UPDATE_NBD_ARTIKELLISTE = 'updateNbdArtikelliste'
  public static UPDATE_NBD_RESERVIERUNG_TERMIN = 'updateNbdReservTermin'
  public static UPDATE_NBD_RESERV_KUNDE = 'updateNbdReservKunde'
  public static UPDATE_NBD_LIEFERUNG_KUNDE = 'updateNbdLieferungKunde'
  public static UPDATE_NBD_ABH_KUNDE = 'updateNbdAbhKunde'
  public static UPDATE_NBD_ABH_VERZEHRART = 'updateNbdAbhVerzehrart'
  public static UPDATE_NBD_SOF_TISCH = 'updateNbdSofTisch'
  public static UPDATE_QR_CODE_TISCH = 'updateQrCodeTisch'
  public static UPDATE_STANDORT = 'updateStandort'
  public static UPDATE_LIEFERGEBIET = 'updateLiefergebiet'
  public static UPDATE_NEUBESTELLUNG_DATA = 'updateNeubestellungData'
  public static ARTIKEL_HINZUFUEGEN = 'artikelHinzufuegen'
  public static TRINKGELD_FESTLEGEN = 'trinkgeldFestlegen'
}
