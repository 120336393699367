import Bestellung from '@/entities/common/bestellung'
import { Terminart } from '@/entities/common/enums'
import BestellungList from '@/entities/common/lists/bestellung_list'
import BPBeginnenRequest from '@/entities/common/pending/bp_beginnen_request'
import RemoteResult from '@/entities/common/remote_result'
import BestellungFilialeRequest from '@/entities/common/request/bestellung_filiale_request'
import IdRequest from '@/entities/common/request/id_request'
import TischMitFiliale from '@/entities/common/tisch_mit_filiale'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class AbholungStartenTischLogic {
  tisch?: TischMitFiliale = null
  bestellungen?: Bestellung[] = []

  bestellungStarten() {
    var request = new BPBeginnenRequest()
    request.filialeId = this.tisch.filiale.filialeId
    request.terminart = Terminart.ABHOLUNG
    request.direktabholung = true
    request.tischId = this.tisch.tisch.id
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      if (response.data.success) {
        var payload = {
          bestellungId: response.data.payload,
          filiale: this.tisch.filiale,
          tisch: this.tisch.tisch,
        }

        store.commit(StoreOps.BESTELLUNG_DIREKT_STARTEN_TISCH, payload)
        router.push({ name: RouteNames.FILIALE_PAGE_AREA.VERZEHRART })
      }
    })
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postUser<RemoteResult<TischMitFiliale>>(
      RestPaths.filialeBestellungSucheTisch,
      request
    ).then((response) => {
      if (response.data.success) {
        this.tisch = response.data.payload
      }
    })

    var bestellungenRequest = new BestellungFilialeRequest()
    bestellungenRequest.identifierId = store.state.user.identifier
    bestellungenRequest.filialeId = id
    Poster.postUser<RemoteResult<BestellungList>>(
      RestPaths.bestellungKundeFilialeAktuell,
      bestellungenRequest
    ).then((response) => {
      if (response.data.success) {
        this.bestellungen = response.data.payload.list
      }
    })
  }
}
