import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import NeuePreislisteInfo from '@/entities/member/neue_preisliste_info'
import {
  Kategorie,
  Produkt,
} from '@/components/custom/preisliste'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import PlisteInsertRequest, {
  Kategorie as ReqKategorie,
} from '@/entities/member/request/pliste_insert_request'
import {
  createInsertProdukt,
  createProdukt,
  createNbdExtra,
  createNbdProdukt
} from './preisliste_neu_logic_helper'

export default class TageskarteNeuMemcommonLogic {
  filiale?: Filiale
  extras: Produkt[] = []
  produkte: Produkt[] = []
  gewaehlteProdukte: Produkt[] = []
  produkteWaehlenVisible: boolean = false
  datumDialogVisible: boolean = false
  datum?: string
  kategorie: Kategorie = new Kategorie()
  preislisteAktivieren: boolean = true

  insertWorking: boolean = false
  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false

  init(filialeId: string, betriebId: string) {
    var filialeRequest = new IdRequest()
    filialeRequest.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      filialeRequest
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    var request = new IdRequest()
    request.id = betriebId

    Poster.postMember<RemoteResult<NeuePreislisteInfo>>(
      RestPaths.preislisteNeueInfo,
      request
    ).then((response) => {
      if (response.data.success) {
        let payload = response.data.payload

        payload.extras.forEach((e) => {
          var extra = createNbdExtra(e)
          this.extras.push(extra)
        })

        payload.produkte.forEach((p) => {
          var produkt = createNbdProdukt(p, this.extras)
          produkt.id = p.id
          produkt.bezeichnung = p.bezeichnung
          produkt.artikelNr = p.artikelNr
          produkt.standardpreis = p.standardpreis
          this.produkte.push(produkt)
        })
      }
    })
  }

  findProduktExtra(id: string) {
    return this.extras.find((element) => element.id == id)
  }

  produkteHinzufuegenAnzeigen() {
    this.produkteWaehlenVisible = true
  }

  produkteHinzufuegenAbbrechen() {
    this.produkteWaehlenVisible = false
    this.gewaehlteProdukte = []
  }

  produkteHinzufuegen() {
    this.gewaehlteProdukte.forEach((produkt) => {
      this.kategorie.produkte.push(createProdukt(produkt))
    })
    this.produkteWaehlenVisible = false
  }

  preislisteEinfuegen() {
    this.insertWorking = true
    this.insertFormDisabled = true
    this.insertError = false

    var request = new PlisteInsertRequest()
    request.tageskarte = true
    request.aktivieren = this.preislisteAktivieren
    request.gueltigVon = moment(this.datum, 'YYYY-MM-DD')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss')
    request.filialeId = this.filiale.id

    var reqKategorie = new ReqKategorie()
    reqKategorie.index = -1
    request.kategorien.push(reqKategorie)

    this.kategorie.produkte.forEach((prlProdukt, index) => {
      var reqProdukt = createInsertProdukt(prlProdukt, index)
      reqKategorie.produkte.push(reqProdukt)
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.preislisteEinfuegen, request)
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertCompleted = true
        } else {
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }
}
