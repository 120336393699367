
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import TerminSofortLogic from '@/logic/bestellung_neu/termin_sofort_logic'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class TerminSofort extends mixins(Calculator) {
  logic: TerminSofortLogic = null

  get verfuegbarVisible() {
    return this.logic.tisch == null
  }

  get buchenVisible() {
    return this.logic.tisch != null
  }

  verfuegbarkeitPruefen() {
    this.logic.verfuegbarkeitPruefen()
  }

  tischBuchen() {
    this.logic.tischBuchen()
  }

  mounted() {
    this.logic = new TerminSofortLogic()
    this.logic.init()
  }
}
