class NeuReservierungVorbestellung {
  public PRODUKTE = 'bestellung-neu-reservierung-vorbestellung-produkte'
  public ARTIKEL = 'bestellung-neu-reservierung-vorbestellung-artikel'
  public BESTAETIGEN = 'bestellung-neu-reservierung-vorbestellung-bestaetigen'
}

class NeuShopping {
  public PRODUKTE = 'bestellung-neu-shopping-produkte'
  public ARTIKEL = 'bestellung-neu-shopping-artikel'
}

class NeuShoppingLieferung {
  public PRODUKTE = 'bestellung-neu-shopping-lieferung-produkte'
  public ARTIKEL = 'bestellung-neu-shopping-lieferung-artikel'
}

class Neu {
  public HOME = 'home'
  public FILIALE = 'filiale-select'
  public TISCH_QR_BUCHEN = 'bestellung-neu-tisch-qr-buchen'
  public TERMIN_LIEFERUNG = 'bestellung-neu-termin-lieferung'
  public TERMIN_ABHOLUNG = 'bestellung-neu-termin-abholung'
  public TERMIN_RESERVIERUNG = 'bestellung-neu-termin-reservierung'
  public SOFORTBESTELLUNG_TISCH = 'bestellung-neu-sofortbestellung-tisch'
  public SOFORTBESTELLUNG_BESTELLART =
    'bestellung-neu-sofortbestellung-bestellart'
  public SHOPPING_LIEFERUNG = new NeuShoppingLieferung()
  public SHOPPING = new NeuShopping()
  public KUNDENDATEN = 'bestellung-neu-kundendaten'
  public KUNDENDATEN_RESERVIERUNG = 'bestellung-neu-kundendaten-reservierung'
  public KUNDENDATEN_LIEFERUNG = 'bestellung-neu-kundendaten-lieferung'
  public RESERVIERUNG_SELECT_VORBESTELLUNG = 'reservierung-select-vorbestellung'
  public RESERVIETUNG_VORBESTELLUNG = new NeuReservierungVorbestellung()
  public BESTAETIGEN = 'bestellung-neu-bestaetigen'
  public BESTAETIGEN_LIEFERUNG = 'bestellung-neu-bestaetigen-lieferung'
  public BESTAETIGT = 'bestellung-neu-bestaetigt'
  public WARENKORB = 'bestellung-neu-warenkorb'
  public WARENKORB_LIEFERUNG = 'bestellung-neu-warenkorb-lieferung'
  public BEZAHLUNG_BESTAETIGEN = 'bestellung-neu-bezahlung-bestaetigen'
  public BEZAHLUNG_WEITERLEITEN = 'bestellung-neu-bezahlung-weiterleiten'
  public VERZEHRART = 'verzehrart'
}

class BestellungReservierungBestellung {
  public PRODUKTE = 'bestellung-reservierung-bestellung-produkte'
  public ARTIKEL = 'bestellung-reservierung-bestellung-artikel'
  public BESTAETIGEN = 'bestellung-reservierung-bestellung-bestaetigen'
}

class BestellungNachbestellen {
  public PRODUKTE = 'bestellung-nachbestellen-produkte'
  public ARTIKEL = 'bestellung-nachbestellen-artikel'
  public BESTAETIGEN = 'bestellung-nachbestellen-bestaetigen'
}

class BestellungVorbestellung {
  public PRODUKTE = 'bestellung-vorbestellung-produkte'
  public ARTIKEL = 'bestellung-vorbestellung-artikel'
  public BESTAETIGEN = 'bestellung-vorbestellung-bestaetigen'
}

class Bestellung {
  public DETAILS = 'bestellung-details'
  public RESERVIERUNG_BESTELLUNG = new BestellungReservierungBestellung()
  public SERVICE_ANFORDERN = 'bestellung-service-anfordern'
  public BEZAHLEN = 'bestellung-bezahlen'
  public BEZAHLEN_BESTAETIGT = 'bestellung-bezahlen-bestaetigt'
  public NACHBESTELLEN = new BestellungNachbestellen()
  public VORBESTELLUNG = new BestellungVorbestellung()
  public PASSWORT_AENDERN = 'passwort-aendern-kunde'
  public FILIALE_INFO = 'filiale-info'
}

class System {
  public PORTAL = 'system-portal'
  public SETUP = 'system-setup'
  public ANFRAGEN = 'system-anfragen'
  public ACCOUNT = 'system-account'
  public SYSTEM_ACCOUNTS = 'system-system-accounts'
  public SYSTEM_ACCOUNT_NEU = 'system-system-account-neu'
  public SYSTEM_ACCOUNT_DETAILS = 'system-system-account-details'
  public SYSTEM_ACCOUNT_EDIT = 'system-system-account-edit'
  public ANFRAGE_DETAILS = 'system-anfrage-details'
  public AUSWERTUNG = 'system-auswertung'
  public BETRIEBE = 'system-betriebe'
  public BETRIEB = 'system-betrieb'
  public BETRIEB_NEU = 'system-betrieb-neu'
  public BETRIEB_EDIT = 'system-betrieb-edit'
  public FILIALEN = 'system-filialen'
  public FILIALE_NEU = 'system-filiale-neu'
  public FILIALE_EDIT = 'system-filiale-edit'
  public FILIALE_DETAILS = 'system-filiale-details'
  public MITARBEITER = 'system-mitarbeiter'
  public MITARBEITER_NEU = 'system-mitarbeiter-neu'
  public MITARBEITER_EDIT = 'system-mitarbeiter-edit'
  public MITARBEITER_DETAILS = 'system-mitarbeiter-details'
  public PRODUKTE = 'system-produkte'
  public PRODUKT_NEU = 'system-produkt-neu'
  public PRODUKT_EDIT = 'system-produkt-edit'
  public PRODUKT_DETAILS = 'system-produkt-details'
  public PRODUKT_EXTRAS = 'system-produkt-extras'
  public OEFFNUNGSZEITEN = 'system-oeffnungszeiten'
  public OEFFNUNGSZEITEN_FILIALE = 'system-oeffnungszeiten-filiale'
  public OEFFNUNGSZEIT_EDIT = 'system-oeffnungszeit-edit'
  public OEFFNUNGSZEIT_NEU = 'system-oeffnungszeit-neu'
  public OEFFNUNGSZEIT_DETAILS = 'system-oeffnungszeit-details'
  public OEFFNUNGSZEIT_AUSNAHMEN = 'system-oeffnungszeit-ausnahmen'
  public OEFFNUNGSZEIT_AUSNAHMEN_FILIALE =
    'system-oeffnungszeit-ausnahmen-filiale'
  public PREISLISTEN = 'system-preislisten'
  public PREISLISTEN_FILIALE = 'system-preislisten-filiale'
  public PREISLISTE_NEU = 'system-preisliste-neu'
  public PREISLISTE_DETAILS = 'system-preisliste-details'
  public PREISLISTE_EDIT = 'system-preisliste-edit'
  public KATEGORIEN = 'system-kategorien'
  public TISCHE = 'system-tische'
  public TISCHE_FILIALE = 'system-tische-filiale'
  public TISCH_EDIT = 'system-tisch-edit'
  public TISCH_DETAILS = 'system-tisch-details'
  public BASISKATEGORIEN = 'system-basiskategorien'
  public PRODUKTARTEN = 'system-produktarten'
  public INHALTSSTOFFE = 'system-inhaltsstoffe'
  public BESTELLVERWALTUNG = 'bestellverwaltung'
}

class BestellungPage {
  public DETAILS = 'bestellung-page-details'
  public SERVICE_ANFORDERN = 'bestellung-page-service-anfordern'
  public RESERVIERUNG = new BestellungPageReservierung()
  public NACHBESTELLEN = new BestellungPageNachbestellen()
  public VORBESTELLUNG = new BestellungPageVorbestellen()
  public BEZAHLEN = 'bestellung-page-bezahlen'
}

class BestellungPageReservierung {
  public PRODUKTE = 'bestellung-page-reservierung-produkte'
  public ARTIKEL = 'bestellung-page-reservierung-artikel'
  public BESTAETIGEN = 'bestellung-page-reservierung-bestaetigen'
}

class BestellungPageNachbestellen {
  public PRODUKTE = 'bestellung-page-nachbestellen-produkte'
  public ARTIKEL = 'bestellung-page-nachbestellen-artikel'
  public BESTAETIGEN = 'bestellung-page-nachbestellen-bestaetigen'
}

class BestellungPageVorbestellen {
  public PRODUKTE = 'bestellung-page-vorbestellen-produkte'
  public ARTIKEL = 'bestellung-page-vorbestellen-artikel'
  public BESTAETIGEN = 'bestellung-page-vorbestellen-bestaetigen'
}

class FilialePageArea {
  public TERMIN_ABHOLUNG = 'filiale-page-area-termin-abholung'
  public TERMIN_LIEFERUNG = 'filiale-page-area-termin-lieferung'
  public TERMIN_RESERVIERUNG = 'filiale-page-area-termin-reservierung'
  public VERZEHRART = 'filiale-page-area-verzehrart'
  public SOFORTBESTELLUNG_TISCH = 'filiale-page-area-sofortbestellung-tisch'
  public SOFORTBESTELLUNG_BESTELLART =
    'filiale-page-area-sofortbestellung-bestellart'
  public SHOPPING = new FilialePageAreaShopping()
  public SHOPPING_LIEFERUNG = new FilialePageAreaShoppingLieferung()
  public WARENKORB = 'filiale-page-area-warenkorb'
  public WARENKORB_LIEFERUNG = 'filiale-page-area-warenkorb-lieferung'
  public BESTAETIGEN = 'filiale-page-area-bestaetigen'
  public BESTAETIGEN_LIEFERUNG = 'filiale-page-area-bestaetigen-lieferung'
  public KUNDENDATEN = 'filiale-page-area-kundendaten'
  public KUNDENDATEN_RESERVIERUNG = 'filiale-page-area-kundendaten-reservierung'
  public KUNDENDATEN_LIEFERUNG = 'filiale-page-area-kundendaten-lieferung'
  public STANDORT_LIEFERUNG = 'filiale-page-area-standort-lieferung'
  public ABHOLUNG_STARTEN = 'filiale-page-area-abholung-starten'
  public ABHOLUNG_STARTEN_TISCH = 'filiale-page-area-abholung-starten-tisch'
  public TRINKGELD = 'filiale-page-area-trinkgeld'
}

class FilialePageAreaShopping {
  public PRODUKTE = 'filiale-page-area-shopping-produkte'
  public ARTIKEL = 'filiale-page-area-shopping-artikel'
}

class FilialePageAreaShoppingLieferung {
  public PRODUKTE = 'filiale-page-area-shopping-lieferung-produkte'
  public ARTIKEL = 'filiale-page-area-shopping-lieferung-artikel'
}

class Member {
  public PORTAL = 'mitarbeiter-portal'
  public EIGENE_DATEN = 'mitarbeiter-eigene-daten'
  public PASSWORT_AENDERN = 'mitarbeiter-passwort-aendern'
  public KUECHE = 'mitarbeiter-kueche'
  public SERVICE = 'mitarbeiter-service'
  public BESTELLUNGEN = 'mitarbeiter-bestellungen'
  public BESTELLLISTE = 'mitarbeiter-bestellliste'
  public BESTELLUNG = 'mitarbeiter-bestellung'
  public RESERVIERUNGEN = 'mitarbeiter-reservierungen'
  public AUSWERTUNGEN = 'mitarbeiter-auswertungen'
  public FILIALEN = 'mitarbeiter-filialen'
  public FILIALE_NEU = 'mitarbeiter-filiale-neu'
  public FILIALE_DETAILS = 'mitarbeiter-filiale-details'
  public FILIALE_EDIT = 'mitarbeiter-filiale-edit'
  public FILIALE_LIEFERGEBIET_ZUORDNEN =
    'mitarbeiter-filiale-liefergebiet-zuordnen'
  public PRODUKTE = 'mitarbeiter-produkte'
  public PRODUKT_NEU = 'mitarbeiter-produkt-neu'
  public PRODUKT_DETAILS = 'mitarbeiter-produkt-details'
  public PRODUKT_EDIT = 'mitarbeiter-produkt-edit'
  public PRODUKT_EXTRAS = 'mitarbeiter-produkt-extras'
  public VERFUEGBARKEIT = 'mitarbeiter-verfuegbarkeit'
  public BASISKATEGORIEN = 'mitarbeiter-basiskategorien'
  public PRODUKTARTEN = 'mitarbeiter-produktarten'
  public INHALTSSTOFFE = 'mitarbeiter-produkt-inhaltsstoffe'
  public PREISLISTEN = 'mitarbeiter-preislisten'
  public PREISLISTEN_FILIALE = 'mitarbeiter-preislisten-filiale'
  public PREISLISTE_DETAILS = 'mitarbeiter-preisliste-details'
  public PREISLISTE_NEU = 'mitarbeiter-preisliste-neu'
  public PREISLISTE_FILIALE_NEU = 'mitarbeiter-preisliste-filiale-neu'
  public PREISLISTE_EDIT = 'mitarbeiter-preisliste-edit'
  public TAGESKARTE_NEU = 'mitarbeiter-tageskarte-neu'
  public TAGESKARTE_EDIT = 'mitarbeiter-tageskarte-edit'
  public TAGESKARTE_DETAILS = 'mitarbeiter-tageskarte-details'
  public KATEGORIEN = 'mitarbeiter-kategorien'
  public OEFFNUNGSZEITEN = 'mitarbeiter-oeffnungszeiten'
  public OEFFNUNGSZEITEN_FILIALE = 'mitarbeiter-oeffnungszeiten-filiale'
  public OEFFNUNGSZEIT_FILIALE = 'mitarbeiter-oeffnungszeit-filiale'
  public OEFFNUNGSZEIT_EDIT = 'mitarbeiter-oeffnungszeit-edit'
  public OEFFNUNGSZEIT_NEU = 'mitarbeiter-oeffnungszeit-neu'
  public OEFFNUNGSZEIT_DETAILS = 'mitarbeiter-oeffnungszeit-details'
  public OEFFNUNGSZEIT_AUSNAHMEN = 'mitarbeiter-oeffnungszeit-ausnahmen'
  public OEFFNUNGSZEIT_AUSNAHMEN_FILIALE =
    'mitarbeiter-oeffnungszeit-ausnahmen-filiale'
  public TISCHE = 'mitarbeiter-tische'
  public TISCHE_FILIALE = 'mitarbeiter-tische-filiale'
  public TISCH_DETAILS = 'mitarbeiter-tisch-details'
  public TISCH_EDIT = 'mitarbeiter-tisch-edit'
  public MITARBEITER = 'mitarbeiter-mitarbeiter'
  public MITARBEITER_NEU = 'mitarbeiter-mitarbeiter-neu'
  public MITARBEITER_DETAILS = 'mitarbeiter-mitarbeiter-details'
  public MITARBEITER_EDIT = 'mitarbeiter-mitarbeiter-edit'
  public PDUKT_SHORTCUTS = 'mitarbeiter-pdukt-shortcuts'
  public FILIALE_SELECT = 'mitarbeiter-filiale-select'
  public LIEFERUNGEN = 'mitarbeiter-lieferungen'
  public STANDARDPREISE = 'mitarbeiter-standardpreise'
}

export default class RouteNames {
  public static NEU = new Neu()
  public static BESTELLUNGEN = 'bestellungen'
  public static ABGESCHLOSSENE_BESTELLUNGEN = 'abgeschlossene_bestellungen'
  public static REGISTRIEREN = 'registrieren'
  public static BETRIEB_ANFRAGEN = 'betrieb-anfragen'
  public static BESTELLUNG = new Bestellung()
  public static BESTELLUNG_PAGE = new BestellungPage()
  public static MEMBER = new Member()
  public static SYSTEM = new System()
  public static FILIALE_PAGE_AREA = new FilialePageArea()
  public static PASSWORT_VERGESSEN = 'passwort-vergessen'
  public static PASSWORT_AENDERN = 'passwort-aendern'
  public static LOGIN_MITARBEITER = 'login-mitarbeiter'
  public static LOGIN_SYSTEM = 'login-system'
  public static DATENSCHUTZ = 'datenschutz'
  public static IMPRESSUM = 'impressum'
  public static AGB = 'agb'
  public static AGB_RESTAURANTS = 'agb-restaurants'
  public static MEIN_ACCOUNT = 'mein-account'
  public static FILIALE_PAGE = 'filiale-page'
}
