import BstlArtikel from '@/entities/common/bstl_artikel'
import { ZeitraumData } from '@/entities/member/request/oeffnungsz_insert_request'
import moment from 'moment'

export default class Formatter {
  static formatiereDatumUhrzeit(value: string): string {
    if (value == null) return null
    return moment(value)
      .add(moment().utcOffset(), 'minutes')
      .format('DD.MM.yyyy HH:mm')
  }

  static formatiereUhrzeit(value: string): string {
    if (value == null) return null
    return moment(value, 'HH:mm[:ss]').format('HH:mm')
  }

  static formatiereDatum(value: string): string {
    if (value == null) return null
    return moment(value).format('DD.MM.yyyy')
  }

  static formatiereWaehrung(value: number): string {
    if (!value) value = 0.0
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(value)
  }

  static berechneZeitraum(value): Zeitraum {
    var zeitraum = moment.duration(moment().diff(moment(value)))

    var invert: number = 1
    if (zeitraum.asMilliseconds() < 0) {
      invert = -1
    }

    var days = zeitraum.days() * invert
    var hours = zeitraum.hours() * invert
    var minutes = zeitraum.minutes() * invert
    var seconds = zeitraum.seconds() * invert
    var result =
      (invert == 1 ? '' : 'in ') +
      (days == 0 ? '' : days.toString() + ' Tage, ') +
      (hours == 0 ? '' : hours.toString() + ' Std. ') +
      minutes.toString() +
      ' Min. ' +
      seconds.toString() +
      ' Sek. '

    var res: Zeitraum = {
      inverse: invert == 1 ? false : true,
      stringValue: result,
    }

    return res
  }

  static berechnePreisListe(artikelliste: BstlArtikel[]) {
    if (artikelliste == null) return 0.0
    let result = 0.0
    artikelliste.forEach((element) => {
      result += this.berechnePreis(element)
    })
    return result
  }

  static berechnePreis(artikel: BstlArtikel) {
    if (artikel == null || artikel.produkt == null) return null
    let preis = artikel.produkt.preis
    if (!preis) preis = 0.0
    if (artikel.produkt.extras) {
      artikel.produkt.extras.forEach((extra) => {
        preis += extra.preis
      })
    }

    if (artikel.produkt.auswahlen) {
      artikel.produkt.auswahlen.forEach((auswahl) => {
        preis += auswahl.option.preis
      })
    }

    if (artikel.produkt.subprodukte) {
      artikel.produkt.subprodukte.forEach((subprodukt) => {
        preis += subprodukt.produkt.preis

        subprodukt.produkt.extras.forEach((extra) => {
          preis += extra.preis
        })

        subprodukt.produkt.auswahlen.forEach((auswahl) => {
          preis += auswahl.option.preis
        })
      })
    }

    return preis * artikel.anzahl
  }
}

type Zeitraum = {
  inverse: boolean
  stringValue: string
}

export { Zeitraum }
