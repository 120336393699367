
import Component, { mixins } from 'vue-class-component'
import Calculator from './mixins/Calculator.vue'
import FilialePageLogic from '@/logic/filiale_page_logic'
import RestPaths from '@/util/rest_paths'
import CookieLaw from 'vue-cookie-law'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component({ components: { CookieLaw } })
export default class FilialePage extends mixins(Calculator) {
  drawer: boolean = false
  logic: FilialePageLogic = null
  websocket = null

  computeBild() {
    if (this.logic.filiale.bildId == null) return '/assets/no_image.png'
    return (
      RestPaths.imageBasePath +
      this.logic.filiale.logoKleinId +
      '_logo_klein.jpg'
    )
  }

  computeColor(value: number) {
    return this.convertToHex(value)
  }

  convertToHex(value: number) {
    return '#' + (value & 0x00ffffff).toString(16).padStart(6, '0')
  }

  mounted() {
    this.logic = new FilialePageLogic()
    this.logic.init(this.$route.params.urlpath)
    this.websocket = new WebSocket(RestPaths.urlBaseWs + 'user') 
  }

  navBestellungen() {
    router.push({ name: RouteNames.BESTELLUNGEN })
  }

  navAgb() {
    router.push({ name: RouteNames.AGB })
  }

  navImpressum() {
    router.push({ name: RouteNames.IMPRESSUM })
  }

  navDatenschutz() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }
}
