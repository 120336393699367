
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class NichtGueltig extends Vue {
  zurueckZurStartseite() {
    this.$router.push({ name: RouteNames.FILIALE_PAGE })
  }
}
