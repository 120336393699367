import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Terminart } from '@/entities/common/enums'
import BestellungFilialeList from '@/entities/common/lists/bestellung_filiale_list'
import Location from '@/entities/common/location'
import RemoteResult from '@/entities/common/remote_result'
import FilialeSearchRequest from '@/entities/common/request/filiale_search_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import store from '@/store'
import StoreOps from '@/util/store_ops'
import router from '@/router'
import RouteNames from '@/util/route_names'
import BPBeginnenRequest from '@/entities/common/pending/bp_beginnen_request'

export default class FilialeSelectLogic {
  reactivefilialeList: BestellungFiliale[] = []
  currentLocation: Location = null
  fountLocations: Location[] = []
  loadingCities: boolean = false
  _search: string = null
  _cityString: number = null

  get search() {
    return this._search
  }

  set search(value: string) {
    this._search = value
    this.searchByName(value)
  }

  get cityString() {
    return this._cityString
  }

  set cityString(value: number) {
    this._cityString = value
    this.searchNearest(value)
  }

  searchNearestByLocation() {
    var request = new FilialeSearchRequest()
    request.locationId = this.currentLocation.id

    Poster.postUser<RemoteResult<BestellungFilialeList>>(
      RestPaths.filialeListeBestellungSuche,
      request
    ).then((response) => {
      this.reactivefilialeList = response.data.payload.list
    })
  }

  searchNearest(id: number) {
    var request = new FilialeSearchRequest()
    request.locationId = id

    Poster.postMember<RemoteResult<BestellungFilialeList>>(
      RestPaths.filialeListeBestellungSuche,
      request
    )
      .then((response) => {
        this.reactivefilialeList = response.data.payload.list
      })
      .finally(() => {
        return null
      })
    return null
  }

  filialseiteAnzeigen(filiale: BestellungFiliale) {
    var updateFiliale = {
      id: null,
      filiale: filiale,
      terminart: Terminart.LIEFERUNG,
    }
    store.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
    router.push({
      name: RouteNames.FILIALE_PAGE,
      params: { urlpath: filiale.urlPath },
    })
  }

  lieferung(filiale: BestellungFiliale) {
    var request = new BPBeginnenRequest()
    request.filialeId = filiale.filialeId
    request.terminart = Terminart.LIEFERUNG
    request.identifier = store.state.user.identifier
    request.liefergebietId = filiale.lieferungSettings.liefergebietId

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: filiale,
        terminart: Terminart.LIEFERUNG,
      }
      store.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      if (!store.state.standort) {
        router.push({
          name: RouteNames.FILIALE_PAGE_AREA.STANDORT_LIEFERUNG,
          params: { urlpath: filiale.urlPath },
        })
      } else {
        router.push({
          name: RouteNames.FILIALE_PAGE_AREA.TERMIN_LIEFERUNG,
          params: { urlpath: filiale.urlPath },
        })
      }
    })
  }

  abholung(filiale: BestellungFiliale) {
    var request = new BPBeginnenRequest()
    request.filialeId = filiale.filialeId
    request.terminart = Terminart.ABHOLUNG
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: filiale,
        terminart: Terminart.ABHOLUNG,
      }
      store.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      if (filiale.abholungSettings.supportsVorbestellung) {
        router.push({
          name: RouteNames.FILIALE_PAGE_AREA.TERMIN_ABHOLUNG,
          params: { urlpath: filiale.urlPath },
        })
      } else {
        router.push({
          name: RouteNames.FILIALE_PAGE_AREA.VERZEHRART,
          params: { urlpath: filiale.urlPath },
        })
      }
    })
  }

  reservierung(filiale: BestellungFiliale) {
    var request = new BPBeginnenRequest()
    request.filialeId = filiale.filialeId
    request.terminart = Terminart.RESERVIERUNG
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: filiale,
        terminart: Terminart.RESERVIERUNG,
      }
      store.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      router.push({
        name: RouteNames.FILIALE_PAGE_AREA.TERMIN_RESERVIERUNG,
        params: { urlpath: filiale.urlPath },
      })
    })
  }

  sofortbestellung(filiale: BestellungFiliale) {
    var request = new BPBeginnenRequest()
    request.filialeId = filiale.filialeId
    request.terminart = Terminart.SOFORTBESTELLUNG
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingBeginnen,
      request
    ).then((response) => {
      var updateFiliale = {
        id: response.data.payload,
        filiale: filiale,
        terminart: Terminart.SOFORTBESTELLUNG,
      }

      store.commit(StoreOps.BESTELLUNG_STARTEN, updateFiliale)
      router.push({
        name: RouteNames.FILIALE_PAGE_AREA.SOFORTBESTELLUNG_TISCH,
        params: { urlpath: filiale.urlPath },
      })
    })
  }

  berechneBildId(filiale: BestellungFiliale) {
    if (filiale.bildId == null) return '/assets/no_image.png'
    return RestPaths.urlBase + RestPaths.imageImage + '/' + filiale.bildId
  }

  formatDistance(meters?: number) {
    if (!meters) return ''
    if (meters >= 1000) {
      return Math.round(meters / 1000.0) + ' km'
    } else if (meters >= 100) {
      return Math.round(meters) + ' m'
    } else {
      return meters.toFixed(1) + ' m'
    }
  }

  berechneGeoeffnet(filiale: BestellungFiliale) {
    if (filiale == null) return
    var result = false
    if (filiale.zurZeitGeoeffnet) return true
    filiale.oeffnungszeitListe.forEach((element) => {
      var datum = moment(element.datum)
      var isToday = datum.isSame(moment(), 'day')
      if (isToday) {
        if (!element.geoeffnet) {
          result = false
          return
        } else {
          result = true
          return
        }
      }
    })
    return result
  }

  init() {
    var request = new FilialeSearchRequest()
    request.requestType = FilialeSearchRequest.STANDORT_GENAU
    request.latitude = store.state.standort.latitude
    request.longitude = store.state.standort.longitude

    Poster.postUser<RemoteResult<BestellungFilialeList>>(
      RestPaths.filialeListeBestellungSuche,
      request
    ).then((response) => {
      this.reactivefilialeList = response.data.payload.list
    })
  }

  searchByName(value: string) {
    if (value.length >= 3) {
      this.loadingCities = true

      fetch(RestPaths.urlBase + RestPaths.locationByQuery + '?q=' + value)
        .then((response) => response.json())
        .then((data) => {
          this.fountLocations = data.payload.list
          this.loadingCities = false
        })
        .catch(() => {})
    }
  }

  detailsAnzeigen(filiale: BestellungFiliale) {
    router.push({
      name: RouteNames.BESTELLUNG.FILIALE_INFO,
      params: { filialeId: filiale.filialeId },
    })
  }
}
