import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Ausverkauft from '@/entities/member/ausverkauft'
import FilialeSimple from '@/entities/member/filiale_simple'
import Pdukt from '@/entities/member/pdukt'
import ProduktAusverkauftInsertRequest from '@/entities/member/request/produkt_ausverkauft_insert_request'
import ProduktAusverkauftRequest from '@/entities/member/request/produkt_ausverkauft_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import AusverkaufList from '@/entities/member/lists/ausverkauft_list'
import PduktRequest from '@/entities/member/request/pdukt_request'
import store from '@/store'
import PduktList from '@/entities/member/lists/pdukt_list'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import vuexStore from '@/store'
import moment from 'moment'

export default class AusverkauftLogic {
  produkte?: Pdukt[] = []
  _datum?: string = null
  filialen?: FilialeSimple[] = []
  _filiale?: string = ''
  ausverkaufte?: Ausverkauft[] = []
  selectedProdukte: Pdukt[] = []
  availableProdukte: Pdukt[] = []
  neuDialogOpen: boolean = false

  get filiale(): string {
    return this._filiale
  }

  set filiale(value: string) {
    this._filiale = value
    this.updateVerfuegbarkeit()
  }

  get datum(): string {
    return this._datum
  }

  set datum(value: string) {
    this._datum = value
    this.updateVerfuegbarkeit()
  }

  ausverkauftLoeschen(a: Ausverkauft) {
    var request = new IdRequest()
    request.id = a.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.artikelAusverkauftDelete,
      request
    ).then((response) => {
      if (response.data.success) {
        this.ausverkaufte.splice(this.ausverkaufte.indexOf(a), 1)
        this.availableProdukte.push(
          this.produkte.find((x) => x.id == a.produktId)
        )
      }
    })
  }

  init() {
    var request = new PduktRequest()
    request.betriebId = store.state.user.betriebId
    request.extraZusatz = false

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      this.produkte = response.data.payload.list
      this.availableProdukte = response.data.payload.list
    })

    var fRequest = new IdRequest()
    fRequest.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      fRequest
    ).then((response) => {
      this.filialen = response.data.payload.list
    })

    this.filiale = vuexStore.state.user.filiale.id
    this.datum = moment().format('YYYY-MM-DD')
    this.updateVerfuegbarkeit()
  }

  ausverkauftEinfuegen() {
    var request = new ProduktAusverkauftInsertRequest()
    request.datum = this.datum
    request.filialeId = this.filiale

    this.selectedProdukte.forEach((element) => {
      request.pduktList.push(element.id)
    })

    Poster.postMember<RemoteResult<AusverkaufList>>(
      RestPaths.artikelAusverkauftInsert,
      request
    ).then((response) => {
      if (response.data.success) {
        this.availableProdukte = this.availableProdukte.filter((el) => {
          return this.selectedProdukte.indexOf(el) < 0
        })
        this.selectedProdukte = []

        this.neuDialogOpen = false
        this.updateVerfuegbarkeit()
      }
    })
  }

  updateVerfuegbarkeit() {
    var request = new ProduktAusverkauftRequest()
    request.datum = this.datum
    request.filialeId = this.filiale

    Poster.postMember<RemoteResult<AusverkaufList>>(
      RestPaths.artikelAusverkauftList,
      request
    ).then((response) => {
      this.availableProdukte = this.produkte
      this.ausverkaufte = response.data.payload.list
      this.availableProdukte = this.availableProdukte.filter((el) => {
        return this.ausverkaufte.find((x) => x.produktId == el.id) == null
      })
    })
  }
}
