
import ArtikelAktuell, { ProduktExtra } from '@/entities/common/artikel_aktuell'
import { Produkt } from '@/entities/common/artikel_aktuell'
import Component, { mixins } from 'vue-class-component'
import { Emit, Model, ModelSync, Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class ExtrasContainer extends mixins(Calculator) {
  //selectedExtras: ProduktExtra[]
  @Prop() artikelAktuell: ArtikelAktuell
  @Prop() produkt: Produkt
  @Prop() simpleMode: boolean = false
  expanded: boolean = false

  switchExpander() {
    this.expanded = !this.expanded
  }

  berechneExtra(extra: ProduktExtra) {
    if (this.simpleMode) {
      return extra.base.preis
    } else {
      if (extra.base.hasArten) {
        var variante = this.artikelAktuell.gewaehleVariante
        if (!variante) return 0
        var result: number = 0
        extra.base.arten.forEach((v) => {
          if (variante.pduktArtId === v.pduktArtId) {
            result = v.preis
            return
          }
        })
        return result
      }
    }
    return extra.base.preis
  }

  @Emit('zeigeExtra')
  zeigeExtra(extra: ProduktExtra) {}

  get selectedExtras(): ProduktExtra[] {
    if (this.simpleMode) {
      return this.produkt.selectedExtras
    } else {
      return this.artikelAktuell.produkt.selectedExtras
    }
  }

  set selectedExtras(value) {
    if (this.simpleMode) {
      this.produkt.selectedExtras = value
    } else {
      this.artikelAktuell.produkt.selectedExtras = value
    }
  }

  get firstExtras(): ProduktExtra[] {
    if (this.simpleMode) {
      if (
        this.produkt &&
        this.produkt.extras &&
        this.produkt.extras.length > 3
      ) {
        return this.produkt.extras.slice(0, 3)
      }
      return this.produkt.extras
    } else {
      if (
        this.artikelAktuell.produkt &&
        this.artikelAktuell.produkt.extras &&
        this.artikelAktuell.produkt.extras.length > 3
      ) {
        return this.artikelAktuell.produkt.extras.slice(0, 3)
      }
      return this.artikelAktuell.produkt.extras
    }
  }

  get extraExtras(): ProduktExtra[] {
    if (this.simpleMode) {
      if (
        this.produkt &&
        this.produkt.extras &&
        this.produkt.extras.length > 3
      ) {
        return this.produkt.extras.slice(3, this.produkt.extras.length)
      }
    } else {
      if (
        this.artikelAktuell.produkt &&
        this.artikelAktuell.produkt.extras &&
        this.artikelAktuell.produkt.extras.length > 3
      ) {
        return this.artikelAktuell.produkt.extras.slice(
          3,
          this.artikelAktuell.produkt.extras.length
        )
      }
    }
    return []
  }

  get extraExtrasVisible(): boolean {
    if (this.simpleMode) {
      return this.produkt.extras && this.produkt.extras.length > 3
    } else {
      return (
        this.artikelAktuell.produkt.extras &&
        this.artikelAktuell.produkt.extras.length > 3
      )
    }
  }
}
