import { AbholzeitType, Terminart } from '@/entities/common/enums'
import BestellungData from '@/util/bestellung_data'
import NachbestellungData from '@/util/nachbestellung-data'
import Nbd from '@/util/neue-bestellung-data'
import moment from 'moment'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import VuexPersist from 'vuex-persist'
import { v4 as uuidv4 } from 'uuid'
import LoginResult from '@/entities/common/login_result'
import UserInfo from './user_info'
import StoreOps from '@/util/store_ops'
import SocketMessage from '@/util/socket_message'
import BstlArtikel from '@/entities/common/bstl_artikel'
import Bestellung from '@/entities/common/bestellung'
import FilialeSimple from '@/entities/member/filiale_simple'
import TischMitFiliale from '@/entities/common/tisch_mit_filiale'
import { LieferungSettings } from '@/entities/common/bestellung_filiale'
import BestellungPending from '@/entities/common/bestellung_pending'

interface MangerooState {
  // neueBestellungData: Nbd
  nachbestellungData: NachbestellungData
  bestellung: BestellungData
  incomingMessage: SocketMessage
  isNeueBestellung: boolean
  user: UserInfo
  standort: Standort
  bestellungPending: BestellungPending
}

class Standort {
  bezeichnung?: string
  land?: string
  strasse?: string
  plz?: string
  ort?: string
  latitude?: number
  longitude?: number
}

Vue.use(Vuex)

var vuexStore: Store<MangerooState> = null

var cookiesEnabled = navigator.cookieEnabled

if (cookiesEnabled) {
  var vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
  })

  vuexStore = new Vuex.Store<MangerooState>({
    plugins: [vuexLocalStorage.plugin],
    state: (): MangerooState => ({
      //neueBestellungData: new Nbd(),
      nachbestellungData: new NachbestellungData(),
      bestellung: new BestellungData(),
      incomingMessage: null,
      isNeueBestellung: false,
      user: new UserInfo(),
      standort: new Standort(),
      bestellungPending: new BestellungPending(),
    }),
    mutations: {
      [StoreOps.FILIALE_SELECT_MEMBER](
        state: MangerooState,
        data: FilialeSimple
      ) {
        console.log('filiale_select')
        state.user.filiale = data
      },
      [StoreOps.LOGIN_KUNDE](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true
        state.user.vorname = data.vorname
        state.user.nachname = data.nachname
        state.user.username = data.username
        state.user.role = data.role
        state.user.key = data.key
        state.user.betriebId = data.betriebId
        state.user.admin = data.admin
        state.user.expires = data.expires
      },
      [StoreOps.LOGIN_MEMBER](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true
        state.user.vorname = data.vorname
        state.user.nachname = data.nachname
        state.user.username = data.username
        state.user.role = data.role
        state.user.key = data.key
        state.user.betriebId = data.betriebId
        state.user.admin = data.admin
        state.user.expires = data.expires
      },
      [StoreOps.LOGOUT](state: MangerooState) {
        state.user.loggedIn = false
        state.user.vorname = null
        state.user.nachname = null
        state.user.username = null
        state.user.role = null
        state.user.key = null
        state.user.betriebId = null
        state.user.admin = false
        state.user.expires = null
      },
      [StoreOps.POST_INCOMING_MESSAGE](
        state: MangerooState,
        payload: SocketMessage
      ) {
        state.incomingMessage = payload
      },
      [StoreOps.UPDATE_NEU_LIEFERUNG_TERMIN](state: MangerooState, payload) {
        var type = payload.type
        state.bestellungPending.lieferung.abholzeitType = type
        if (type == AbholzeitType.UHRZEIT) {
          state.bestellungPending.lieferung.gewaehlteUhrzeit = payload.uhrzeit
        }
      },
      [StoreOps.UPDATE_NEU_ABHOLUNG_TERMIN](state: MangerooState, payload) {
        var type = payload.type
        state.bestellungPending.abholung.abholzeitType = type
        if (type == AbholzeitType.UHRZEIT) {
          state.bestellungPending.abholung.gewaehlteUhrzeit = payload.uhrzeit
        }
      },
      [StoreOps.UPDATE_NBD_ABH_VERZEHRART](state: MangerooState, payload) {
        state.bestellungPending.abholung.verzehrart = payload
      },
      [StoreOps.BESTELLUNG_STARTEN](state: MangerooState, payload) {
        state.bestellungPending.id = payload.id
        state.bestellungPending.terminart = payload.terminart
        state.isNeueBestellung = true

        if (payload.terminart == Terminart.ABHOLUNG) {
          if (
            !state.bestellungPending.filiale.abholungSettings
              .supportsVorbestellung
          ) {
            state.bestellungPending.abholung.abholzeitType =
              AbholzeitType.BALDEST
          }
        }

        if (payload.terminart == Terminart.LIEFERUNG) {
          state.bestellungPending.lieferung.kundeStrasse =
            state.standort.strasse
          state.bestellungPending.lieferung.kundePlz = state.standort.plz
          state.bestellungPending.lieferung.kundeOrt = state.standort.ort
          if (payload.liefergebiet) {
            state.bestellungPending.lieferung.liefergebietId =
              payload.liefergebiet.liefergebietId
            state.bestellungPending.lieferung.lieferkosten =
              payload.liefergebiet.lieferkosten
          }
        }
      },
      [StoreOps.BESTELLUNG_DIREKT_STARTEN](state: MangerooState, payload) {
        state.bestellungPending = new BestellungPending()
        state.bestellungPending.id = payload.bestellungId
        state.bestellungPending.terminart = Terminart.ABHOLUNG
        state.bestellungPending.abholung.abholzeitType = AbholzeitType.BALDEST
        state.bestellungPending.filiale = payload.filiale
        state.bestellungPending.abholung.direktabholung = true
        state.isNeueBestellung = true
      },
      [StoreOps.BESTELLUNG_DIREKT_STARTEN_TISCH](
        state: MangerooState,
        payload
      ) {
        state.bestellungPending.id = payload.bestellungId
        state.bestellungPending.terminart = Terminart.ABHOLUNG
        state.bestellungPending.abholung.tischId = payload.tisch
        state.bestellungPending.abholung.tischBezeichnung =
          payload.tisch.tischnummer
        state.bestellungPending.abholung.abholzeitType = AbholzeitType.BALDEST
        state.bestellungPending.filiale = payload.filiale
        state.bestellungPending.abholung.direktabholung = true
        state.isNeueBestellung = true
      },
      [StoreOps.REMOVE_ARTIKEL_BESTELLUNG_NEU](
        state: MangerooState,
        payload: BstlArtikel
      ) {
        var index = state.bestellungPending.artikelliste.findIndex((x) => {
          x.id === payload.id
        })
        if (index > -1) {
          state.bestellungPending.artikelliste.splice(index, 1)
        }
      },
      [StoreOps.BEGINNE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },
      [StoreOps.BEGINNE_VORBESTELLUNG](
        state: MangerooState,
        payload: Bestellung
      ) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },
      [StoreOps.BEGINNE_NACHBESTELLUNG](
        state: MangerooState,
        payload: Bestellung
      ) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },
      [StoreOps.CLEAR_NEUE_BESTELLUNG](state: MangerooState) {
        state.bestellungPending = new BestellungPending()
        state.isNeueBestellung = false
      },
      [StoreOps.UPDATE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung.bestellung = payload
      },
      [StoreOps.UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG](
        state: MangerooState,
        payload
      ) {
        state.bestellung.artikelliste = payload
      },
      [StoreOps.UPDATE_IDENTIFIER](state: MangerooState, payload: string) {
        state.user.identifier = payload
      },
      [StoreOps.UPDATE_NBD_ARTIKELLISTE](
        state: MangerooState,
        payload: BstlArtikel[]
      ) {
        state.bestellungPending.artikelliste = payload
      },
      [StoreOps.UPDATE_NBD_RESERVIERUNG_TERMIN](state: MangerooState, payload) {
        const data = state.bestellungPending.reservierung
        data.anzahlPersonen = payload.personen
        //data.datum = payload.datum
        //data.tisch = payload.tisch
        data.zeitVon = payload.uhrzeitVon
        data.zeitBis = payload.uhrzeitBis
      },
      [StoreOps.UPDATE_NBD_RESERV_KUNDE](
        state: MangerooState,
        payload: string
      ) {
        //state.bestellungPending.reservierung.datum = payload
      },
      [StoreOps.UPDATE_NBD_RESERV_KUNDE](state: MangerooState, payload: any) {
        var data = state.bestellungPending.reservierung
        data.kundeNachname = payload.kundeNachname
        data.kundeVorname = payload.kundeVorname
        data.kundeTelefon = payload.kundeTelefon
        data.kundeEmail = payload.kundeEmail
      },
      [StoreOps.UPDATE_NBD_LIEFERUNG_KUNDE](
        state: MangerooState,
        payload: any
      ) {
        var data = state.bestellungPending.lieferung
        data.kundeNachname = payload.kundeNachname
        data.kundeVorname = payload.kundeVorname
        data.kundeTelefon = payload.kundeTelefon
        data.kundeEmail = payload.kundeEmail
        data.kundeStrasse = payload.kundeStrasse
        data.kundePlz = payload.kundePlt
        data.kundeOrt = payload.kundeOrt
        data.kundeAdresszusatz = payload.kundeAdresszusatz
      },
      [StoreOps.UPDATE_NBD_ABH_KUNDE](state: MangerooState, payload) {
        state.bestellungPending.abholung.kundeNachname = payload.kundeNachname
        state.bestellungPending.abholung.kundeVorname = payload.kundeVorname
        state.bestellungPending.abholung.kundeTelefon = payload.kundeTelefon
        state.bestellungPending.abholung.kundeEmail = payload.kundeEmail
      },
      [StoreOps.UPDATE_NBD_SOF_TISCH](state: MangerooState, payload) {
        state.bestellungPending.sofort.tischId = payload.id
      },
      [StoreOps.UPDATE_QR_CODE_TISCH](state: MangerooState, payload) {
        state.bestellungPending.id = payload.id
        state.bestellungPending.terminart = Terminart.SOFORTBESTELLUNG
        state.bestellungPending.filiale = payload.filialeTisch.filiale
        // state.bestellungPending.sofort.tisch =
        // payload.filialeTisch.tisch
      },
      [StoreOps.UPDATE_STANDORT](state: MangerooState, payload) {
        state.standort.bezeichnung = payload.bezeichnung
        state.standort.latitude = payload.latitude
        state.standort.longitude = payload.longitude
        state.standort.land = payload.land
        state.standort.plz = payload.plz
        state.standort.ort = payload.ort
        state.standort.strasse = payload.strasse
      },
      [StoreOps.FILIALE_SELECT](state: MangerooState, payload) {
        state.bestellungPending.filiale = payload
      },
      [StoreOps.UPDATE_LIEFERGEBIET](state: MangerooState, payload) {
        state.bestellungPending.lieferung.liefergebietId = payload.id
        state.bestellungPending.filiale.lieferungSettings =
          new LieferungSettings()
        state.bestellungPending.filiale.lieferungSettings.lieferkosten =
          payload.lieferkosten
        state.bestellungPending.filiale.lieferungSettings.mindestbestellwert =
          payload.mindestbestellwert
        state.bestellungPending.filiale.lieferungSettings.kostenlosAb =
          payload.kostenlosAb
      },
      [StoreOps.UPDATE_NEUBESTELLUNG_DATA](
        state: MangerooState,
        payload: BestellungPending
      ) {
        state.bestellungPending = payload
      },
      [StoreOps.ARTIKEL_HINZUFUEGEN](
        state: MangerooState,
        payload: BstlArtikel
      ) {
        state.bestellungPending.artikelliste.push(payload)
      },
      [StoreOps.TRINKGELD_FESTLEGEN](state: MangerooState, payload){
        state.bestellungPending.trinkgeldType = payload.type
        state.bestellungPending.trinkgeld = payload.value
      }
    },
  })
}

export default vuexStore
