import DateTimeCapsule from '@/entities/common/data_time_capsule'
import RemoteResult from '@/entities/common/remote_result'
import CheckLocationRequest from '@/entities/common/request/check_location_request'
import CheckLocationResponse from '@/entities/common/request/check_location_response'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import TimeZoneList from '@/entities/member/lists/time_zone_list'
import FilialeUpdateRequest, {
  AbholungSettings,
  ReservierungSettings,
  SofortbestellungSettings,
  LieferungSettings,
  PaypalSettings,
} from '@/entities/member/request/filiale_update_request'
import TimeZone from '@/entities/member/time_zone'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default class FilialeEditMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale = null

  bezeichnung?: string = null
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  email?: string = null
  latitude?: number = null
  longitude?: number = null
  image?: any = null
  imageUrl?: string = null
  tempImageId?: string = null

  timeZone?: number = 42
  timeZones: TimeZone[] = []

  imageAlt?: string = null
  bannerAlt?: string = null
  logoKleinAlt?: string = null

  imageAltEntfernen: boolean = false
  bannerAltEntfernen: boolean = false
  logoKleinAltEntfernen: boolean = false

  logoKlein: any = null
  logoKleinUrl: string = null
  tempLogoKleinId: string = null

  banner?: any = null
  bannerUrl?: string = null
  tempBannerId?: string = null
  map?: any = null
  liefergebiete: Liefergebiet[] = []

  urlPath: string = null

  adressCheckWorking = false
  adressCheckError = false

  loadError = false

  colorPrimary: string = '#0000ff'
  colorSecondary: string = '#0000ff'
  colorTertiary: string = '#0000ff'

  darkMode: boolean = false

  abholung = {
    supported: false,
    vorbestellungSupported: false,
    mehrereTageSupported: false,
    vorbestellungTage: 0,
    zeitVorSchliessung: 0,
  }

  reservierung = {
    supported: false,
    supportsVorbestellung: false,
    zeitVorSchliessung: 0,
    reservierungTage: 0,
  }

  sofortbestellung = {
    supported: false,
    zeitVorSchliessung: 0,
  }

  paypal = {
    supported: false,
    paypalAdresse: null,
  }

  lieferung = {
    supported: false,
    supportsTermin: false,
    terminTage: 0,
  }

  editWorking: boolean = false
  editCompleted: boolean = false
  editFormDisabled: boolean = false
  editError: boolean = false

  colorPrimaryDialogVisible: boolean = false
  colorSecondaryDialogVisible: boolean = false
  colorTertiaryDialogVisible: boolean = false

  pickColorPrimary() {
    this.colorPrimaryDialogVisible = true
  }

  pickColorSecondary() {
    this.colorSecondaryDialogVisible = true
  }

  pickColorTertiary() {
    this.colorTertiaryDialogVisible = true
  }

  get mapData(): any {
    return 'data:image/jpeg;base64,' + this.map
  }

  goBack() {
    router.back()
  }

  adresseUeberpruefen() {
    this.adressCheckWorking = true
    this.adressCheckError = false

    var request = new CheckLocationRequest()
    request.ort = this.ort
    request.plz = this.plz
    request.strasse = this.strasse

    Poster.postMember<RemoteResult<CheckLocationResponse>>(
      RestPaths.filialeCheckLocation,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.latitude = response.data.payload.longitude
          this.longitude = response.data.payload.latitude
          this.map = response.data.payload.imageData
        } else {
          this.adressCheckWorking = false
          this.adressCheckError = true
        }
      })
      .catch(() => {
        this.adressCheckWorking = false
        this.adressCheckError = true
      })
  }

  previewImage() {
    if (this.image == null) {
      this.imageUrl = null
      return
    }
    this.imageUrl = URL.createObjectURL(this.image)
    this.uploadImage()
  }

  previewBanner() {
    if (this.banner == null) {
      this.bannerUrl = null
      return
    }
    this.bannerUrl = URL.createObjectURL(this.banner)
    this.uploadBanner()
  }

  previewLogoKlein() {
    if (this.logoKlein == null) {
      this.logoKleinUrl = null
      return
    }
    this.logoKleinUrl = URL.createObjectURL(this.logoKlein)
    this.uploadLogoKlein()
  }

  rgbToInt(val: string) {
    if (!val) return 0
    return parseInt(val.substring(1, 7), 16)
  }

  filialeSpeichern() {
    this.editError = false
    this.editWorking = true
    this.editFormDisabled = true

    var request = new FilialeUpdateRequest()
    request.id = this.filiale.id
    request.bezeichnung = this.bezeichnung
    request.aktiv = true
    request.bezeichnung = this.bezeichnung
    request.strasse = this.strasse
    request.plz = this.plz
    request.ort = this.ort
    request.telefon = this.telefon
    request.email = this.email
    request.timeZoneId = this.timeZone

    if (this.imageAltEntfernen) {
      request.bildNeuId = null
      request.bildNeu = true
    } else {
      request.bildNeuId = this.tempImageId
      request.bildNeu = this.tempImageId != null
    }

    if (this.bannerAltEntfernen) {
      request.bannerNeuId = null
      request.bannerNeu = true
    } else {
      request.bannerNeuId = this.tempBannerId
      request.bannerNeu = this.tempBannerId != null
    }

    if (this.logoKleinAltEntfernen) {
      request.logoKleinNeuId = null
      request.logoKleinNeu = true
    } else {
      request.logoKleinNeuId = this.tempLogoKleinId
      request.logoKleinNeu = this.tempLogoKleinId != null
    }

    request.betriebId = this.filiale.betriebId
    request.latitude = this.latitude
    request.longitude = this.longitude

    request.supportsAbholung = this.abholung.supported
    request.supportsReservierung = this.reservierung.supported
    request.supportsSofortbestellung = this.sofortbestellung.supported
    request.supportsLieferung = this.lieferung.supported
    request.supportsPaypal = this.paypal.supported

    request.colorPrimary = this.rgbToInt(this.colorPrimary)
    request.colorSecondary = this.rgbToInt(this.colorSecondary)
    request.colorTertiary = this.rgbToInt(this.colorTertiary)

    request.urlPath = this.urlPath
    request.darkMode = this.darkMode

    if (this.abholung.supported) {
      var abholungSettings = new AbholungSettings()
      request.abholungSettings = abholungSettings

      abholungSettings.supportsMehrereTage = this.abholung.mehrereTageSupported
      abholungSettings.supportsVorbestellung =
        this.abholung.vorbestellungSupported
      abholungSettings.vorbestellungTage = this.abholung.vorbestellungTage
      abholungSettings.zeitVorSchliessung = this.abholung.zeitVorSchliessung
    }

    if (this.reservierung.supported) {
      var reservierungSettings = new ReservierungSettings()
      request.reservierungSettings = reservierungSettings

      reservierungSettings.reservierungTage = this.reservierung.reservierungTage
      reservierungSettings.supportsVorbestellung =
        this.reservierung.supportsVorbestellung
      reservierungSettings.zeitVorSchliessung =
        this.reservierung.zeitVorSchliessung
    }

    if (this.sofortbestellung.supported) {
      var sofortbestellungSettings = new SofortbestellungSettings()
      request.sofortbestellungSettings = sofortbestellungSettings

      sofortbestellungSettings.zeitVorSchliessung =
        this.sofortbestellung.zeitVorSchliessung
    }

    if (this.lieferung.supported) {
      var lieferungSetings = new LieferungSettings()
      request.lieferungSettings = lieferungSetings

      lieferungSetings.supportsTermin = this.lieferung.supportsTermin
      lieferungSetings.terminTage = this.lieferung.terminTage
    }

    if (this.paypal.supported) {
      var paypalSettings = new PaypalSettings()
      request.paypalSettings = paypalSettings

      paypalSettings.paypalAdresse = this.paypal.paypalAdresse
    }

    Poster.postMember<RemoteResult<any>>(RestPaths.filialeSpeichern, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editError = true
        this.editFormDisabled = false
      })
  }

  uploadImage() {
    var data = new FormData()
    data.append('file', this.image)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.tempImageId = data.payload
      })
  }

  uploadBanner() {
    var data = new FormData()
    data.append('file', this.banner)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.tempBannerId = data.payload
      })
  }

  uploadLogoKlein() {
    var data = new FormData()
    data.append('file', this.logoKlein)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => (this.tempLogoKleinId = data.payload))
  }

  intToArgb(value: number) {
    return '#' + (value & 0x00ffffff).toString(16).padStart(6, '0')
  }

  init(id: string) {
    Poster.postMember<RemoteResult<TimeZoneList>>(
      RestPaths.filialeTimeZones,
      null
    ).then((response) => {
      if (response.data.success) {
        this.timeZones = response.data.payload.list
      }
    })

    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Filiale>>(RestPaths.filialeDetails, request)
      .then((response) => {
        if (response.data.success) {
          this.filiale = response.data.payload
          this.bezeichnung = this.filiale.bezeichnung
          this.strasse = this.filiale.strasse
          this.plz = this.filiale.plz
          this.ort = this.filiale.ort
          this.telefon = this.filiale.telefon
          this.email = this.filiale.email
          this.latitude = this.filiale.latitude
          this.longitude = this.filiale.longitude
          this.timeZone = this.filiale.timeZoneId
          this.urlPath = this.filiale.urlPath
          this.darkMode = this.filiale.darkMode

          this.colorPrimary = this.intToArgb(this.filiale.colorPrimary)
          this.colorSecondary = this.intToArgb(this.filiale.colorSecondary)
          this.colorTertiary = this.intToArgb(this.filiale.colorTertiary)

          if (this.filiale.bildId) {
            this.imageAlt =
              RestPaths.imageBasePath + this.filiale.bildId + '_klein.jpg'
          }

          if (this.filiale.bannerId) {
            this.bannerAlt =
              RestPaths.imageBasePath + this.filiale.bannerId + '_cropped.jpg'
          }

          if (this.filiale.logoKleinId) {
            this.logoKleinAlt =
              RestPaths.imageBasePath +
              this.filiale.logoKleinId +
              '_logo_klein.jpg'
          }

          if (this.filiale.supportsAbholung) {
            this.abholung.supported = true
            this.abholung.mehrereTageSupported =
              this.filiale.abholungSettings.supportsMehrereTage
            this.abholung.vorbestellungSupported =
              this.filiale.abholungSettings.supportsVorbestellung
            this.abholung.vorbestellungTage =
              this.filiale.abholungSettings.vorbestellungTage
            this.abholung.zeitVorSchliessung =
              this.filiale.abholungSettings.zeitVorSchliessung
          }

          if (this.filiale.supportsReservierung) {
            this.reservierung.supported = true
            this.reservierung.reservierungTage =
              this.filiale.reservierungSettings.reservierungTage
            this.reservierung.supportsVorbestellung =
              this.filiale.reservierungSettings.supportsVorbestellung
            this.reservierung.zeitVorSchliessung =
              this.filiale.reservierungSettings.zeitVorSchliessung
          }

          if (this.filiale.supportsSofortbestellung) {
            this.sofortbestellung.supported = true
            this.sofortbestellung.zeitVorSchliessung =
              this.filiale.sofortbestellungSettings.zeitVorSchliessung
          }

          if (this.filiale.supportsLieferung) {
            this.lieferung.supported = true
            this.filiale.liefergebiete.forEach((l) => {
              var liefergebiet = new Liefergebiet()
              liefergebiet.id = l.id
              liefergebiet.bezeichnung = l.bezeichnung
              liefergebiet.lieferkosten = l.lieferkosten
              liefergebiet.kostenlosAb = l.kostenlosAb
              liefergebiet.mindestbestellwert = l.mindestbestellwert
              this.liefergebiete.push(liefergebiet)
            })
          }

          if (this.filiale.supportsPaypal) {
            this.paypal.supported = true
            this.paypal.paypalAdresse =
              this.filiale.paypalSettings.paypalAdresse
          }
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  navBack() {
    router.back()
  }
}

class Liefergebiet {
  id?: string
  bezeichnung?: string
  lieferkosten?: number
  kostenlosAb?: number
  mindestbestellwert: number
}

type Color = {
  types: ['hex', 'hexa', 'rgba', 'hsla', 'hsva']
  type: 'hex'
  hex: string
  hexa: string
  rgba: { r: 255; g: 0; b: 255; a: 1 }
  hsla: { h: 300; s: 1; l: 0.5; a: 1 }
  hsva: { h: 300; s: 1; v: 1; a: 1 }
}
